import { DynamicComponent } from '@priva/components/dynamic';

import { AppError } from '../app.model';

export interface AppState {
    initialized: boolean;
    error?: AppError;
    activeDialogs: DynamicComponent[];
    activePanel?: DynamicComponent;
}

export const initialAppState: AppState = {
    initialized: false,
    activeDialogs: [],
};
