import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';

import { AppConfigurationService } from 'app/common/configuration';
import { CreateRowDto, Row, UpdateRowDto } from 'app/core/models';
import { ApiEndPoints } from 'app/shared/constants/api-endpoints';

import { ErrorHandlerService } from '../error-handling';

@Injectable({ providedIn: 'root' })
export class RowApiService {
    protected readonly http = inject(HttpClient);
    protected readonly appConfigurationService = inject(AppConfigurationService);
    protected readonly translate = inject(TranslateService);
    protected readonly errorHandlerService = inject(ErrorHandlerService);
    private readonly baseUrl = this.appConfigurationService.getBaseUrl('spatial');

    private getRowById(siteId: string, id: string): Observable<Row> {
        return this.http.get<Row>(ApiEndPoints.rows.getUpdateOrDeleteById(this.baseUrl, siteId, id));
    }

    public createRows(siteId: string, rows: Row[]): Observable<Row[]> {
        const createRowDto = rows.map((row) => row as CreateRowDto);
        return this.http.post<Row[]>(ApiEndPoints.rows.create(this.baseUrl, siteId) + '/batch', {
            sections: createRowDto,
        });
    }

    public updateRow(siteId: string, id: string, updateRowDto: UpdateRowDto): Observable<Row> {
        if (!id) {
            this.errorHandlerService.handleScriptMessages(
                this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'),
            );
            return throwError(() => new Error(this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE')));
        }

        return this.getRowById(siteId, id).pipe(
            switchMap((existingRow) => {
                if (existingRow.id !== id) {
                    this.errorHandlerService.handleScriptMessages(
                        this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'),
                    );
                    return throwError(() => new Error(this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE')));
                }

                const {
                    name: _name,
                    area: _area,
                    number: _number,
                    spaceId: _spaceId,
                    ...otherProperties
                } = existingRow;

                const updatePayload: Row = {
                    ...updateRowDto,
                    ...otherProperties,
                };

                return this.http.put<Row>(
                    ApiEndPoints.rows.getUpdateOrDeleteById(this.baseUrl, siteId, id),
                    updatePayload,
                );
            }),
            catchError(() => {
                this.errorHandlerService.handleScriptMessages(
                    this.translate.instant('ERROR.UPDATING_ROW', { row: updateRowDto.name }),
                );
                return throwError(
                    () => new Error(this.translate.instant('ERROR.UPDATING_ROW', { row: updateRowDto.name })),
                );
            }),
        );
    }

    public deleteRow(siteId: string, id: string): Observable<void> {
        return this.http.delete<void>(ApiEndPoints.rows.getUpdateOrDeleteById(this.baseUrl, siteId, id));
    }
}
