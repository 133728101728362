import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    ApiErrorMessage,
    AppShellErrorService,
    AppShellErrorSeverity,
    AppShellErrorStatus,
    ErrorMessage,
} from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { UniqueIdentifierUtilities } from '@priva/utilities';

@Injectable()
export class ErrorHandlerService implements OnDestroy {
    private apiMessages$: Observable<ApiErrorMessage>;
    private scriptMessages$: Observable<ErrorMessage>;
    private destroy$ = new Subject<void>();

    constructor(
        private readonly errorService: AppShellErrorService,
        private readonly notificationsService: PrivaNotificationsService,
    ) {
        this.apiMessages$ = this.errorService.apiErrors;
        this.scriptMessages$ = this.errorService.scriptErrors;
    }

    public initialize(): void {
        this.apiMessages$
            .pipe(takeUntil(this.destroy$))
            .subscribe((message: ApiErrorMessage) => this.handleApiMessages(message));
        this.scriptMessages$
            .pipe(takeUntil(this.destroy$))
            .subscribe((message) => this.handleScriptMessages(message));
    }

    public handleApiMessages(error: ApiErrorMessage): void {
        this.processErrorMessage(error);
    }

    public handleScriptMessages(message: string | ErrorMessage): void {
        const isStringMessage = typeof message === 'string';

        const scriptErrorMessage: ErrorMessage = {
            uuid: isStringMessage || !message.uuid ? UniqueIdentifierUtilities.createUUID() : message.uuid,
            status: isStringMessage || !message.status ? AppShellErrorStatus.Active : message.status,
            messageLabel: typeof message === 'string' ? message : message.messageLabel,
            severity: AppShellErrorSeverity.Error,
            details: typeof message === 'string' ? '' : message.details,
            source: typeof message === 'string' ? '' : message.source,
        };

        this.processErrorMessage(scriptErrorMessage);
    }

    private processErrorMessage(message: ApiErrorMessage | ErrorMessage): void {
        if (message.status === AppShellErrorStatus.Resolved) {
            this.notificationsService.close(message.uuid);
            return;
        }

        switch (message.severity) {
            case AppShellErrorSeverity.Error:
                this.notificationsService.bar.error(message.messageLabel, message.uuid);
                break;
            case AppShellErrorSeverity.Warning:
                this.notificationsService.bar.warning(message.messageLabel, message.uuid);
                break;
            default:
                break;
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
