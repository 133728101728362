import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, Directive, Input, Output, Pipe, NgModule } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
class PrivaOverflowService {
  constructor() {
    this.inverted = false;
  }
  register(items, inverted, visibleItemCount) {
    visibleItemCount ||= items?.length ?? 0;
    this.visibleItemCount$ = new BehaviorSubject(visibleItemCount);
    this.inverted = inverted;
  }
  update(visibleItemCount) {
    this.visibleItemCount$?.next(visibleItemCount);
  }
  unregister() {
    this.visibleItemCount$.complete();
    this.visibleItemCount$ = undefined;
    this.inverted = false;
  }
  get visibleItemCount() {
    return this.visibleItemCount$?.asObservable();
  }
  isInverted() {
    return this.inverted;
  }
  static {
    this.ɵfac = function PrivaOverflowService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaOverflowService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaOverflowService,
      factory: PrivaOverflowService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaOverflowService, [{
    type: Injectable
  }], null, null);
})();

/// <reference types="resize-observer-browser" />
/**
 * TODO(PvdS): test with a pipe to check the overflow of a single item,
 *      this can be used to set items offcanvas instead of not rendering them,
 *      which would allow using the observeItemResize when there is no measure template used
 **/
const sum = arr => arr.reduce((acc, curr) => acc + curr, 0);
class PrivaOverflowDirective {
  constructor(hostRef, overflowService, zone) {
    this.hostRef = hostRef;
    this.overflowService = overflowService;
    this.zone = zone;
    this.items = [];
    this.triggerPosition = 'end';
    this.alwaysShowTrigger = false;
    this.hostWidthChange = new EventEmitter();
    this.hasOverflow = new EventEmitter();
    this.hostElement = this.hostRef.nativeElement;
    this.itemElements = [];
    this.itemSizes = [];
  }
  ngOnChanges(changes) {
    if (changes['items'] && !changes['items']?.firstChange) {
      this.overflowService.unregister();
    }
    if (changes['items']?.currentValue) {
      this.resizeHostObserver?.disconnect();
      this.resizeItemObserver?.disconnect();
      this.overflowService.register(changes['items'].currentValue, this.triggerPosition === 'start', this.visibleItemCount);
      // Call async to give the browser time to render the items
      setTimeout(() => {
        this.assignItemElements();
        this.measureTemplate ? this.observeItemResize() : this.measureItems(this.itemElements);
        this.observeHostResize();
      });
    }
    if (changes['alwaysShowTrigger'] && !changes['alwaysShowTrigger'].firstChange) {
      // Call async to give the browser time to render the items
      setTimeout(() => {
        this.measureItems(this.itemElements);
      });
    }
  }
  observeHostResize() {
    this.resizeHostObserver = new ResizeObserver(entries => {
      this.zone.run(() => this.checkOverflow(entries[0].contentRect.width));
    });
    this.resizeHostObserver.observe(this.hostElement);
  }
  observeItemResize() {
    this.resizeItemObserver = new ResizeObserver(() => {
      this.zone.run(() => this.measureItems(this.itemElements));
    });
    this.itemElements.forEach(item => this.resizeItemObserver.observe(item));
  }
  ngOnDestroy() {
    this.overflowService.unregister();
    this.resizeHostObserver?.disconnect();
    this.resizeItemObserver?.disconnect();
  }
  assignItemElements() {
    this.itemElements = Array.from(this.measureTemplate ? this.measureTemplate.children : this.hostElement.children);
  }
  measureItems(items) {
    const sizes = items.map(item => {
      const style = window.getComputedStyle(item);
      return Math.floor(item.getBoundingClientRect().width + parseFloat(style.marginLeft) + parseFloat(style.marginRight));
    });
    this.itemSizes = this.triggerPosition === 'start' ? sizes.slice(1).reverse() : sizes.slice(0, -1);
    this.triggerSize = this.triggerPosition === 'start' ? sizes[0] : sizes[sizes.length - 1];
    this.itemSizesSum = sum(this.alwaysShowTrigger ? sizes : this.itemSizes);
    this.checkOverflow(this.hostElement.getBoundingClientRect().width);
  }
  checkOverflow(hostWidth) {
    hostWidth = Math.ceil(hostWidth);
    // TODO: Separate the actual and measureTemplate host widths
    //   This is a breaking change because components using a measureTemplate and listening to `hostWidthChange`
    //   now receive the host width of the measure template, after refactor a new, separate listener like `measureTemplateHostWidthChange`should be used.
    const _newHostWidth = this.measureTemplate ? Math.ceil(this.measureTemplate.getBoundingClientRect().width) : hostWidth;
    if (this._hostWidth !== _newHostWidth) {
      this._hostWidth = _newHostWidth;
      this.hostWidthChange.emit(this._hostWidth);
    }
    this._hasOverflow = false;
    if (!this.itemSizes?.length) {
      return;
    } else if (this.itemSizesSum <= hostWidth) {
      this.overflowService.update(this.itemSizes.length);
    } else {
      let availableSpace = hostWidth - this.triggerSize;
      this.visibleItemCount = this.itemSizes.findIndex(size => (availableSpace -= size) < 0);
      this._hasOverflow = this.visibleItemCount < this.itemSizes.length;
      this.overflowService.update(this.visibleItemCount);
    }
    this.hasOverflow.emit(this._hasOverflow);
  }
  static {
    this.ɵfac = function PrivaOverflowDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaOverflowDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(PrivaOverflowService), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaOverflowDirective,
      selectors: [["", "priva-overflow", ""]],
      inputs: {
        items: "items",
        triggerPosition: "triggerPosition",
        alwaysShowTrigger: "alwaysShowTrigger",
        measureTemplate: "measureTemplate"
      },
      outputs: {
        hostWidthChange: "hostWidthChange",
        hasOverflow: "hasOverflow"
      },
      features: [i0.ɵɵProvidersFeature([PrivaOverflowService]), i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaOverflowDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-overflow]',
      providers: [PrivaOverflowService]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: PrivaOverflowService
  }, {
    type: i0.NgZone
  }], {
    items: [{
      type: Input
    }],
    triggerPosition: [{
      type: Input
    }],
    alwaysShowTrigger: [{
      type: Input
    }],
    measureTemplate: [{
      type: Input
    }],
    hostWidthChange: [{
      type: Output
    }],
    hasOverflow: [{
      type: Output
    }]
  });
})();

// Note that this pipe can only be used in the context of a component/directive that provides the PrivaOverflowService
class PrivaOverflowPipe {
  constructor(overflowService) {
    this.overflowService = overflowService;
  }
  transform(items, isOverflowed, showLastRemaining = false) {
    const isInverted = this.overflowService.isInverted();
    return this.overflowService.visibleItemCount.pipe(map(visible => {
      const visibleEnd = showLastRemaining ? visible === 0 ? 1 : visible : visible;
      const visibleInvertedStart = showLastRemaining ? items.length - 1 : 0;
      const visibleInvertedEnd = showLastRemaining ? items.length : 0;
      return isInverted ? items.slice(isOverflowed ? 0 : visible === 0 ? visibleInvertedStart : items.length - visible, isOverflowed ? items.length - visible : visible === 0 ? visibleInvertedEnd : items.length) : items.slice(isOverflowed ? visible : 0, isOverflowed ? items.length : visibleEnd);
    }));
  }
  static {
    this.ɵfac = function PrivaOverflowPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaOverflowPipe)(i0.ɵɵdirectiveInject(PrivaOverflowService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "overflow$",
      type: PrivaOverflowPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaOverflowPipe, [{
    type: Pipe,
    args: [{
      name: 'overflow$',
      pure: false
    }]
  }], () => [{
    type: PrivaOverflowService
  }], null);
})();
class PrivaOverflowModule {
  static {
    this.ɵfac = function PrivaOverflowModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaOverflowModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaOverflowModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaOverflowModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaOverflowPipe, PrivaOverflowDirective],
      imports: [CommonModule],
      exports: [PrivaOverflowPipe, PrivaOverflowDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaOverflowDirective, PrivaOverflowModule, PrivaOverflowPipe };
