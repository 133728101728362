import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Directive, Input, NgModule } from '@angular/core';
import { DatePickerComponent } from 'ng2-date-picker';
var PrivaDatepickerThemeClass;
(function (PrivaDatepickerThemeClass) {
  PrivaDatepickerThemeClass["onwhite"] = "datepicker--theme-on-white";
  PrivaDatepickerThemeClass["onlightgray"] = "datepicker--theme-on-light-gray";
  PrivaDatepickerThemeClass["onlightblue"] = "datepicker--theme-on-light-blue";
  PrivaDatepickerThemeClass["ondarkblue"] = "datepicker--theme-on-dark-blue";
})(PrivaDatepickerThemeClass || (PrivaDatepickerThemeClass = {}));
class PrivaDatepickerDirective {
  constructor() {
    this.host = inject(DatePickerComponent, {
      self: true
    });
    this.theme = 'onwhite';
    this.alignment = 'left';
    this.size = 'md';
    this.width = 'full';
  }
  ngOnInit() {
    this.updateHostTheme();
  }
  ngOnChanges() {
    this.updateHostTheme();
  }
  updateHostTheme() {
    this.host.theme = [`datepicker`, `${PrivaDatepickerThemeClass[this.theme?.toLocaleLowerCase()]}`, `datepicker--size-${this.size}`, `datepicker--width-${this.width}`, `datepicker--text-${this.alignment}`, `datepicker--mode-${this.host?.mode || 'day'}`, this.host?.config?.showGoToCurrent ? 'has-today-button' : ''].join(' ');
  }
  static {
    this.ɵfac = function PrivaDatepickerDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDatepickerDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaDatepickerDirective,
      selectors: [["", "priva-datepicker", ""]],
      inputs: {
        theme: "theme",
        alignment: "alignment",
        size: "size",
        width: "width"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDatepickerDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-datepicker]'
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    alignment: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    width: [{
      type: Input
    }]
  });
})();
class PrivaDatepickerModule {
  static {
    this.ɵfac = function PrivaDatepickerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDatepickerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaDatepickerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDatepickerModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaDatepickerDirective],
      imports: [CommonModule],
      exports: [PrivaDatepickerDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaDatepickerDirective, PrivaDatepickerModule, PrivaDatepickerThemeClass };
