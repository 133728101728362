import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { signalStore, withComputed } from '@ngrx/signals';
import { filter } from 'rxjs';

import { PrivaAuthCurrentUserService } from '@priva/auth/current-user';

export type AuthStore = InstanceType<typeof AuthStore>;

export const AuthStore = signalStore(
    { providedIn: 'root' },
    withDevtools('AuthStore'),
    withComputed(() => {
        const authService = inject(PrivaAuthCurrentUserService);
        const currentUser = toSignal(authService.user.pipe(filter((user) => !!user?.tenantId)));
        const tenantId = () => currentUser()?.tenantId;
        return {
            tenantId: computed(() => tenantId()),
            isTenantIdAvailable: computed(() => !!tenantId()),
            currentUser,
        };
    }),
);
