export const ApiEndPoints = {
    sites: {
        getList: (baseUrl: string, tenantId: string) => `${baseUrl}/tenants/${tenantId}/sites`,
        getOrUpdateById: (baseUrl: string, tenantId: string, siteId: string) =>
            `${baseUrl}/tenants/${tenantId}/sites/${siteId}`,
        getTopology: (baseUrl: string, tenantId: string, siteId: string) =>
            `${baseUrl}/tenants/${tenantId}/sites/${siteId}/topology`,
    },
    greenhouses: {
        getListOrCreate: (baseUrl: string, siteId: string) => `${baseUrl}/sites/${siteId}/complexes`,
        getUpdateOrDeleteById: (baseUrl: string, siteId: string, greenHouseId: string) =>
            `${baseUrl}/sites/${siteId}/complexes/${greenHouseId}`,
    },
    compartments: {
        getList: (baseUrl: string, siteId: string, levelId: string, spaceId: string) =>
            `${baseUrl}/sites/${siteId}/spaces?levelId=${levelId}&spaceId=${spaceId}`,
        getUpdateOrDeleteById: (baseUrl: string, siteId: string, id: string) =>
            `${baseUrl}/sites/${siteId}/spaces/${id}`,
        create: (baseUrl: string, siteId: string) => `${baseUrl}/sites/${siteId}/spaces`,
    },
    rows: {
        getList: (baseUrl: string, siteId: string, spaceId: string) =>
            `${baseUrl}/sites/${siteId}/complexes/sections?spaceId=${spaceId}`,
        getUpdateOrDeleteById: (baseUrl: string, siteId: string, sectionId: string) =>
            `${baseUrl}/sites/${siteId}/sections/${sectionId}`,
        create: (baseUrl: string, siteId: string) => `${baseUrl}/sites/${siteId}/sections`,
    },
    controllers: {
        getList: (baseUrl: string, tenantId: string) => `${baseUrl}/tenants/${tenantId}/controllers`,
    },
    controlApplications: {
        getList: (baseUrl: string, siteId: string) => `${baseUrl}/sites/${siteId}/controlApplications`,
    },
    controlApplicationInfluences: {
        getBatch: (baseUrl: string, siteId: string) =>
            `${baseUrl}/sites/${siteId}/controlApplications/influences/getbatch`,
        putBatch: (baseUrl: string, siteId: string) =>
            `${baseUrl}/sites/${siteId}/controlApplications/influences`,
    },
};
