import { Pipe, PipeTransform } from '@angular/core';

import { ValidationStatus } from './validation-status.enum';

@Pipe({
    name: 'validationItemClass',
    pure: true,
})
export class ValidationItemClassPipe implements PipeTransform {
    public transform(value: ValidationStatus | string): string {
        switch (value) {
            case ValidationStatus.Error:
                return 'has-error';
            case ValidationStatus.Warning:
                return 'has-warning';

            default:
                return '';
        }
    }
}
