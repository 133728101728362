import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { BehaviorSubject, Subject, debounceTime, throttleTime } from 'rxjs';
import { CommonModule } from '@angular/common';
var MediaOrientation;
(function (MediaOrientation) {
  MediaOrientation[MediaOrientation["Portrait"] = 0] = "Portrait";
  MediaOrientation[MediaOrientation["Landscape"] = 1] = "Landscape";
})(MediaOrientation || (MediaOrientation = {}));
class PrivaWindowEventsService {
  constructor(zone) {
    this.scrollDebounceTimeout = 50;
    // Media orientation
    this.isPortrait = matchMedia('(orientation: portrait)');
    const currentMediaOrientation = this.calcMediaOrientation();
    this.mediaOrientation$ = new BehaviorSubject(currentMediaOrientation);
    this.resizeEvent$ = new Subject();
    this.scrollEvent$ = new Subject();
    zone.runOutsideAngular(() => {
      window.addEventListener('scroll', $event => this.onScrollEvent($event));
      window.addEventListener('resize', $event => this.onResizeEvent($event));
    });
  }
  onScrollEvent($event) {
    this.scrollEvent$.next($event);
  }
  onResizeEvent($event) {
    const currentMediaOrientation = this.calcMediaOrientation();
    this.resizeEvent$.next($event);
    if (this.mediaOrientation$.value !== currentMediaOrientation) {
      this.mediaOrientation$.next(currentMediaOrientation);
    }
  }
  calcMediaOrientation() {
    return this.isPortrait.matches ? MediaOrientation.Portrait : MediaOrientation.Landscape;
  }
  get mediaOrientationChange() {
    return this.mediaOrientation$.asObservable();
  }
  get onResize() {
    return this.resizeEvent$.asObservable();
  }
  get onScroll() {
    return this.scrollEvent$.asObservable();
  }
  get onScrollTrailing() {
    return this.scrollEvent$.pipe(debounceTime(this.scrollDebounceTimeout));
  }
  get onScrollLeading() {
    return this.scrollEvent$.pipe(throttleTime(this.scrollDebounceTimeout));
  }
  static {
    this.ɵfac = function PrivaWindowEventsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaWindowEventsService)(i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaWindowEventsService,
      factory: PrivaWindowEventsService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaWindowEventsService, [{
    type: Injectable
  }], () => [{
    type: i0.NgZone
  }], null);
})();
class PrivaWindowEventsModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot() {
    return {
      ngModule: PrivaWindowEventsModule,
      providers: [PrivaWindowEventsService]
    };
  }
  static {
    this.ɵfac = function PrivaWindowEventsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaWindowEventsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaWindowEventsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaWindowEventsModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [],
      declarations: [],
      providers: [
        /* Non singleton services */
      ]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaWindowEventsModule, PrivaWindowEventsService };
