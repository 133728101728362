import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
const _c0 = (a0, a1) => [a0, a1];
var PrivaSpinnerSize;
(function (PrivaSpinnerSize) {
  PrivaSpinnerSize["xs"] = "spinner-xs";
  PrivaSpinnerSize["sm"] = "spinner-sm";
  PrivaSpinnerSize["md"] = "spinner-md";
  PrivaSpinnerSize["lg"] = "spinner-lg";
})(PrivaSpinnerSize || (PrivaSpinnerSize = {}));
var PrivaSpinnerStyle;
(function (PrivaSpinnerStyle) {
  PrivaSpinnerStyle["default"] = "";
  PrivaSpinnerStyle["inverse"] = "spinner-inverse";
})(PrivaSpinnerStyle || (PrivaSpinnerStyle = {}));
class PrivaSpinnerComponent {
  constructor() {
    this.size = 'md';
    this.style = 'default';
    this.PrivaSpinnerSize = PrivaSpinnerSize;
    this.PrivaSpinnerStyle = PrivaSpinnerStyle;
  }
  static {
    this.ɵfac = function PrivaSpinnerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaSpinnerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaSpinnerComponent,
      selectors: [["priva-spinner"]],
      inputs: {
        size: "size",
        style: "style"
      },
      decls: 1,
      vars: 4,
      consts: [[1, "spinner", 3, "ngClass"]],
      template: function PrivaSpinnerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(1, _c0, ctx.PrivaSpinnerSize[ctx.size == null ? null : ctx.size.toLowerCase()], ctx.PrivaSpinnerStyle[ctx.style == null ? null : ctx.style.toLowerCase()]));
        }
      },
      dependencies: [i1.NgClass],
      styles: ["[_nghost-%COMP%]{display:inline-flex}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'priva-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<span\n    class=\"spinner\"\n    [ngClass]=\"[PrivaSpinnerSize[size?.toLowerCase()], PrivaSpinnerStyle[style?.toLowerCase()]]\"\n></span>\n",
      styles: [":host{display:inline-flex}\n"]
    }]
  }], null, {
    size: [{
      type: Input
    }],
    style: [{
      type: Input
    }]
  });
})();
class PrivaSpinnerModule {
  static {
    this.ɵfac = function PrivaSpinnerModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaSpinnerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaSpinnerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSpinnerModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaSpinnerComponent],
      imports: [CommonModule],
      exports: [PrivaSpinnerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaSpinnerComponent, PrivaSpinnerModule };
