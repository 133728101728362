import { Pipe, PipeTransform } from '@angular/core';

import { ValidationStatus } from './validation-status.enum';

@Pipe({
    name: 'validationIcon',
    pure: true,
})
export class ValidationIconPipe implements PipeTransform {
    public transform(value: ValidationStatus | string): string {
        switch (value) {
            case ValidationStatus.Error:
                return 'fas fa-exclamation-circle';
            case ValidationStatus.Warning:
                return 'far fa-exclamation-circle';
            case ValidationStatus.Info:
                return 'far fa-info-circle';
            case ValidationStatus.Success:
                return 'far fa-check-circle';
            default:
                throw new Error(`Unhandled validation item type: ${value}`);
        }
    }
}
