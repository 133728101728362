import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, NgModule } from '@angular/core';
import * as i2 from '@priva/auth/http';
import { PrivaAuthHttpOptions, PrivaAuthHttpBffModule, PrivaAuthHttpModule } from '@priva/auth/http';
import { first, tap, map, fromEvent, filter, switchMap, merge, distinctUntilChanged, shareReplay, of, catchError } from 'rxjs';
import * as i1$1 from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import * as i1 from 'angular-oauth2-oidc';
import { OAuthModule } from 'angular-oauth2-oidc';
import * as i5 from '@angular/router';
import { jwtDecode } from 'jwt-decode';

/**
 * (any implementation of) CurrentUserService is responsible for getting the current user data.
 */
class PrivaAuthCurrentUserService {}
class PrivaBffLoggedInUserGuard {
  constructor(currentUserService, authHttpOptions) {
    this.currentUserService = currentUserService;
    this.authHttpOptions = authHttpOptions;
  }
  canActivate(_route, state) {
    return this.isCurrentUserAvailable(state.url);
  }
  canActivateChild(_route, state) {
    return this.isCurrentUserAvailable(state.url);
  }
  isCurrentUserAvailable(_url) {
    return this.currentUserService.user.pipe(first(), tap(user => {
      if (!this.authHttpOptions.bffConfig) {
        console.warn('Cannot start login without sufficient configuration, please add bffConfig section');
      } else if (!user) {
        this.currentUserService.login();
      }
    }), map(user => !!user));
  }
  static {
    this.ɵfac = function PrivaBffLoggedInUserGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaBffLoggedInUserGuard)(i0.ɵɵinject(PrivaAuthCurrentUserService), i0.ɵɵinject(i2.PrivaAuthHttpOptions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaBffLoggedInUserGuard,
      factory: PrivaBffLoggedInUserGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaBffLoggedInUserGuard, [{
    type: Injectable
  }], () => [{
    type: PrivaAuthCurrentUserService
  }, {
    type: i2.PrivaAuthHttpOptions
  }], null);
})();
function withType(key) {
  return ({
    type
  }) => type === key;
}
class PrivaAuthCurrentUserBffService {
  constructor() {
    this.http = inject(HttpClient);
    this.options = inject(PrivaAuthHttpOptions);
    this.initUserStream();
  }
  get user() {
    return this.user$;
  }
  login() {
    const returnUrl = window.location.pathname + window.location.search;
    window.location.href = `/bff/login?returnUrl=${returnUrl}`;
  }
  initUserStream() {
    const updatedUser$ = fromEvent(document, 'visibilitychange').pipe(filter(() => document.visibilityState === 'visible'), switchMap(() => this.getCurrentBffUser()));
    this.user$ = merge(this.getCurrentBffUser(), updatedUser$).pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
  }
  getCurrentBffUser() {
    if (!this.options.bffConfig) {
      console.warn('Using PrivaAuthCurrentUserBffService with insufficient configuration, please add bffConfig section');
      return of(undefined);
    }
    return this.http.get('/bff/user').pipe(map(bffUser => this.currentUserFromBffUser(bffUser)), catchError(_err => of(undefined)));
  }
  currentUserFromBffUser(bffUser) {
    if (!bffUser) return undefined;
    const getValue = key => bffUser.find(withType(key))?.value;
    const getBooleanValue = key => getValue(key)?.toLowerCase() === 'true';
    return {
      id: getValue('tenant_user'),
      name: getValue('name'),
      tenantId: getValue('tenant'),
      tenantName: getValue('tenant_name'),
      administrativeName: getValue('admin_name'),
      isInternalUser: getBooleanValue('internal_user'),
      memberId: getValue('sub'),
      logoutUrl: getValue('bff:logout_url'),
      features: this.extractFeatureTogglesFromBffUser(bffUser)
    };
  }
  extractFeatureTogglesFromBffUser(bffUser) {
    const featurePrefix = 'priva.';
    const isFeature = ({
      type
    }) => type.startsWith(featurePrefix);
    const toToggle = ({
      type
    }) => ({
      name: type,
      state: true
    });
    return bffUser.filter(isFeature).map(toToggle);
  }
  static {
    this.ɵfac = function PrivaAuthCurrentUserBffService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAuthCurrentUserBffService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaAuthCurrentUserBffService,
      factory: PrivaAuthCurrentUserBffService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthCurrentUserBffService, [{
    type: Injectable
  }], () => [], null);
})();
class PrivaAuthCurrentUserBffModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot() {
    return {
      ngModule: PrivaAuthCurrentUserBffModule,
      providers: [{
        provide: PrivaAuthCurrentUserService,
        useClass: PrivaAuthCurrentUserBffService
      }]
    };
  }
  static {
    this.ɵfac = function PrivaAuthCurrentUserBffModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAuthCurrentUserBffModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthCurrentUserBffModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PrivaBffLoggedInUserGuard],
      imports: [CommonModule, PrivaAuthHttpBffModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthCurrentUserBffModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaAuthHttpBffModule],
      providers: [PrivaBffLoggedInUserGuard]
    }]
  }], null, null);
})();

/** @deprecated Use a BFF back-end and PrivaBffLoggedInUserGuard instead.
 * See https://dev.azure.com/privagroup/UX%20Frontend%20Toolkit/_git/priva.packages.monorepo?path=/projects/auth/MIGRATION-bff.md
 */
class PrivaAuthenticatedUserGuard {
  constructor(currentUserService) {
    this.currentUserService = currentUserService;
  }
  canActivate(_route, state) {
    return this.isCurrentUserAvailable(state.url);
  }
  canActivateChild(_route, state) {
    return this.isCurrentUserAvailable(state.url);
  }
  isCurrentUserAvailable(_url) {
    return this.currentUserService.user.pipe(tap(user => {
      if (!user) this.currentUserService.login();
    }), map(user => !!user));
  }
  static {
    this.ɵfac = function PrivaAuthenticatedUserGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAuthenticatedUserGuard)(i0.ɵɵinject(PrivaAuthCurrentUserService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaAuthenticatedUserGuard,
      factory: PrivaAuthenticatedUserGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthenticatedUserGuard, [{
    type: Injectable
  }], () => [{
    type: PrivaAuthCurrentUserService
  }], null);
})();

/** @deprecated Use a BFF back-end and PrivaBffLoggedInUserGuard instead.
 * See https://dev.azure.com/privagroup/UX%20Frontend%20Toolkit/_git/priva.packages.monorepo?path=/projects/auth/MIGRATION-bff.md
 */
class PrivaValidTokenGuard {
  constructor(oauthService, authHttpOptions, currentUserService) {
    this.oauthService = oauthService;
    this.authHttpOptions = authHttpOptions;
    this.currentUserService = currentUserService;
    this.currentUserService.user.pipe(first()).subscribe(() => {
      // Force current user to load
    });
  }
  canActivate(_route, state) {
    return this.isValidTokenAvailable(state.url);
  }
  canActivateChild(_route, state) {
    return this.isValidTokenAvailable(state.url);
  }
  async isValidTokenAvailable(url) {
    if (!this.oauthService.clientId) {
      this.oauthService.configure(this.authHttpOptions.authConfig);
      await this.oauthService.loadDiscoveryDocumentAndLogin({
        state: btoa(url)
      });
      this.oauthService.setupAutomaticSilentRefresh();
    }
    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()) {
      return true;
    } else {
      // HK - Below code is obsolete in conjunction with the PKCE auth flow
      //      Errors should be handled by the implementing app
      // await this.router.navigate(['error/not-authorized']);
      return false;
    }
  }
  static {
    this.ɵfac = function PrivaValidTokenGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaValidTokenGuard)(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.PrivaAuthHttpOptions), i0.ɵɵinject(PrivaAuthCurrentUserService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaValidTokenGuard,
      factory: PrivaValidTokenGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaValidTokenGuard, [{
    type: Injectable
  }], () => [{
    type: i1.OAuthService
  }, {
    type: i2.PrivaAuthHttpOptions
  }, {
    type: PrivaAuthCurrentUserService
  }], null);
})();
class CurrentUserPaths {
  constructor(api) {
    this.api = api;
    this.currentUser = () => this.api;
  }
}
class CurrentUserConfiguration {
  constructor(options) {
    this.options = options;
    this.defaults = {
      uri: 'privaauth/me'
    };
    const uri = this.options && this.options.uri ? this.options.uri : this.defaults.uri;
    this.paths = new CurrentUserPaths(uri);
  }
}

/**
 * CurrentUserService is responsible for getting the current user data.
 * @deprecated Use a BFF back-end and PrivaAuthCurrentUserBffModule instead.
 * See https://dev.azure.com/privagroup/UX%20Frontend%20Toolkit/_git/priva.packages.monorepo?path=/projects/auth/MIGRATION-bff.md
 */
class PrivaAuthCurrentUserOAuthService {
  constructor(http, configuration, oauthService, authHttpOptions, router) {
    this.http = http;
    this.configuration = configuration;
    this.oauthService = oauthService;
    this.authHttpOptions = authHttpOptions;
    this.router = router;
    /**
     * Switch between the classic implicit flow and the PKCE auth flow.
     */
    if (this.authHttpOptions.authConfig) {
      this.user$ = this.getCurrentUserFromToken();
    } else {
      this.user$ = this.getCurrentUser();
    }
  }
  get user() {
    return this.user$;
  }
  login() {
    this.router.navigate(['error/not-authorized']);
  }
  /**
   * This method is for support of the oauth implicit flow will be deprecated
   * in a future version of the @priva/auth package.
   */
  // Call the frontend server
  getCurrentUser() {
    const url = this.configuration.paths.currentUser();
    return this.http.get(url).pipe(shareReplay(1), catchError((_error, _caught) => of(undefined)));
  }
  /**
   * This method is for supporting the PKCE auth flow.
   *
   * It listens for events from the oauth service and sets information
   * from the received token in the CurrentUserService.
   */
  // get the user from the token
  getCurrentUserFromToken() {
    return this.oauthService.events.pipe(filter(event => event.type === 'discovery_document_loaded' || event.type === 'token_received' || event.type === 'token_refreshed'), map(_event => this.oauthService.getAccessToken()), filter(token => !!token), shareReplay(1), map(token => {
      const profile = jwtDecode(token);
      const currentUser = {
        administrativeName: profile.admin_name,
        id: profile.tenant_user,
        /**
         * Support either string or boolean for internal_user
         */
        isInternalUser: typeof profile.internal_user === 'boolean' ? profile.internal_user : profile.internal_user === 'True',
        memberId: profile.sub,
        name: profile.name,
        tenantId: profile.tenant,
        tenantName: profile.tenant_name,
        features: this.getPermissionClaimsFromToken(profile)
      };
      return currentUser;
    }));
  }
  /**
   * @param profile Decoded profile form token
   *
   * Filter all permission claims on 'priva.' from information in token.
   * Permission claims are return based on the given scopes
   */
  getPermissionClaimsFromToken(profile) {
    const features = [];
    const result = Object.keys(profile).filter(option => option.startsWith('priva.'));
    result.forEach(name => {
      features.push({
        name,
        state: true
      });
    });
    return features;
  }
  static {
    this.ɵfac = function PrivaAuthCurrentUserOAuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAuthCurrentUserOAuthService)(i0.ɵɵinject(i1$1.HttpClient), i0.ɵɵinject(CurrentUserConfiguration), i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.PrivaAuthHttpOptions), i0.ɵɵinject(i5.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaAuthCurrentUserOAuthService,
      factory: PrivaAuthCurrentUserOAuthService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthCurrentUserOAuthService, [{
    type: Injectable
  }], () => [{
    type: i1$1.HttpClient
  }, {
    type: CurrentUserConfiguration
  }, {
    type: i1.OAuthService
  }, {
    type: i2.PrivaAuthHttpOptions
  }, {
    type: i5.Router
  }], null);
})();
function getCurrentUserConfigurationFactory() {
  return new CurrentUserConfiguration();
}
/** @deprecated Use a BFF back-end and PrivaAuthCurrentUserBffModule instead.
 * See https://dev.azure.com/privagroup/UX%20Frontend%20Toolkit/_git/priva.packages.monorepo?path=/projects/auth/MIGRATION-bff.md
 */
class PrivaAuthCurrentUserModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot(config) {
    return {
      ngModule: PrivaAuthCurrentUserModule,
      providers: [{
        provide: PrivaAuthCurrentUserService,
        useClass: PrivaAuthCurrentUserOAuthService
      }, config || {
        provide: CurrentUserConfiguration,
        useFactory: getCurrentUserConfigurationFactory
      }]
    };
  }
  static {
    this.ɵfac = function PrivaAuthCurrentUserModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAuthCurrentUserModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthCurrentUserModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PrivaAuthenticatedUserGuard, PrivaValidTokenGuard],
      imports: [CommonModule, PrivaAuthHttpModule, OAuthModule.forRoot()]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthCurrentUserModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaAuthHttpModule, OAuthModule.forRoot()],
      exports: [],
      declarations: [],
      providers: [PrivaAuthenticatedUserGuard, PrivaValidTokenGuard]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CurrentUserConfiguration, PrivaAuthCurrentUserBffModule, PrivaAuthCurrentUserModule, PrivaAuthCurrentUserService, PrivaAuthenticatedUserGuard, PrivaBffLoggedInUserGuard, PrivaValidTokenGuard };
