<div
    class="notification margin-bottom-2"
    [ngClass]="{
        'notification-warning': errorDefinition.status === 'warning',
        'notification-danger': errorDefinition.status === 'error',
    }"
>
    <span class="status-icon" [ngClass]="errorDefinition.status | validationIcon"></span>
    <span class="notification-message">
        <strong class="notification-emphasis">{{ errorDefinition.errorTitleKey | translate }}</strong>
        {{ errorDefinition.errorMessageKey | translate: errorDefinition.parameters }}
    </span>
</div>
