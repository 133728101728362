import { Action } from '@ngrx/store';

export interface AppError {
    title: string;
    subTitle: string;

    actionTitle?: string;
    actionHtmlId?: string;
    actionHtmlClass?: string;

    action?: Action;
}

/* TODO: convert to interface and remove resource text */
export class AppApiError implements AppError {
    public title: string;
    public action: Action;
    public subTitle = 'APP.API.ERROR.SUBTITLE';
    public actionTitle = 'APP.API.ERROR.RELOAD';
    public actionHtmlClass = 'btn-icon btn-icon-left far fa-repeat';

    constructor(title: string, action: Action) {
        this.title = title;
        this.action = action;
    }
}
