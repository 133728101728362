import { Injectable } from '@angular/core';
import { CanDeactivate, GuardResult, MaybeAsync } from '@angular/router';

export interface CanComponentDeactivate {
    canDeactivate: () => boolean | Promise<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    public canDeactivate(component: CanComponentDeactivate): MaybeAsync<GuardResult> {
        return component?.canDeactivate() ?? true;
    }
}
