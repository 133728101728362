import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
const _c0 = ["*"];
const _c1 = (a0, a1) => [a0, a1];
var PrivaGroupDirectionClass;
(function (PrivaGroupDirectionClass) {
  PrivaGroupDirectionClass["horizontal"] = "group--direction-horizontal";
  PrivaGroupDirectionClass["vertical"] = "group--direction-vertical";
})(PrivaGroupDirectionClass || (PrivaGroupDirectionClass = {}));
var PrivaGroupSpacingClass;
(function (PrivaGroupSpacingClass) {
  PrivaGroupSpacingClass["none"] = "";
  PrivaGroupSpacingClass["sm"] = "group--spacing-sm";
  PrivaGroupSpacingClass["md"] = "group--spacing-md";
  PrivaGroupSpacingClass["lg"] = "group--spacing-lg";
})(PrivaGroupSpacingClass || (PrivaGroupSpacingClass = {}));
class PrivaGroupComponent {
  constructor() {
    this.direction = 'horizontal';
    this.spacing = 'md';
    this.PrivaGroupDirectionClass = PrivaGroupDirectionClass;
    this.PrivaGroupSpacingClass = PrivaGroupSpacingClass;
  }
  static {
    this.ɵfac = function PrivaGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaGroupComponent,
      selectors: [["priva-group"]],
      inputs: {
        direction: "direction",
        spacing: "spacing"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 4,
      consts: [[1, "group", 3, "ngClass"]],
      template: function PrivaGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(1, _c1, ctx.PrivaGroupDirectionClass[ctx.direction == null ? null : ctx.direction.toLowerCase()], ctx.PrivaGroupSpacingClass[ctx.spacing == null ? null : ctx.spacing.toLowerCase()]));
        }
      },
      dependencies: [i1.NgClass],
      styles: ["[_nghost-%COMP%]{display:inline-block}.group[_ngcontent-%COMP%]{display:flex;width:100%}.group--direction-horizontal.group--spacing-sm[_ngcontent-%COMP%]    >*:not(:first-child){margin-left:4px}.group--direction-horizontal.group--spacing-md[_ngcontent-%COMP%]    >*:not(:first-child){margin-left:8px}.group--direction-horizontal.group--spacing-lg[_ngcontent-%COMP%]    >*:not(:first-child){margin-left:12px}.group--direction-vertical[_ngcontent-%COMP%]{flex-direction:column}.group--direction-vertical.group--spacing-sm[_ngcontent-%COMP%]    >*:not(:first-child){margin-top:4px}.group--direction-vertical.group--spacing-md[_ngcontent-%COMP%]    >*:not(:first-child){margin-top:8px}.group--direction-vertical.group--spacing-lg[_ngcontent-%COMP%]    >*:not(:first-child){margin-top:12px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaGroupComponent, [{
    type: Component,
    args: [{
      selector: 'priva-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n    class=\"group\"\n    [ngClass]=\"[\n        PrivaGroupDirectionClass[direction?.toLowerCase()],\n        PrivaGroupSpacingClass[spacing?.toLowerCase()],\n    ]\"\n>\n    <ng-content />\n</div>\n",
      styles: [":host{display:inline-block}.group{display:flex;width:100%}.group--direction-horizontal.group--spacing-sm ::ng-deep>*:not(:first-child){margin-left:4px}.group--direction-horizontal.group--spacing-md ::ng-deep>*:not(:first-child){margin-left:8px}.group--direction-horizontal.group--spacing-lg ::ng-deep>*:not(:first-child){margin-left:12px}.group--direction-vertical{flex-direction:column}.group--direction-vertical.group--spacing-sm ::ng-deep>*:not(:first-child){margin-top:4px}.group--direction-vertical.group--spacing-md ::ng-deep>*:not(:first-child){margin-top:8px}.group--direction-vertical.group--spacing-lg ::ng-deep>*:not(:first-child){margin-top:12px}\n"]
    }]
  }], null, {
    direction: [{
      type: Input
    }],
    spacing: [{
      type: Input
    }]
  });
})();
class PrivaGroupModule {
  static {
    this.ɵfac = function PrivaGroupModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaGroupModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaGroupModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaGroupModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaGroupComponent],
      imports: [CommonModule],
      exports: [PrivaGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaGroupComponent, PrivaGroupModule };
