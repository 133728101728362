import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { filter, map, tap } from 'rxjs';

import { logOut, resetState } from './app.actions';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    public logOut$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(logOut),
                tap(() => {
                    this.router.navigate(['signout']);
                }),
            );
        },
        { dispatch: false },
    );

    public routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            map(() => resetState()),
        );
    });

    constructor(
        private actions$: Actions,
        private router: Router,
    ) {}
}
