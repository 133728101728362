import * as i0 from '@angular/core';
import { input, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { PrivaLocalizationModule } from '@priva/localization';
import * as i1 from '@ngx-translate/core';
const _c0 = ["*", [["", "data-label", ""]], [["", "data-help", ""]]];
const _c1 = ["*", "[data-label]", "[data-help]"];
function PrivaFormGroupComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 1);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵprojection(3, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵattribute("for", ctx_r0.labelFor() || null);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, ctx_r0.label()), "");
  }
}
function PrivaFormGroupComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵprojection(3, 2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, ctx_r0.help()));
  }
}
class PrivaFormGroupComponent {
  constructor() {
    this.ariaLabel = input();
    this.labelFor = input();
    this.hasLabel = input(true);
    this.label = input();
    this.hasHelp = input(false);
    this.help = input();
    this.isInline = input(false);
    this.isWrapped = input(false);
    this.hasOptions = input(false);
  }
  static {
    this.ɵfac = function PrivaFormGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaFormGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaFormGroupComponent,
      selectors: [["priva-form-group"]],
      inputs: {
        ariaLabel: [1, "ariaLabel"],
        labelFor: [1, "labelFor"],
        hasLabel: [1, "hasLabel"],
        label: [1, "label"],
        hasHelp: [1, "hasHelp"],
        help: [1, "help"],
        isInline: [1, "isInline"],
        isWrapped: [1, "isWrapped"],
        hasOptions: [1, "hasOptions"]
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 6,
      vars: 11,
      consts: [[1, "form-group"], [1, "form-control-label"], [1, "form-control-help"], [1, "form-controls"]],
      template: function PrivaFormGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵpipe(1, "translate");
          i0.ɵɵtemplate(2, PrivaFormGroupComponent_Conditional_2_Template, 4, 4, "label", 1)(3, PrivaFormGroupComponent_Conditional_3_Template, 4, 3, "div", 2);
          i0.ɵɵelementStart(4, "div", 3);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 9, ctx.ariaLabel()) || null);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.hasLabel() ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.hasHelp() ? 3 : -1);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("is-inline", ctx.isInline())("is-wrapped", ctx.isWrapped())("has-options", ctx.hasOptions());
        }
      },
      dependencies: [PrivaLocalizationModule, i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}.form-section[_nghost-%COMP%]:not(:first-of-type), .form-section   [_nghost-%COMP%]:not(:first-of-type){margin-top:24px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaFormGroupComponent, [{
    type: Component,
    args: [{
      selector: 'priva-form-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      imports: [PrivaLocalizationModule],
      standalone: true,
      template: "<div class=\"form-group\" [attr.aria-label]=\"(ariaLabel() | translate) || null\">\n    @if (hasLabel()) {\n        <label class=\"form-control-label\" [attr.for]=\"labelFor() || null\">\n            {{ label() | translate }}<ng-content select=\"[data-label]\" />\n        </label>\n    }\n    @if (hasHelp()) {\n        <div class=\"form-control-help\">{{ help() | translate }}<ng-content select=\"[data-help]\" /></div>\n    }\n    <div\n        class=\"form-controls\"\n        [class.is-inline]=\"isInline()\"\n        [class.is-wrapped]=\"isWrapped()\"\n        [class.has-options]=\"hasOptions()\"\n    >\n        <ng-content />\n    </div>\n</div>\n",
      styles: [":host{display:block}:host-context(.form-section):not(:first-of-type){margin-top:24px}\n"]
    }]
  }], null, null);
})();

/** @deprecated import standalone `PrivaFormGroupComponent` instead */
class PrivaFormGroupModule {
  static {
    this.ɵfac = function PrivaFormGroupModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaFormGroupModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaFormGroupModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [PrivaFormGroupComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaFormGroupModule, [{
    type: NgModule,
    args: [{
      imports: [PrivaFormGroupComponent],
      exports: [PrivaFormGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaFormGroupComponent, PrivaFormGroupModule };
