import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
const _c0 = ["priva-input", ""];
var PrivaInputThemeClass;
(function (PrivaInputThemeClass) {
  PrivaInputThemeClass["onwhite"] = "form-control--theme-on-white";
  PrivaInputThemeClass["onlightgray"] = "form-control--theme-on-light-gray";
  PrivaInputThemeClass["onlightblue"] = "form-control--theme-on-light-blue";
  PrivaInputThemeClass["ondarkblue"] = "form-control--theme-on-dark-blue";
})(PrivaInputThemeClass || (PrivaInputThemeClass = {}));
var PrivaInputAlignmentClass;
(function (PrivaInputAlignmentClass) {
  PrivaInputAlignmentClass["left"] = "form-control-text-left";
  PrivaInputAlignmentClass["center"] = "form-control-text-center";
  PrivaInputAlignmentClass["right"] = "form-control-text-right";
})(PrivaInputAlignmentClass || (PrivaInputAlignmentClass = {}));
class PrivaInputComponent {
  constructor() {
    this.size = 'md';
    this.theme = 'onwhite';
  }
  get hostClasses() {
    return ['form-control', 'form-control--size-' + this.size, PrivaInputThemeClass[this.theme?.toLowerCase()], PrivaInputAlignmentClass[this.alignment?.toLowerCase()], this.class || ''].join(' ');
  }
  static {
    this.ɵfac = function PrivaInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaInputComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaInputComponent,
      selectors: [["", "priva-input", ""]],
      hostVars: 2,
      hostBindings: function PrivaInputComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        class: "class",
        size: "size",
        theme: "theme",
        alignment: "alignment"
      },
      attrs: _c0,
      decls: 0,
      vars: 0,
      template: function PrivaInputComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaInputComponent, [{
    type: Component,
    args: [{
      selector: '[priva-input]',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    class: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    alignment: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class PrivaInputModule {
  static {
    this.ɵfac = function PrivaInputModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaInputModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaInputComponent],
      imports: [CommonModule],
      exports: [PrivaInputComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaInputComponent, PrivaInputModule };
