import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import { PrivaLocalizationModule } from '@priva/localization';
import * as i2 from '@ngx-translate/core';
const _c0 = [[["", "data-emphasis", ""]], "*"];
const _c1 = ["[data-emphasis]", "*"];
const _c2 = a0 => [a0];
function PrivaNotificationComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵlistener("click", function PrivaNotificationComponent_Conditional_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.triggerDismiss.emit());
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-test", ctx_r1.dataTest)("aria-label", i0.ɵɵpipeBind1(1, 2, "GLOBAL.ACTION.CLOSE"));
  }
}
var PrivaNotificationStatusClass;
(function (PrivaNotificationStatusClass) {
  PrivaNotificationStatusClass["info"] = "notification-info";
  PrivaNotificationStatusClass["success"] = "notification-success";
  PrivaNotificationStatusClass["warning"] = "notification-warning";
  PrivaNotificationStatusClass["danger"] = "notification-danger";
})(PrivaNotificationStatusClass || (PrivaNotificationStatusClass = {}));
var PrivaNotificationEmphasisClass;
(function (PrivaNotificationEmphasisClass) {
  PrivaNotificationEmphasisClass["inline"] = "notification-emphasis";
  PrivaNotificationEmphasisClass["block"] = "notification-emphasis-block";
})(PrivaNotificationEmphasisClass || (PrivaNotificationEmphasisClass = {}));
var PrivaNotificationStatusIconClass;
(function (PrivaNotificationStatusIconClass) {
  PrivaNotificationStatusIconClass["info"] = "far fa-info-circle";
  PrivaNotificationStatusIconClass["success"] = "far fa-check-circle";
  PrivaNotificationStatusIconClass["warning"] = "far fa-exclamation-circle";
  PrivaNotificationStatusIconClass["danger"] = "fas fa-exclamation-circle";
})(PrivaNotificationStatusIconClass || (PrivaNotificationStatusIconClass = {}));
class PrivaNotificationComponent {
  constructor() {
    this.status = 'info';
    this.isDismissible = false;
    this.dataTest = 'notification';
    this.triggerDismiss = new EventEmitter();
    this.PrivaNotificationStatusClass = PrivaNotificationStatusClass;
    this.PrivaNotificationEmphasisClass = PrivaNotificationEmphasisClass;
    this.PrivaNotificationStatusIconClass = PrivaNotificationStatusIconClass;
  }
  static {
    this.ɵfac = function PrivaNotificationComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaNotificationComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaNotificationComponent,
      selectors: [["priva-notification"]],
      inputs: {
        status: "status",
        emphasisType: "emphasisType",
        emphasizedText: "emphasizedText",
        isDismissible: "isDismissible",
        dataTest: "dataTest"
      },
      outputs: {
        triggerDismiss: "triggerDismiss"
      },
      ngContentSelectors: _c1,
      decls: 9,
      vars: 9,
      consts: [[1, "notification", 3, "ngClass"], ["type", "button", "data-dismiss", "alert", 1, "notification-close", "far", "fa-times"], [1, "status-icon", 3, "ngClass"], [1, "notification-message"], [3, "ngClass"], [1, "notification-text"], ["type", "button", "data-dismiss", "alert", 1, "notification-close", "far", "fa-times", 3, "click"]],
      template: function PrivaNotificationComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, PrivaNotificationComponent_Conditional_1_Template, 2, 4, "button", 1);
          i0.ɵɵelement(2, "span", 2);
          i0.ɵɵelementStart(3, "span", 3)(4, "strong", 4);
          i0.ɵɵtext(5);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "span", 5);
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("notification-dismissible", ctx.isDismissible);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c2, ctx.PrivaNotificationStatusClass[ctx.status == null ? null : ctx.status.toLowerCase()]));
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.isDismissible ? 1 : -1);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngClass", ctx.PrivaNotificationStatusIconClass[ctx.status == null ? null : ctx.status.toLowerCase()]);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngClass", ctx.PrivaNotificationEmphasisClass[ctx.emphasisType == null ? null : ctx.emphasisType.toLowerCase()]);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate(ctx.emphasizedText);
        }
      },
      dependencies: [i1.NgClass, i2.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaNotificationComponent, [{
    type: Component,
    args: [{
      selector: 'priva-notification',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n    class=\"notification\"\n    [ngClass]=\"[PrivaNotificationStatusClass[status?.toLowerCase()]]\"\n    [class.notification-dismissible]=\"isDismissible\"\n>\n    @if (isDismissible) {\n        <button\n            type=\"button\"\n            class=\"notification-close far fa-times\"\n            data-dismiss=\"alert\"\n            [attr.data-test]=\"dataTest\"\n            [attr.aria-label]=\"'GLOBAL.ACTION.CLOSE' | translate\"\n            (click)=\"triggerDismiss.emit()\"\n        ></button>\n    }\n    <span class=\"status-icon\" [ngClass]=\"PrivaNotificationStatusIconClass[status?.toLowerCase()]\"></span\n    ><span class=\"notification-message\"\n        ><strong [ngClass]=\"PrivaNotificationEmphasisClass[emphasisType?.toLowerCase()]\"\n            >{{ emphasizedText }}<ng-content select=\"[data-emphasis]\" /></strong\n        ><span class=\"notification-text\"><ng-content /></span\n    ></span>\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], null, {
    status: [{
      type: Input
    }],
    emphasisType: [{
      type: Input
    }],
    emphasizedText: [{
      type: Input
    }],
    isDismissible: [{
      type: Input
    }],
    dataTest: [{
      type: Input
    }],
    triggerDismiss: [{
      type: Output
    }]
  });
})();
class PrivaNotificationModule {
  static {
    this.ɵfac = function PrivaNotificationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaNotificationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaNotificationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaNotificationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaLocalizationModule],
      declarations: [PrivaNotificationComponent],
      exports: [PrivaNotificationComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaNotificationComponent, PrivaNotificationModule };
