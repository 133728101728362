import { Observable, catchError, throwError } from 'rxjs';

// Observable wrapper for a ResizeObserver
function observeElementSize(elem) {
  return new Observable(subscriber => {
    const resizeObserver = new ResizeObserver(entries => {
      subscriber.next(entries);
    });
    resizeObserver.observe(elem);
    return function unsubscribe() {
      resizeObserver.unobserve(elem);
    };
  });
}

// RxJS operator to run the remainder of an RxJS pipe in the Angular zone.
// This can be used when the first part of the RxJS pipe is running outside the Angular zone
// (e.g. to do a resource-intensive operation) and the outcome needs to be fed into a view.
function runInZone(zone) {
  return source => new Observable(observer => source.subscribe({
    next: value => zone.run(() => observer.next(value)),
    error: e => zone.run(() => observer.error(e)),
    complete: () => zone.run(() => observer.complete())
  }));
}
function annotateError(annotation) {
  return catchError(error => {
    error.annotation = annotation;
    return throwError(() => error);
  });
}
function isAnnotatedError(error) {
  return 'annotation' in error;
}

/**
 * Generated bundle index. Do not edit.
 */

export { annotateError, isAnnotatedError, observeElementSize, runInZone };
