import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiErrorMessage, AppShellErrorSeverity, AppShellErrorStatus } from '@priva/appshell';
import { UniqueIdentifierUtilities } from '@priva/utilities';

import { ErrorHandlerService } from 'app/core/services/error-handling';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private errorHandlerService: ErrorHandlerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const apiErrorMessage: ApiErrorMessage = {
                    uuid: UniqueIdentifierUtilities.createUUID(),
                    status: AppShellErrorStatus.Active,
                    severity:
                        error.status >= 400 ? AppShellErrorSeverity.Error : AppShellErrorSeverity.Warning,
                    messageLabel: error.message || 'An error occurred',
                    statusCode: error.status,
                    details: error.error || 'No details provided',
                    source: req.url,
                };

                this.errorHandlerService.handleApiMessages(apiErrorMessage);

                return throwError(() => error);
            }),
        );
    }
}
