import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsBrowser } from '@segment/analytics-next';

/** Segment Configuration Injection Token */
const SEGMENT_CONFIG = new InjectionToken('ngx-segment-analytics.config');
const DEFAULT_CONFIG = {
  debug: false,
  loadOnInitialization: true,
  // Compatibility < 1.2.5
  cdnURL: 'https://cdn.segment.com',
  segmentHost: 'cdn.segment.com',
  segmentUri: '/analytics.js/v1/$API_KEY$/analytics.min.js',
  plugins: []
};
class SegmentService {
  _config;
  static _segmentInstance = new AnalyticsBrowser();
  /**
   * @param userConfig Segment configuration
   */
  constructor(userConfig) {
    this._config = {
      ...DEFAULT_CONFIG,
      ...userConfig
    };
    if (this._config.loadOnInitialization && (typeof this._config.apiKey === 'undefined' || this._config.apiKey === '')) {
      console.error('The API Key cannot be an empty string if Segment must be loaded on initialization.');
      return;
    }
    if (true === this._config.debug) {
      console.log('Segment initialization...');
    }
    if (this._config.loadOnInitialization && !SegmentService._segmentInstance.instance?.initialized) {
      if (this._config.segmentHost) {
        // Deprecated option
        const cdnUrl = 'https://' + this._config.segmentHost;
      } else {
        const cdnUrl = this._config.cdnURL;
      }
      this.load({
        writeKey: this._config.apiKey,
        cdnURL: this._config.segmentHost
      });
    }
  }
  /**
   * Load Segment configuration.
   *
   * @param settingsOrApiKey Write API Key or Segment settings.
   * @param options Optional parameters.
   */
  load(settingsOrApiKey, options = {}) {
    let settings;
    if (typeof settingsOrApiKey === 'string') {
      settings = {
        writeKey: settingsOrApiKey
      };
    } else {
      settings = settingsOrApiKey;
    }
    SegmentService._segmentInstance.load(settings, options).then(() => {
      if (this._config.debug) {
        console.log('Segment initialized');
      }
    });
    this.debug(this._config.debug);
  }
  async identify(userId, traits, options) {
    await SegmentService._segmentInstance.identify(userId, traits, options);
    return this;
  }
  /**
   * The track method lets you record any actions your users perform.
   *
   * @param event The name of the event you’re tracking.
   * @param properties A dictionary of properties for the event.
   * @param options A dictionary of options.
   *
   * @returns
   */
  async track(event, properties, options) {
    await SegmentService._segmentInstance.track(event, properties, options);
    return this;
  }
  /**
   * The page method lets you record page views on your website, along with optional extra information about the page being viewed.
   *
   * @param category The category of the page.
   * @param name The name of the page.
   * @param properties A dictionary of properties of the page.
   * @param options A dictionary of options.
   *
   * @returns
   */
  async page(category, name, properties, options) {
    await SegmentService._segmentInstance.page(category, name, properties, options);
    return this;
  }
  /**
   * The group method associates an identified user with a company, organization, project, workspace, team, tribe, platoon,
   * assemblage, cluster, troop, gang, party, society or any other name you came up with for the same concept.
   *
   * @param groupId The Group ID to associate with the current user.
   * @param traits A dictionary of traits for the group.
   *
   * @returns
   */
  async group(groupId, traits) {
    await SegmentService._segmentInstance.group(groupId, traits);
    return this;
  }
  /**
   * The alias method combines two previously unassociated user identities.
   *
   * @param userId The new user ID you want to associate with the user.
   * @param previousId The previous ID that the user was recognized by. This defaults to the currently identified user’s ID.
   * @param options A dictionary of options.
   *
   * @returns
   */
  async alias(userId, previousId, options) {
    await SegmentService._segmentInstance.alias(userId, previousId, options);
    return this;
  }
  /**
   * The ready method allows you execute a promise that will be called as soon as all of your enabled destinations have loaded
   * and analytics.js has completed initialization.
   *
   * @returns
   */
  async ready() {
    await SegmentService._segmentInstance.ready();
    return this;
  }
  /**
   * Return information about the currently identified user
   *
   * @returns Informations about the currently identified user
   */
  user() {
    return SegmentService._segmentInstance.instance.user();
  }
  /**
   * Return identifier about the currently identified user
   *
   * @returns Identifier about the currently identified user
   */
  id() {
    return this.user()?.id();
  }
  /**
   * Override the default Anonymous ID
   *
   * @param anonymousId New anonymous ID
   */
  setAnonymousId(anonymousId) {
    SegmentService._segmentInstance.setAnonymousId(anonymousId);
  }
  /**
   * Return traits about the currently identified user
   *
   * @returns Traits about the currently identified user
   */
  traits() {
    return this.user()?.traits();
  }
  /**
   * Reset the id, including anonymousId, and clear traits for the currently identified user and group.
   */
  reset() {
    SegmentService._segmentInstance.reset();
  }
  /**
   * Turn on/off debug mode, logging helpful messages to the console.
   *
   * @param enabled Enable or not the debug mode
   */
  debug(enabled) {
    SegmentService._segmentInstance.debug(enabled);
  }
  /**
   * Set listeners for these events and run your own custom code.
   *
   * @param method Name of the method to listen for
   * @param callback A function to execute after each the emitted method
   */
  on(method, callback) {
    SegmentService._segmentInstance.on(method, callback);
  }
  /**
   * Attaches the `track` call as a handler to a link
   *
   * @param elements DOM element or an array of DOM elements to be bound with track method.
   * @param event The name of the event, passed to the `track` method or a function that returns a string to be used
   *              as the name of the track event.
   * @param properties A dictionary of properties to pass with the `track` method.
   */
  trackLink(elements, event, properties) {
    SegmentService._segmentInstance.trackLink(elements, event, properties);
  }
  /**
   * Binds a `track` call to a form submission.
   *
   * @param forms The form element to track or an array of form
   * @param event The name of the event, passed to the `track` method.
   * @param properties A dictionary of properties to pass with the `track` method.
   */
  trackForm(forms, event, properties) {
    SegmentService._segmentInstance.trackSubmit(forms, event, properties);
  }
  /**
   * Add a source middleware called on events
   *
   * @param middleware Custom function
   */
  addSourceMiddleware(middleware) {
    SegmentService._segmentInstance.addSourceMiddleware(middleware);
  }
  /**
   * Add destination middlewares called on events
   *
   * @param integration Integration name
   * @param middlewares Custom functions
   */
  addDestinationMiddleware(integration, ...middlewares) {
    SegmentService._segmentInstance.addDestinationMiddleware(integration, ...middlewares);
  }
  /**
   * Register plugins
   *
   * @param plugins
   */
  async register(...plugins) {
    await SegmentService._segmentInstance.register(...plugins);
    return;
  }
  get segmentInstance() {
    return SegmentService._segmentInstance.instance;
  }
  static ɵfac = function SegmentService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SegmentService)(i0.ɵɵinject(SEGMENT_CONFIG));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SegmentService,
    factory: SegmentService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SegmentService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SEGMENT_CONFIG]
    }]
  }], null);
})();

/**
 * Segment Module
 */
class SegmentModule {
  /**
   * Segment Module Initialisation
   *
   * @param config Segment Configuration
   * @returns Segment Module
   */
  static forRoot(config) {
    return {
      ngModule: SegmentModule,
      providers: [{
        provide: SEGMENT_CONFIG,
        useValue: config
      }, SegmentService]
    };
  }
  /**
   * Segment Module Constructor
   *
   * @param parentModule Must be null
   */
  constructor(parentModule) {
    if (parentModule) {
      throw new Error('SegmentModule is already loaded. Import it in the AppModule only');
    }
  }
  static ɵfac = function SegmentModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SegmentModule)(i0.ɵɵinject(SegmentModule, 12));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SegmentModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SegmentModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule]
    }]
  }], () => [{
    type: SegmentModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/**
 * Window Wrapper for Angular AOT
 */
class WindowWrapper {
  /** Segment Analytics.js instance */
  analytics;
  static ɵfac = function WindowWrapper_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WindowWrapper)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WindowWrapper,
    factory: WindowWrapper.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WindowWrapper, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_CONFIG, SEGMENT_CONFIG, SegmentModule, SegmentService, WindowWrapper };
