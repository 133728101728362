import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { fuzzyMatch } from '@priva/utilities/helpers';
const _c0 = ["priva-mark-match", ""];
function MarkMatchComponent_For_1_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mark", 0);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const part_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(part_r1);
  }
}
function MarkMatchComponent_For_1_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const part_r1 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(part_r1);
  }
}
function MarkMatchComponent_For_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, MarkMatchComponent_For_1_Conditional_0_Template, 2, 1, "mark", 0)(1, MarkMatchComponent_For_1_Conditional_1_Template, 2, 1, "ng-container");
  }
  if (rf & 2) {
    const $index_r2 = ctx.$index;
    i0.ɵɵconditional($index_r2 % 2 ? 0 : 1);
  }
}
class MarkMatchComponent {
  constructor() {
    this.splitUpText = [''];
    this._filter = [];
  }
  set filter(value) {
    this._filter = [];
    if (Array.isArray(value)) {
      this._filter = value;
    }
    if (typeof value === 'string') {
      this._filter = [value];
    }
    this.matchFilter();
  }
  ngOnInit() {
    this.matchFilter();
  }
  matchFilter() {
    const regExp = this.buildRegExp(this._filter);
    const text = this.text || '';
    this.splitUpText = regExp ? text.split(regExp) : [text];
  }
  buildRegExp(filter) {
    const wordExps = filter.map(word => fuzzyMatch(word)).filter(exp => !!exp);
    return wordExps.length ? new RegExp('(' + wordExps.join('|') + ')', 'gi') : undefined;
  }
  static {
    this.ɵfac = function MarkMatchComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MarkMatchComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MarkMatchComponent,
      selectors: [["", "priva-mark-match", ""]],
      inputs: {
        text: "text",
        filter: "filter"
      },
      attrs: _c0,
      decls: 2,
      vars: 0,
      consts: [[1, "mark-match"]],
      template: function MarkMatchComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵrepeaterCreate(0, MarkMatchComponent_For_1_Template, 2, 1, null, null, i0.ɵɵrepeaterTrackByIdentity);
        }
        if (rf & 2) {
          i0.ɵɵrepeater(ctx.splitUpText);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MarkMatchComponent, [{
    type: Component,
    args: [{
      selector: '[priva-mark-match]',
      template: "@for (part of splitUpText; track part) {\n    @if ($index % 2) {\n        <mark class=\"mark-match\">{{ part }}</mark>\n    } @else {\n        <ng-container>{{ part }}</ng-container>\n    }\n}\n"
    }]
  }], null, {
    text: [{
      type: Input
    }],
    filter: [{
      type: Input
    }]
  });
})();
class PrivaMarkMatchModule {
  static {
    this.ɵfac = function PrivaMarkMatchModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaMarkMatchModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaMarkMatchModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaMarkMatchModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [MarkMatchComponent],
      declarations: [MarkMatchComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MarkMatchComponent, PrivaMarkMatchModule };
