import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = (a0, a1, a2) => [a0, a1, a2];
var PrivaStatusCircleStatus;
(function (PrivaStatusCircleStatus) {
  PrivaStatusCircleStatus["info"] = "status-circle-info";
  PrivaStatusCircleStatus["success"] = "status-circle-success";
  PrivaStatusCircleStatus["warning"] = "status-circle-warning";
  PrivaStatusCircleStatus["danger"] = "status-circle-danger";
})(PrivaStatusCircleStatus || (PrivaStatusCircleStatus = {}));
var PrivaStatusCircleSize;
(function (PrivaStatusCircleSize) {
  PrivaStatusCircleSize["sm"] = "";
  PrivaStatusCircleSize["md"] = "status-circle-md";
  PrivaStatusCircleSize["lg"] = "status-circle-lg";
})(PrivaStatusCircleSize || (PrivaStatusCircleSize = {}));
var PrivaStatusCircleTheme;
(function (PrivaStatusCircleTheme) {
  PrivaStatusCircleTheme["onwhite"] = "status-circle-theme-on-white";
  PrivaStatusCircleTheme["onlightgray"] = "status-circle-theme-on-light-gray";
  PrivaStatusCircleTheme["onlightblue"] = "status-circle-theme-on-light-blue";
  PrivaStatusCircleTheme["ondarkblue"] = "status-circle-theme-on-dark-blue";
})(PrivaStatusCircleTheme || (PrivaStatusCircleTheme = {}));
class PrivaStatusCircleComponent {
  constructor() {
    this.theme = 'onwhite';
    this.size = 'sm';
    this.status = 'info';
    this.isHidden = false;
    this.isDisabled = false;
    this.PrivaStatusCircleStatus = PrivaStatusCircleStatus;
    this.PrivaStatusCircleSize = PrivaStatusCircleSize;
    this.PrivaStatusCircleTheme = PrivaStatusCircleTheme;
  }
  static {
    this.ɵfac = function PrivaStatusCircleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaStatusCircleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaStatusCircleComponent,
      selectors: [["priva-status-circle"]],
      inputs: {
        theme: "theme",
        size: "size",
        status: "status",
        isHidden: "isHidden",
        isDisabled: "isDisabled"
      },
      decls: 1,
      vars: 9,
      consts: [[1, "status-circle", 3, "ngClass"]],
      template: function PrivaStatusCircleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵclassProp("status-circle-invisible", ctx.isHidden)("status-circle-disabled", ctx.isDisabled);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(5, _c0, ctx.PrivaStatusCircleStatus[ctx.status == null ? null : ctx.status.toLowerCase()], ctx.PrivaStatusCircleSize[ctx.size == null ? null : ctx.size.toLowerCase()], ctx.PrivaStatusCircleTheme[ctx.theme == null ? null : ctx.theme.toLowerCase()]));
        }
      },
      dependencies: [i1.NgClass],
      styles: ["[_nghost-%COMP%]{display:inline-flex}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaStatusCircleComponent, [{
    type: Component,
    args: [{
      selector: 'priva-status-circle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<span\n    class=\"status-circle\"\n    [ngClass]=\"[\n        PrivaStatusCircleStatus[status?.toLowerCase()],\n        PrivaStatusCircleSize[size?.toLowerCase()],\n        PrivaStatusCircleTheme[theme?.toLowerCase()],\n    ]\"\n    [class.status-circle-invisible]=\"isHidden\"\n    [class.status-circle-disabled]=\"isDisabled\"\n>\n</span>\n",
      styles: [":host{display:inline-flex}\n"]
    }]
  }], null, {
    theme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    status: [{
      type: Input
    }],
    isHidden: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }]
  });
})();
class PrivaStatusCircleModule {
  static {
    this.ɵfac = function PrivaStatusCircleModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaStatusCircleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaStatusCircleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaStatusCircleModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaStatusCircleComponent],
      imports: [CommonModule],
      exports: [PrivaStatusCircleComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaStatusCircleComponent, PrivaStatusCircleModule };
