import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, Component, ViewChild, NgModule } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i1 from '@priva/utilities/window-events';
const _c0 = ["buoy"];
var ScrollDirection;
(function (ScrollDirection) {
  ScrollDirection[ScrollDirection["Init"] = 0] = "Init";
  ScrollDirection[ScrollDirection["Up"] = 1] = "Up";
  ScrollDirection[ScrollDirection["Down"] = 2] = "Down";
  ScrollDirection[ScrollDirection["NoChange"] = 3] = "NoChange";
})(ScrollDirection || (ScrollDirection = {}));
class ScrollDirectionEnumConverter {
  static {
    this.classMap = new Map([[ScrollDirection.Init, 'scrolled-init'], [ScrollDirection.Up, 'scrolled-up'], [ScrollDirection.Down, 'scrolled-down'], [ScrollDirection.NoChange, 'scrolled-up']]);
  }
  static getClassIdentifier(direction) {
    const identifier = this.classMap.get(direction);
    return identifier;
  }
}
var ScrollLocation;
(function (ScrollLocation) {
  ScrollLocation[ScrollLocation["Init"] = 0] = "Init";
  ScrollLocation[ScrollLocation["Top"] = 1] = "Top";
  ScrollLocation[ScrollLocation["Bottom"] = 2] = "Bottom";
  ScrollLocation[ScrollLocation["Between"] = 3] = "Between";
})(ScrollLocation || (ScrollLocation = {}));
class ScrollLocationEnumConverter {
  static {
    this.classMap = new Map([[ScrollLocation.Init, ''], [ScrollLocation.Top, 'scrolled-top'], [ScrollLocation.Bottom, 'scrolled-bottom'], [ScrollLocation.Between, '']]);
  }
  static getClassIdentifier(pos) {
    const identifier = this.classMap.get(pos);
    return identifier;
  }
}
class PrivaScrollbarService {
  constructor(windowEvents) {
    this.windowEvents = windowEvents;
    this.scrollbarWidth = 0;
    this.scrollbarPlaceholderWidth = 0;
    this.scrollPosPreviousY = 0;
    this.scrollTicking = false;
    this.scrollDirectionChange$ = new BehaviorSubject(ScrollDirection.Init);
    this.scrollLocationChange$ = new BehaviorSubject(ScrollLocation.Init);
    this.scrollbarVisibility$ = new Subject();
    // Events
    this.subscribeOnScrollTrailing();
  }
  /** Scrollbar related
   * ==============================
   */
  calcBarWidth(buoy) {
    this.scrollbarWidth = buoy.nativeElement.offsetWidth - buoy.nativeElement.clientWidth;
  }
  get placeholderWidth() {
    return this.scrollbarPlaceholderWidth;
  }
  calcPlaceholderWidth() {
    if (document.body.scrollHeight > document.body.clientHeight) {
      this.scrollbarPlaceholderWidth = this.scrollbarWidth;
    } else {
      this.scrollbarPlaceholderWidth = 0;
    }
  }
  get scrollbarVisibility() {
    return this.scrollbarVisibility$.asObservable();
  }
  hideScrollbar(toggleOnBreakpoint = '') {
    this.calcPlaceholderWidth();
    this.scrollbarVisibility$.next({
      visible: false,
      breakpoint: toggleOnBreakpoint
    });
  }
  showScrollbar(toggleOnBreakpoint = '') {
    this.scrollbarVisibility$.next({
      visible: true,
      breakpoint: toggleOnBreakpoint
    });
  }
  /** Scroll event related
   * ==============================
   */
  subscribeOnScrollTrailing() {
    this.windowEvents.onScrollTrailing.subscribe($event => {
      if (!this.scrollTicking) {
        this.scrollTicking = true;
        requestAnimationFrame(() => this.updateScrollClasses($event));
      }
    });
  }
  get directionChange() {
    return this.scrollDirectionChange$.asObservable();
  }
  get locationChange() {
    return this.scrollLocationChange$.asObservable();
  }
  updateScrollClasses($event) {
    if (!$event?.target?.scrollingElement) {
      return;
    }
    const target = $event.target;
    const scrollPosY = target.scrollingElement.scrollTop;
    const scrollHeight = target.scrollingElement.scrollHeight;
    const scrollClientHeight = target.scrollingElement.clientHeight;
    this.scrollTicking = false;
    this.updateScrollDirectionClasses(scrollPosY);
    this.updateScrollLocationClasses(scrollPosY, scrollHeight, scrollClientHeight);
    this.scrollPosPreviousY = scrollPosY;
  }
  updateScrollDirectionClasses(scrollPosY) {
    let scrollDirection;
    if (scrollPosY < this.scrollPosPreviousY) {
      scrollDirection = ScrollDirection.Up;
    } else if (scrollPosY > this.scrollPosPreviousY) {
      scrollDirection = ScrollDirection.Down;
    } else {
      scrollDirection = ScrollDirection.NoChange;
    }
    if (this.scrollDirectionChange$.value !== scrollDirection) {
      this.scrollDirectionChange$.next(scrollDirection);
    }
  }
  updateScrollLocationClasses(scrollPosY, scrollHeight, scrollClientHeight) {
    let scrollLocation;
    if (scrollPosY === 0) {
      scrollLocation = ScrollLocation.Top;
    } else if (scrollHeight - (scrollPosY + scrollClientHeight) === 0) {
      scrollLocation = ScrollLocation.Bottom;
    } else {
      scrollLocation = ScrollLocation.Between;
    }
    if (this.scrollLocationChange$.value !== scrollLocation) {
      this.scrollLocationChange$.next(scrollLocation);
    }
  }
  static {
    this.ɵfac = function PrivaScrollbarService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaScrollbarService)(i0.ɵɵinject(i1.PrivaWindowEventsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaScrollbarService,
      factory: PrivaScrollbarService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaScrollbarService, [{
    type: Injectable
  }], () => [{
    type: i1.PrivaWindowEventsService
  }], null);
})();
class ScrollbarComponent {
  constructor(renderer, scrollService) {
    this.renderer = renderer;
    this.scrollService = scrollService;
  }
  ngOnInit() {
    this.scrollService.calcBarWidth(this.scrollbarBuoy);
    this.scrollService.scrollbarVisibility.subscribe(visibility => {
      visibility.visible ? this.showScrollbar(visibility.breakpoint) : this.hideScrollbar(visibility.breakpoint);
    });
  }
  hideScrollbar(toggleOnBreakpoint) {
    this.renderer.addClass(document.body, `no-scroll${toggleOnBreakpoint}`);
    this.renderer.setAttribute(document.documentElement, 'style', `--scrollbar-width: ${this.scrollService.placeholderWidth}px`);
  }
  showScrollbar(toggleOnBreakpoint) {
    this.renderer.removeClass(document.body, `no-scroll${toggleOnBreakpoint}`);
  }
  static {
    this.ɵfac = function ScrollbarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ScrollbarComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(PrivaScrollbarService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ScrollbarComponent,
      selectors: [["priva-scrollbar-buoy"]],
      viewQuery: function ScrollbarComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.scrollbarBuoy = _t.first);
        }
      },
      decls: 2,
      vars: 0,
      consts: [["buoy", ""], ["id", "scrollbar-measure"]],
      template: function ScrollbarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollbarComponent, [{
    type: Component,
    args: [{
      selector: 'priva-scrollbar-buoy',
      template: '<div #buoy id="scrollbar-measure"></div>'
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: PrivaScrollbarService
  }], {
    scrollbarBuoy: [{
      type: ViewChild,
      args: ['buoy', {
        static: true
      }]
    }]
  });
})();
class PrivaScrollbarModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot() {
    return {
      ngModule: PrivaScrollbarModule,
      providers: [PrivaScrollbarService]
    };
  }
  static {
    this.ɵfac = function PrivaScrollbarModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaScrollbarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaScrollbarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaScrollbarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [ScrollbarComponent],
      declarations: [ScrollbarComponent],
      providers: [
        /* Non singleton services */
      ]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaScrollbarModule, PrivaScrollbarService, ScrollDirection, ScrollDirectionEnumConverter, ScrollLocation, ScrollLocationEnumConverter, ScrollbarComponent };
