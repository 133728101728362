import * as i1 from '@ngx-translate/core';
import { TranslateDefaultParser, TranslateService, TranslateModule, TranslateParser, TranslatePipe, TranslateDirective } from '@ngx-translate/core';
export { TranslateLoader, TranslateService } from '@ngx-translate/core';
import * as i0 from '@angular/core';
import { Injectable, NgModule, Pipe, inject } from '@angular/core';
import { isObservable, of, combineLatest, map, shareReplay, catchError, startWith, switchMap, tap, retry, onErrorResumeNextWith, mergeMap, ReplaySubject } from 'rxjs';
import { registerLocaleData, CurrencyPipe, CommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeUSD from '@angular/common/locales/en';
import localeCAD from '@angular/common/locales/en-CA';
import localeCHF from '@angular/common/locales/en-CH';
import localeGBP from '@angular/common/locales/en-GB';
import localeEUR from '@angular/common/locales/en-NL';
import { format, formatDistance } from 'date-fns';
import { af, da, de, es, fi, fr, hu, it, ja, ko, nl, pl, enUS, ru, sk, sv, zhCN } from 'date-fns/locale';

// Match label, defined as 1 or more non-whitespace characters preceeded by a '@'
const reLabel = /@([^ \t@[\]]+)/g;
/** Support composite labels as used within Priva for misc purposes; examples are:
 * - '@DISPLAYNAMES.meteom_txt_long 1' -> 'Weather 1'
 * - '@DISPLAYNAMES.afdloc_txt_long 2 @et_per1_long' -> 'Compartment 2 period 1'
 */
class PrivaTranslateParser extends TranslateDefaultParser {
  constructor(injector, missingTranslationHandler) {
    super();
    this.injector = injector;
    this.missingTranslationHandler = missingTranslationHandler;
  }
  getValue(target, key) {
    const labels = key.match(reLabel);
    if (labels?.length) {
      const translations = labels.map(match => this.treatLabel(target, match.slice(1)));
      // if any of the translations is Observable<string>, treat all as such, and return Observable<string>
      if (translations.some(isObservable)) {
        const translations$ = translations.map(translation => isObservable(translation) ? translation : of(translation));
        return combineLatest(translations$).pipe(map(translations => this.processTranslations(key, labels, translations)));
      }
      return this.processTranslations(key, labels, translations);
    }
    return super.getValue(target, key);
  }
  processTranslations(key, labels, translations) {
    return key.replace(reLabel, match => translations[labels.indexOf(match)]);
  }
  treatLabel(target, key) {
    const translation = super.getValue(target, key);
    return typeof translation === 'string' ? translation : this.handleMissingTranslation(key);
  }
  handleMissingTranslation(key) {
    // Using injector to obtain translate service, because injecting via constructor causes circularity
    this.translateService = this.translateService || this.injector.get(TranslateService);
    // The next 2 lines are copied from the ngx-translate/core TranslateService implementation
    const params = {
      key,
      translateService: this.translateService
    };
    const translation = this.missingTranslationHandler.handle(params);
    // If the missing translation handler returned something, use it, otherwise reconstruct the '@label'
    return typeof translation !== 'undefined' ? translation : '@' + key;
  }
  static {
    this.ɵfac = function PrivaTranslateParser_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTranslateParser)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.MissingTranslationHandler));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTranslateParser,
      factory: PrivaTranslateParser.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTranslateParser, [{
    type: Injectable
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.MissingTranslationHandler
  }], null);
})();

/**
 * PrivaTranslateModule is functionally equal to @ngx-translate/core's TranslateModule, but
 * defaults to an extended translation parser (PrivaTranslateParser) that also handles composite
 * translations, where labels are prefixed with '@'
 */
class PrivaTranslateModule {
  static forRoot(config = {}) {
    return {
      ngModule: PrivaTranslateModule,
      providers: [TranslateModule.forRoot({
        loader: config.loader,
        compiler: config.compiler,
        parser: config.parser || {
          provide: TranslateParser,
          useClass: PrivaTranslateParser
        },
        missingTranslationHandler: config.missingTranslationHandler,
        isolate: config.isolate,
        useDefaultLang: config.useDefaultLang
      }).providers]
    };
  }
  static {
    this.ɵfac = function PrivaTranslateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTranslateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaTranslateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [TranslateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTranslateModule, [{
    type: NgModule,
    args: [{
      imports: [TranslateModule],
      declarations: [],
      exports: [TranslatePipe, TranslateDirective]
    }]
  }], null, null);
})();
class PrivaCurrencyPipe {
  transform(value, currencyCode, digitsInfo) {
    value = typeof value === 'number' ? `${value}` : value;
    if (!value || !currencyCode) {
      return value;
    }
    const currency = this.getLocale(currencyCode);
    registerLocaleData(currency.locale, currency.code);
    return new CurrencyPipe(currency.code, currencyCode).transform(value, currencyCode, 'symbol', digitsInfo, currency.code);
  }
  getLocale(currencyCode) {
    const currencyLocale = {
      CAD: {
        locale: localeCAD,
        code: 'ca'
      },
      USD: {
        locale: localeUSD,
        code: 'en'
      },
      GBP: {
        locale: localeGBP,
        code: 'gb'
      },
      EUR: {
        locale: localeEUR,
        code: 'nl'
      },
      CHF: {
        locale: localeCHF,
        code: 'ch'
      }
    };
    return currencyLocale[currencyCode] ?? currencyLocale['USD'];
  }
  static {
    this.ɵfac = function PrivaCurrencyPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaCurrencyPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "privaCurrency",
      type: PrivaCurrencyPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaCurrencyPipe, [{
    type: Pipe,
    args: [{
      name: 'privaCurrency'
    }]
  }], null, null);
})();
class PrivaCurrencyModule {
  static {
    this.ɵfac = function PrivaCurrencyModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaCurrencyModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaCurrencyModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaCurrencyModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaCurrencyPipe],
      exports: [PrivaCurrencyPipe]
    }]
  }], null, null);
})();
var Endianness;
(function (Endianness) {
  Endianness["Little"] = "LITTLE";
  Endianness["Middle"] = "MIDDLE";
  Endianness["Big"] = "BIG";
})(Endianness || (Endianness = {}));
var Meridiem;
(function (Meridiem) {
  Meridiem["Regular"] = "REGULAR";
  Meridiem["Military"] = "MILITARY";
})(Meridiem || (Meridiem = {}));
var FirstDayOfWeek;
(function (FirstDayOfWeek) {
  FirstDayOfWeek[FirstDayOfWeek["Sunday"] = 0] = "Sunday";
  FirstDayOfWeek[FirstDayOfWeek["Monday"] = 1] = "Monday";
  FirstDayOfWeek[FirstDayOfWeek["Tuesday"] = 2] = "Tuesday";
  FirstDayOfWeek[FirstDayOfWeek["Wednesday"] = 3] = "Wednesday";
  FirstDayOfWeek[FirstDayOfWeek["Thursday"] = 4] = "Thursday";
  FirstDayOfWeek[FirstDayOfWeek["Friday"] = 5] = "Friday";
  FirstDayOfWeek[FirstDayOfWeek["Saturday"] = 6] = "Saturday";
})(FirstDayOfWeek || (FirstDayOfWeek = {}));
class PrivaLocalizationPreferencesService {
  constructor() {
    this.preferences$ = of(this.cloneConfig(this.config));
  }
  get config() {
    return {
      meridiem: Meridiem.Military,
      endianness: Endianness.Little,
      firstDayOfWeek: FirstDayOfWeek.Monday,
      duration: {
        includeSeconds: true,
        addSuffix: true
      }
    };
  }
  /**
   * A config is cloned to avoid possibility of mutation via public config property.
   * @param {DateTimePreferences} config
   * @returns {DateTimePreferences}
   */
  cloneConfig(config) {
    return {
      ...config,
      duration: {
        ...config.duration
      }
    };
  }
  get endianness() {
    return this.preferences$.pipe(map(preferences => preferences.endianness));
  }
  get meridiem() {
    return this.preferences$.pipe(map(preferences => preferences.meridiem));
  }
  get firstDayOfWeek() {
    return this.preferences$.pipe(map(preferences => preferences.firstDayOfWeek));
  }
  get duration() {
    return this.preferences$.pipe(map(preferences => preferences.duration));
  }
  static {
    this.ɵfac = function PrivaLocalizationPreferencesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLocalizationPreferencesService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaLocalizationPreferencesService,
      factory: PrivaLocalizationPreferencesService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLocalizationPreferencesService, [{
    type: Injectable
  }], null, null);
})();
class PrivaLocalizationOptions {
  constructor() {
    this.version = '1.0.0';
    this.path = 'assets/translations';
    this.translationFileSuffix = '.json';
    this.availableLanguagesResource = 'assets/translations/locales.json';
    this.preferenceApi = undefined;
    this.defaultLanguage = 'en-US';
  }
}
class PrivaPreferencesService {
  constructor() {
    this.http = inject(HttpClient);
    this.options = inject(PrivaLocalizationOptions);
    this.preferences$ = this.getPreferences();
  }
  getPreferences() {
    return this.apiUrl ? this.http.get(this.apiUrl).pipe(shareReplay({
      bufferSize: 1,
      refCount: false
    }), catchError(error => {
      console.warn(`Could not get preferences from ${this.apiUrl}`, error);
      return of({});
    })) : of({});
  }
  get apiUrl() {
    return this.options.preferenceApi;
  }
  get preferences() {
    return this.preferences$;
  }
}
const useBrowserPreferenceFlag = 'browser';
/**
 * Service that manages language selection and determines the application language.
 */
class PrivaLocalizationService {
  constructor() {
    this.debug = false;
    this.availableLanguages = [];
    this.browserLanguages = [];
    this.http = inject(HttpClient);
    this.options = inject(PrivaLocalizationOptions);
    this.translateService = inject(TranslateService);
    this.localizationPreferencesService = inject(PrivaLocalizationPreferencesService);
    this.preferences$ = inject(PrivaPreferencesService).preferences;
    this.setLanguageLoader();
    this.determineBrowserLanguages();
  }
  get firstBrowserLanguage() {
    return this.browserLanguages[0];
  }
  /**
   * Returns a translation instantly from the internal state of loaded translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  instant(key, interpolateParams) {
    return this.translateService.instant(key, interpolateParams);
  }
  translate(key, interpolateParams) {
    const translationChange$ = this.translateService.onTranslationChange.pipe(startWith({}));
    return combineLatest([this.languages$, translationChange$]).pipe(switchMap(() => this.translateService.get(key, interpolateParams)));
  }
  get language() {
    return this.languages$.pipe(map(() => this.languageCode));
  }
  get languageSnapshot() {
    if (!this.languageCode) {
      /* eslint-disable-next-line no-console*/
      console.warn(' [PrivaLocalizationService]' + ' Language snapshot was requested before language was set.' + ' Usually an order / async problem.');
    }
    return this.languageCode || this.options.defaultLanguage;
  }
  get country() {
    return this.language.pipe(map(locale => locale.split('-').shift()));
  }
  get meridiem() {
    return this.localizationPreferencesService.meridiem;
  }
  get endianness() {
    return this.localizationPreferencesService.endianness;
  }
  /**
   * Merge external translation labels with existing ones from an url
   */
  setExternalTranslation(url) {
    return this.language.pipe(switchMap(language => this.http.get(url).pipe(tap(translations => {
      this.translateService.setTranslation(language, translations, true);
    }))));
  }
  /**
   * Merge external translation labels with existing ones from a json object
   */
  setExternalTranslationFromJson(translations) {
    return this.language.pipe(tap(language => {
      this.translateService.setTranslation(language, translations, true);
    }));
  }
  getAvailableLanguages() {
    return this.http.get(this.options.availableLanguagesResource);
  }
  setLanguageLoader() {
    this.log('Setup language loader observable');
    this.languages$ = combineLatest([this.getAvailableLanguages(), this.preferences$]).pipe(retry(1), tap(([languages, preferences]) => {
      this.log('languages received', languages);
      this.availableLanguages = languages?.length ? languages : [this.options.defaultLanguage];
      this.setLanguage(preferences['language'], preferences['language-fallback']);
    }), catchError(_err => {
      this.availableLanguages = [this.options.defaultLanguage];
      this.setLanguage();
      this.log('available languages resource not found');
      return of([]);
    }), shareReplay({
      bufferSize: 1,
      refCount: false
    }), onErrorResumeNextWith());
  }
  setLanguage(languagePreference = undefined, languagePreferenceFallback = undefined) {
    let found;
    function userLanguages(lang, browser) {
      return lang === useBrowserPreferenceFlag ? browser : lang ? [lang] : [];
    }
    const languageSequence = [...userLanguages(languagePreference, this.browserLanguages), ...userLanguages(languagePreferenceFallback, []), ...this.browserLanguages, this.options.defaultLanguage];
    this.log('languages: available:', this.availableLanguages);
    this.log('languages: browser preference:', this.browserLanguages);
    this.log('languages: user preference:', languagePreference);
    this.log('languages: user preference fallback:', languagePreferenceFallback);
    this.log('languages: final sequence:', languageSequence);
    languageSequence.find(locale => {
      this.log('checking locale', locale);
      const [planguage] = locale.split('-');
      this.log('planguage:', planguage);
      let match = '';
      for (const language of this.availableLanguages) {
        const [alanguage] = language.split('-');
        this.log('language:', language);
        if (locale === language) {
          this.log('full match on', language);
          match = 'full';
          found = language;
          return true;
        }
        if (planguage === alanguage && !match) {
          this.log('partial match on', language);
          match = 'partial';
          found = language;
        }
      }
      this.log('matched on', found, match);
      return !!match;
    });
    this.log('languages: selected', found);
    this.translateService.use(found);
    this.languageCode = found;
  }
  determineBrowserLanguages() {
    // TODO: only the first language preference is used now, make it work with the entire array of preferred languages, story: 243006
    const culture = this.translateService.getBrowserCultureLang();
    const browser = this.translateService.getBrowserLang();
    this.log('culture:', culture, 'browser:', browser, 'default:', this.options.defaultLanguage);
    this.browserLanguages.push(culture ? culture : browser ? browser : this.options.defaultLanguage);
  }
  log(...msg) {
    if (this.debug) {
      /* eslint-disable-next-line no-console */
      console.log(...msg);
    }
  }
  static {
    this.ɵfac = function PrivaLocalizationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLocalizationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaLocalizationService,
      factory: PrivaLocalizationService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLocalizationService, [{
    type: Injectable
  }], () => [], null);
})();
class PrivaDateTimeService {
  constructor(preferencesService, localizationService) {
    this.preferencesService = preferencesService;
    this.localizationService = localizationService;
    this.options$ = this.loadPreferences().pipe(shareReplay(1));
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  format(date, ...formatters) {
    return this.options$.pipe(map(([endianness, meridiem, duration, country]) => {
      return formatters.reduce((acc, formatter) => [...acc, formatter({
        endianness,
        meridiem,
        duration,
        country
      }, date)], []);
    }), map(formats => {
      return formats.map((formatter, i) => i === 0 ? formatter.formatted : formatter.prefix + formatter.formatted).join('');
    }));
  }
  loadPreferences() {
    return combineLatest([this.preferencesService.endianness, this.preferencesService.meridiem, this.preferencesService.duration, this.localizationService.country]);
  }
  static {
    this.ɵfac = function PrivaDateTimeService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDateTimeService)(i0.ɵɵinject(PrivaLocalizationPreferencesService), i0.ɵɵinject(PrivaLocalizationService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaDateTimeService,
      factory: PrivaDateTimeService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDateTimeService, [{
    type: Injectable
  }], () => [{
    type: PrivaLocalizationPreferencesService
  }, {
    type: PrivaLocalizationService
  }], null);
})();
class DateTimeFormatPipe {
  constructor(datetimeFormatService) {
    this.datetimeFormatService = datetimeFormatService;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform(value, ...formatters) {
    return this.datetimeFormatService.format(value, ...formatters);
  }
  static {
    this.ɵfac = function DateTimeFormatPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DateTimeFormatPipe)(i0.ɵɵdirectiveInject(PrivaDateTimeService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "datetime",
      type: DateTimeFormatPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeFormatPipe, [{
    type: Pipe,
    args: [{
      name: 'datetime'
    }]
  }], () => [{
    type: PrivaDateTimeService
  }], null);
})();
class DateTimeModule {
  static {
    this.ɵfac = function DateTimeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DateTimeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DateTimeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PrivaDateTimeService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimeModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [DateTimeFormatPipe],
      declarations: [DateTimeFormatPipe],
      providers: [PrivaDateTimeService]
    }]
  }], null, null);
})();
class LanguageInterceptor {
  constructor(localizationService, preferencesService, {
    path,
    availableLanguagesResource
  }) {
    this.localizationService = localizationService;
    this.preferencesService = preferencesService;
    this.debug = false;
    this.requestBlackList = [];
    const authenticationApi = ['/bff', '/privaauth'];
    const preferencesApi = this.preferencesService.apiUrl ? [this.preferencesService.apiUrl] : [];
    this.requestBlackList.push(path, availableLanguagesResource, ...authenticationApi, ...preferencesApi);
  }
  intercept(request, next) {
    if (this.requestBlackList.some(url => request.url.startsWith(url))) {
      this.log('dont intercept: ', request.url);
      return next.handle(request);
    }
    this.log('do intercept: ', request.url);
    return this.localizationService.language.pipe(mergeMap(language => {
      this.log('language is', language);
      if (language) {
        request = request.clone({
          setHeaders: {
            'Accept-Language': language
          }
        });
      }
      return next.handle(request);
    }));
  }
  log(...msg) {
    if (this.debug) {
      /* eslint-disable-next-line no-console */
      console.log(...msg);
    }
  }
  static {
    this.ɵfac = function LanguageInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LanguageInterceptor)(i0.ɵɵinject(PrivaLocalizationService), i0.ɵɵinject(PrivaPreferencesService), i0.ɵɵinject(PrivaLocalizationOptions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LanguageInterceptor,
      factory: LanguageInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LanguageInterceptor, [{
    type: Injectable
  }], () => [{
    type: PrivaLocalizationService
  }, {
    type: PrivaPreferencesService
  }, {
    type: PrivaLocalizationOptions
  }], null);
})();
class PrivaLocalizationResolver {
  constructor(localizationService) {
    this.localizationService = localizationService;
    this.localization$ = new ReplaySubject(1);
  }
  resolve() {
    this.localizationService.language.subscribe(language => {
      this.localization$.next(!!language);
      this.localization$.complete();
    });
    return this.localization$.asObservable();
  }
  static {
    this.ɵfac = function PrivaLocalizationResolver_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLocalizationResolver)(i0.ɵɵinject(PrivaLocalizationService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaLocalizationResolver,
      factory: PrivaLocalizationResolver.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLocalizationResolver, [{
    type: Injectable
  }], () => [{
    type: PrivaLocalizationService
  }], null);
})();
class PrivaLocalizationModule {
  static forRoot(optionsProvider, preferencesProvider) {
    return {
      ngModule: PrivaLocalizationModule,
      providers: [optionsProvider || PrivaLocalizationModule.provideDefaultLocalizationOptions(), preferencesProvider || PrivaLocalizationModule.provideDefaultLocalizationPreferencesService(), PrivaPreferencesService, PrivaLocalizationService, {
        provide: HTTP_INTERCEPTORS,
        useClass: LanguageInterceptor,
        multi: true
      }]
    };
  }
  static provideDefaultLocalizationOptions() {
    return {
      provide: PrivaLocalizationOptions,
      useClass: PrivaLocalizationOptions
    };
  }
  static provideDefaultLocalizationPreferencesService() {
    return {
      provide: PrivaLocalizationPreferencesService,
      useClass: PrivaLocalizationPreferencesService
    };
  }
  static {
    this.ɵfac = function PrivaLocalizationModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaLocalizationModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaLocalizationModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PrivaLocalizationResolver],
      imports: [CommonModule, PrivaTranslateModule, DateTimeModule, PrivaCurrencyModule, DateTimeModule, PrivaCurrencyModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaLocalizationModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaTranslateModule, DateTimeModule, PrivaCurrencyModule],
      exports: [DateTimeModule, TranslatePipe, TranslateDirective, PrivaCurrencyModule],
      declarations: [],
      providers: [PrivaLocalizationResolver]
    }]
  }], null, null);
})();

/*
    This class replaces the default translation loader. The default loader would cause the
    translations to be cached.
    We want to inject the application version into the url so that every update of the website
    reloads the translation files at least once.
*/
class VersionBasedTranslationLoader {
  constructor(http, options) {
    this.http = http;
    this.options = options;
  }
  getTranslation(lang) {
    const url = `${this.options.path}/${lang}${this.options.translationFileSuffix}` + `?version=${this.options.version}`;
    return this.http.get(url);
  }
}
var TimeFormatTypes;
(function (TimeFormatTypes) {
  TimeFormatTypes["HM"] = "HM";
  TimeFormatTypes["HMS"] = "HMS";
  TimeFormatTypes["HMSMS"] = "HMSMS";
})(TimeFormatTypes || (TimeFormatTypes = {}));
var DateFormatTypes;
(function (DateFormatTypes) {
  DateFormatTypes["Date"] = "DATE";
  DateFormatTypes["PaddedDate"] = "PADDED_DATE";
  DateFormatTypes["ShortDate"] = "SHORT_DATE";
  DateFormatTypes["LongDate"] = "LONG_DATE";
})(DateFormatTypes || (DateFormatTypes = {}));
var DateElementTypes;
(function (DateElementTypes) {
  DateElementTypes["ShortDay"] = "SHORT_DAY";
  DateElementTypes["LongDay"] = "LONG_DAY";
})(DateElementTypes || (DateElementTypes = {}));
var DateExceptions;
(function (DateExceptions) {
  DateExceptions["CurrentYear"] = "CURRENT_YEAR";
  DateExceptions["Default"] = "DEFAULT";
})(DateExceptions || (DateExceptions = {}));
var DurationTypes;
(function (DurationTypes) {
  DurationTypes["Relative"] = "RELATIVE";
  DurationTypes["Absolute"] = "ABSOLUTE";
})(DurationTypes || (DurationTypes = {}));
const CONFIG = {
  TIME: {
    REGULAR: {
      HM: 'h:mm aaaa',
      // 1:06 p.m.
      HMS: 'h:mm:ss aaaa',
      // 1:06:09 p.m.
      HMSMS: 'h:mm:ss.SSS aaaa' // 1:06:09.026 p.m.
    },
    MILITARY: {
      HM: 'H:mm',
      // 13:06
      HMS: 'H:mm:ss',
      // 13:06:09
      HMSMS: 'H:mm:ss.SSS' // 13:06:09.026
    }
  },
  DATE: {
    LITTLE: {
      CURRENT_YEAR: {
        DATE: 'd-M-y',
        // 12-4-2019
        PADDED_DATE: 'dd-MM-yyyy',
        // 12-04-2019
        SHORT_DATE: 'd MMM',
        // 3 Aug
        LONG_DATE: 'd MMMM' // 3 August
      },
      DEFAULT: {
        DATE: 'd-M-y',
        // 12-4-2019
        PADDED_DATE: 'dd-MM-yyyy',
        // 12-04-2019
        SHORT_DATE: 'd MMM yyyy',
        // 3 Aug 2019
        LONG_DATE: 'd MMMM yyyy' // 3 August 2019
      }
    },
    MIDDLE: {
      CURRENT_YEAR: {
        DATE: 'M-d-y',
        // 4-12-2019
        PADDED_DATE: 'MM-dd-yyyy',
        // 04-12-2019
        SHORT_DATE: 'MMM d',
        // Aug 3
        LONG_DATE: 'MMMM d' // August 3
      },
      DEFAULT: {
        DATE: 'M-d-y',
        // 4-12-2019
        PADDED_DATE: 'MM-dd-yyyy',
        // 04-12-2019
        SHORT_DATE: 'MMM d yyyy',
        // Aug 3 2019
        LONG_DATE: 'MMMM d yyyy' // August 3 2019
      }
    },
    BIG: {
      CURRENT_YEAR: {
        DATE: 'y-M-d',
        // 2019-4-12
        PADDED_DATE: 'yyyy-MM-dd',
        // 2019-04-12
        SHORT_DATE: 'MMM d',
        // Aug 3
        LONG_DATE: 'MMMM d' // August 3
      },
      DEFAULT: {
        DATE: 'y-M-d',
        // 2019-4-12
        PADDED_DATE: 'yyyy-MM-dd',
        // 2019-04-12
        SHORT_DATE: 'yyyy MMM d',
        // 2019 Aug 3
        LONG_DATE: 'yyyy MMMM d' // 2019 August 3
      }
    }
  },
  ELEMENTS: {
    SHORT_DAY: 'eee',
    LONG_DAY: 'eeee'
  }
};
class Formatter {
  constructor(formatted, prefix, suffix, dateUTC) {
    this.formatted = formatted;
    this.prefix = prefix;
    this.suffix = suffix;
    this.dateUTC = dateUTC;
  }
}
const locales$3 = {
  af,
  da,
  de,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  en: enUS,
  enUS,
  ru,
  sk,
  sv,
  zhCN,
  zh: zhCN
};
class DateFormatter {
  static {
    this.Date = DateFormatter.formatter(DateFormatTypes.Date);
  }
  static {
    this.PaddedDate = DateFormatter.formatter(DateFormatTypes.PaddedDate);
  }
  static {
    this.ShortDate = DateFormatter.formatter(DateFormatTypes.ShortDate);
  }
  static {
    this.LongDate = DateFormatter.formatter(DateFormatTypes.LongDate);
  }
  static formatter(type) {
    const formatter = ({
      endianness,
      country
    }, date) => {
      const exception = date.getFullYear() === new Date().getFullYear() ? DateExceptions.CurrentYear : DateExceptions.Default;
      return new Formatter(format(date, CONFIG.DATE[endianness][exception][type], {
        locale: locales$3[country]
      }), ' ', '', date.toUTCString());
    };
    return formatter;
  }
}
const locales$2 = {
  af,
  da,
  de,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  en: enUS,
  enUS,
  ru,
  sk,
  sv,
  zhCN,
  zh: zhCN
};
class TimeFormatter {
  static {
    this.HM = TimeFormatter.formatter(TimeFormatTypes.HM);
  }
  static {
    this.HMS = TimeFormatter.formatter(TimeFormatTypes.HMS);
  }
  static {
    this.HMSMS = TimeFormatter.formatter(TimeFormatTypes.HMSMS);
  }
  static formatter(type) {
    const formatter = ({
      meridiem,
      country
    }, date) => new Formatter(format(date, CONFIG.TIME[meridiem][type], {
      locale: locales$2[country]
    }), ', ', '', date.toUTCString());
    return formatter;
  }
}
const locales$1 = {
  af,
  da,
  de,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  en: enUS,
  enUS,
  ru,
  sk,
  sv,
  zhCN,
  zh: zhCN
};
class DateElementsFormatter {
  static {
    this.ShortDay = DateElementsFormatter.formatter(DateElementTypes.ShortDay);
  }
  static {
    this.LongDay = DateElementsFormatter.formatter(DateElementTypes.LongDay);
  }
  static formatter(type) {
    const formatter = ({
      country
    }, date) => new Formatter(format(date, CONFIG['ELEMENTS'][type], {
      locale: locales$1[country]
    }), '', '', date.toUTCString());
    return formatter;
  }
}
const locales = {
  af,
  da,
  de,
  es,
  fi,
  fr,
  hu,
  it,
  ja,
  ko,
  nl,
  pl,
  en: enUS,
  enUS,
  ru,
  sk,
  sv,
  zhCN,
  zh: zhCN
};
class DurationFormatter {
  static {
    this.Relative = DurationFormatter.formatter(DurationTypes.Relative);
  }
  static {
    this.Absolute = DurationFormatter.formatter(DurationTypes.Absolute);
  }
  static formatter(type) {
    const formatter = ({
      duration: options,
      country
    }, date) => {
      const now = new Date();
      const [from, to = now] = date instanceof Date ? [date] : date;
      if (type === DurationTypes.Absolute) {
        options = {
          ...options,
          addSuffix: false,
          locale: locales[country]
        };
      } else {
        options = {
          ...options,
          locale: locales[country]
        };
      }
      return new Formatter(formatDistance(from, to, options), ' ', '', [from.toUTCString(), to.toUTCString()]);
    };
    return formatter;
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { DateElementsFormatter, DateFormatTypes, DateFormatter, DateTimeFormatPipe, DateTimeModule, DurationFormatter, Endianness, FirstDayOfWeek, Meridiem, PrivaCurrencyModule, PrivaCurrencyPipe, PrivaLocalizationModule, PrivaLocalizationOptions, PrivaLocalizationPreferencesService, PrivaLocalizationResolver, PrivaLocalizationService, PrivaTranslateModule, TimeFormatTypes, TimeFormatter, PrivaTranslateModule as TranslateModule, VersionBasedTranslationLoader };
