import { __extends } from "tslib";
import { Emitter } from '@segment/analytics-generic-utils';
import { backoff } from './backoff';
/**
 * @internal
 */
export var ON_REMOVE_FROM_FUTURE = 'onRemoveFromFuture';
var PriorityQueue = /** @class */function (_super) {
  __extends(PriorityQueue, _super);
  function PriorityQueue(maxAttempts, queue, seen) {
    var _this = _super.call(this) || this;
    _this.future = [];
    _this.maxAttempts = maxAttempts;
    _this.queue = queue;
    _this.seen = seen !== null && seen !== void 0 ? seen : {};
    return _this;
  }
  PriorityQueue.prototype.push = function () {
    var _this = this;
    var items = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      items[_i] = arguments[_i];
    }
    var accepted = items.map(function (operation) {
      var attempts = _this.updateAttempts(operation);
      if (attempts > _this.maxAttempts || _this.includes(operation)) {
        return false;
      }
      _this.queue.push(operation);
      return true;
    });
    this.queue = this.queue.sort(function (a, b) {
      return _this.getAttempts(a) - _this.getAttempts(b);
    });
    return accepted;
  };
  PriorityQueue.prototype.pushWithBackoff = function (item, minTimeout) {
    var _this = this;
    if (minTimeout === void 0) {
      minTimeout = 0;
    }
    // One immediate retry unless we have a minimum timeout (e.g. for rate limiting)
    if (minTimeout == 0 && this.getAttempts(item) === 0) {
      return this.push(item)[0];
    }
    var attempt = this.updateAttempts(item);
    if (attempt > this.maxAttempts || this.includes(item)) {
      return false;
    }
    var timeout = backoff({
      attempt: attempt - 1
    });
    if (minTimeout > 0 && timeout < minTimeout) {
      timeout = minTimeout;
    }
    setTimeout(function () {
      _this.queue.push(item);
      // remove from future list
      _this.future = _this.future.filter(function (f) {
        return f.id !== item.id;
      });
      // Lets listeners know that a 'future' message is now available in the queue
      _this.emit(ON_REMOVE_FROM_FUTURE);
    }, timeout);
    this.future.push(item);
    return true;
  };
  PriorityQueue.prototype.getAttempts = function (item) {
    var _a;
    return (_a = this.seen[item.id]) !== null && _a !== void 0 ? _a : 0;
  };
  PriorityQueue.prototype.updateAttempts = function (item) {
    this.seen[item.id] = this.getAttempts(item) + 1;
    return this.getAttempts(item);
  };
  PriorityQueue.prototype.includes = function (item) {
    return this.queue.includes(item) || this.future.includes(item) || Boolean(this.queue.find(function (i) {
      return i.id === item.id;
    })) || Boolean(this.future.find(function (i) {
      return i.id === item.id;
    }));
  };
  PriorityQueue.prototype.pop = function () {
    return this.queue.shift();
  };
  Object.defineProperty(PriorityQueue.prototype, "length", {
    get: function () {
      return this.queue.length;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(PriorityQueue.prototype, "todo", {
    get: function () {
      return this.queue.length + this.future.length;
    },
    enumerable: false,
    configurable: true
  });
  return PriorityQueue;
}(Emitter);
export { PriorityQueue };
