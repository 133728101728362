import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes, withComponentInputBinding } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';

import { AppMainComponent } from './app-main.component';
import { AppConfigurationResolver } from './common/configuration/app-configuration.resolver';
import { CanDeactivateGuard } from './control/guards/can-deactivate.guard';

const routes: Routes = [
    {
        path: 'error',
        loadChildren: () => import('./common/routing/error-pages.module').then((m) => m.AppErrorPagesModule),
    },
    {
        path: 'signout',
        loadChildren: () => import('./common/routing/sign-out.module').then((m) => m.AppSignOutModule),
    },
    {
        path: '',
        component: AppMainComponent,
        canActivate: [PrivaBffLoggedInUserGuard],
        resolve: {
            appConfig: AppConfigurationResolver,
        },
        children: [
            {
                path: 'spatial',
                loadChildren: () =>
                    import('./spatial/spatial-model.routing.module').then((m) => m.SpatialModelRoutingModule),
            },
            {
                path: 'control',
                loadComponent: async () =>
                    (await import('./control/control-model.component')).ControlModelComponent,
                canDeactivate: [CanDeactivateGuard],
            },
            {
                path: '',
                redirectTo: 'spatial',
                pathMatch: 'full',
            },
        ],
    },
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [provideRouter(routes, withComponentInputBinding()), AppConfigurationResolver],
})
export class AppRoutingModule {}
