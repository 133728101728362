import * as i3 from '@angular/cdk/observers';
import { ObserversModule } from '@angular/cdk/observers';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, ElementRef, Component, ChangeDetectionStrategy, Input, ViewChild, Output, NgModule } from '@angular/core';
import * as i2 from '@priva/components/status-circle';
import { PrivaStatusCircleModule } from '@priva/components/status-circle';
import { PrivaLocalizationModule } from '@priva/localization';
import * as i4 from '@ngx-translate/core';
const _c0 = ["label"];
const _c1 = ["*"];
const _c2 = (a0, a1, a2, a3, a4, a5) => [a0, a1, a2, a3, a4, a5];
function PrivaTagComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("tag__icon ", ctx_r1.icon, "");
  }
}
function PrivaTagComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 6);
    i0.ɵɵpipe(1, "translate");
    i0.ɵɵlistener("click", function PrivaTagComponent_Conditional_6_Template_button_click_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dismissTag.emit($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 1, ctx_r1.triggerText));
  }
}
function PrivaTagComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "priva-status-circle", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("theme", ctx_r1.theme)("status", ctx_r1.statusCircle);
  }
}
var PrivaTagCategory;
(function (PrivaTagCategory) {
  PrivaTagCategory["greenblue"] = "tag--category-green-blue";
  PrivaTagCategory["blue"] = "tag--category-blue";
  PrivaTagCategory["orange"] = "tag--category-orange";
  PrivaTagCategory["purple"] = "tag--category-purple";
  PrivaTagCategory["green"] = "tag--category-green";
  PrivaTagCategory["red"] = "tag--category-red";
  PrivaTagCategory["black"] = "tag--category-black";
})(PrivaTagCategory || (PrivaTagCategory = {}));
var PrivaTagOverflow;
(function (PrivaTagOverflow) {
  PrivaTagOverflow["wrap"] = "tag--overflow-wrap";
  PrivaTagOverflow["truncate"] = "tag--overflow-truncate";
})(PrivaTagOverflow || (PrivaTagOverflow = {}));
var PrivaTagDensity;
(function (PrivaTagDensity) {
  PrivaTagDensity["sm"] = "tag--density-sm";
  PrivaTagDensity["md"] = "tag--density-md";
})(PrivaTagDensity || (PrivaTagDensity = {}));
var PrivaTagTheme;
(function (PrivaTagTheme) {
  PrivaTagTheme["onwhite"] = "tag--theme-on-white";
  PrivaTagTheme["onlightgray"] = "tag--theme-on-light-gray";
  PrivaTagTheme["onlightblue"] = "tag--theme-on-light-blue";
  PrivaTagTheme["ondarkblue"] = "tag--theme-on-dark-blue";
})(PrivaTagTheme || (PrivaTagTheme = {}));
class PrivaTagComponent {
  constructor(cdr) {
    this.cdr = cdr;
    /** @deprecated since tag styling is now theme independent */
    this.theme = 'onwhite';
    this.triggerText = 'GLOBAL.ACTION.DISMISS';
    this.variant = 'outline';
    this.split = 'none';
    this.textOverflow = 'wrap';
    this.density = 'md';
    this.size = 'sm';
    this.category = 'blue';
    this.isInactive = false;
    this.isRemovable = false;
    this.dismissTag = new EventEmitter();
    this.PrivaTagOverflow = PrivaTagOverflow;
    this.PrivaTagDensity = PrivaTagDensity;
    this.PrivaTagCategory = PrivaTagCategory;
    this.PrivaTagTheme = PrivaTagTheme;
  }
  ngAfterContentInit() {
    this.checkLabelContent();
  }
  checkLabelContent() {
    const trimmedLabel = (this.label.nativeElement?.textContent || '')?.trim();
    this.hasLabel = !!trimmedLabel.length;
    this.cdr.detectChanges();
  }
  static {
    this.ɵfac = function PrivaTagComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTagComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaTagComponent,
      selectors: [["priva-tag"]],
      viewQuery: function PrivaTagComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7, ElementRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.label = _t.first);
        }
      },
      inputs: {
        theme: "theme",
        title: "title",
        icon: "icon",
        triggerText: "triggerText",
        variant: "variant",
        split: "split",
        textOverflow: "textOverflow",
        density: "density",
        size: "size",
        category: "category",
        statusCircle: "statusCircle",
        isInactive: "isInactive",
        isRemovable: "isRemovable"
      },
      outputs: {
        dismissTag: "dismissTag"
      },
      ngContentSelectors: _c1,
      decls: 8,
      vars: 26,
      consts: [["label", ""], [1, "tag", 3, "ngClass"], [3, "class"], [1, "tag__label", 3, "cdkObserveContent"], ["type", "button", 1, "tag__trigger", "tag__trigger--remove"], [3, "theme", "status"], ["type", "button", 1, "tag__trigger", "tag__trigger--remove", 3, "click"]],
      template: function PrivaTagComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "span", 1);
          i0.ɵɵpipe(1, "translate");
          i0.ɵɵtemplate(2, PrivaTagComponent_Conditional_2_Template, 1, 3, "span", 2);
          i0.ɵɵelementStart(3, "span", 3, 0);
          i0.ɵɵlistener("cdkObserveContent", function PrivaTagComponent_Template_span_cdkObserveContent_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.checkLabelContent());
          });
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, PrivaTagComponent_Conditional_6_Template, 2, 3, "button", 4)(7, PrivaTagComponent_Conditional_7_Template, 1, 2, "priva-status-circle", 5);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("is-inactive", ctx.isInactive)("tag--variant-outline", ctx.variant === "outline")("tag--variant-solid", ctx.variant === "solid")("tag--variant-flat", ctx.variant === "flat")("tag--icon-only", ctx.icon && !ctx.hasLabel && !ctx.isRemovable);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction6(19, _c2, ctx.PrivaTagCategory[ctx.category == null ? null : ctx.category.toLowerCase()], ctx.PrivaTagOverflow[ctx.textOverflow == null ? null : ctx.textOverflow.toLowerCase()], ctx.PrivaTagDensity[ctx.density == null ? null : ctx.density.toLowerCase()], ctx.PrivaTagTheme[ctx.theme == null ? null : ctx.theme.toLowerCase()], "tag--split-" + ctx.split, "tag--size-" + (ctx.size == null ? null : ctx.size.toLowerCase())));
          i0.ɵɵattribute("title", i0.ɵɵpipeBind1(1, 17, ctx.title) || null);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional(ctx.icon ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("hidden", !ctx.hasLabel);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.isRemovable ? 6 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.statusCircle ? 7 : -1);
        }
      },
      dependencies: [i1.NgClass, i2.PrivaStatusCircleComponent, i3.CdkObserveContent, i4.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:inline-block;min-width:0;text-align:center}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTagComponent, [{
    type: Component,
    args: [{
      selector: 'priva-tag',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<span\n    class=\"tag\"\n    [ngClass]=\"[\n        PrivaTagCategory[category?.toLowerCase()],\n        PrivaTagOverflow[textOverflow?.toLowerCase()],\n        PrivaTagDensity[density?.toLowerCase()],\n        PrivaTagTheme[theme?.toLowerCase()],\n        'tag--split-' + split,\n        'tag--size-' + size?.toLowerCase(),\n    ]\"\n    [class.is-inactive]=\"isInactive\"\n    [class.tag--variant-outline]=\"variant === 'outline'\"\n    [class.tag--variant-solid]=\"variant === 'solid'\"\n    [class.tag--variant-flat]=\"variant === 'flat'\"\n    [class.tag--icon-only]=\"icon && !hasLabel && !isRemovable\"\n    [attr.title]=\"(title | translate) || null\"\n>\n    @if (icon) {\n        <span class=\"tag__icon {{ icon }}\"></span>\n    }\n    <span class=\"tag__label\" [class.hidden]=\"!hasLabel\" (cdkObserveContent)=\"checkLabelContent()\" #label\n        ><ng-content\n    /></span>\n    @if (isRemovable) {\n        <button\n            type=\"button\"\n            class=\"tag__trigger tag__trigger--remove\"\n            [attr.aria-label]=\"triggerText | translate\"\n            (click)=\"dismissTag.emit($event)\"\n        ></button>\n    }\n    @if (statusCircle) {\n        <priva-status-circle [theme]=\"theme\" [status]=\"statusCircle\" />\n    }\n</span>\n",
      styles: [":host{display:inline-block;min-width:0;text-align:center}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    theme: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    triggerText: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    split: [{
      type: Input
    }],
    textOverflow: [{
      type: Input
    }],
    density: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    category: [{
      type: Input
    }],
    statusCircle: [{
      type: Input
    }],
    isInactive: [{
      type: Input
    }],
    isRemovable: [{
      type: Input
    }],
    label: [{
      type: ViewChild,
      args: ['label', {
        read: ElementRef,
        static: true
      }]
    }],
    dismissTag: [{
      type: Output
    }]
  });
})();
class PrivaTagModule {
  static {
    this.ɵfac = function PrivaTagModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTagModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaTagModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaStatusCircleModule, ObserversModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTagModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaStatusCircleModule, ObserversModule, PrivaLocalizationModule],
      declarations: [PrivaTagComponent],
      exports: [PrivaTagComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaTagComponent, PrivaTagModule };
