import * as i0 from '@angular/core';
import { Injectable, Directive, Input, HostListener, NgModule } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';

/**
 * PrivaToggleState is used to maintain the state for one specific toggling place in the layout.
 * E.g. for two toggeable menu's, each menu will have its own instance of PrivaToggleState.
 */
class PrivaToggleState {
  constructor(toggleId) {
    this.dataSubject = new BehaviorSubject(false);
    this.toggled = false;
    this.toggleId = toggleId;
    this.dataObserver = this.dataSubject.asObservable();
  }
  toggle() {
    this.toggled = !this.toggled;
    this.update();
  }
  activate() {
    this.toggled = true;
    this.update();
  }
  deactivate() {
    this.toggled = false;
    this.update();
  }
  update() {
    this.dataSubject.next(this.toggled);
  }
}
const DEFAULT_TARGET = '##DEFAULT##';
/**
 * PrivaToggleService is responsible for managing the layout state.
 */
class PrivaToggleService {
  constructor() {
    this.toggleStates = {};
  }
  /**
   * Toggles the specified target. Subscribers to the toggle
   * target can handle layout changes when needed.
   */
  toggleOn(target = DEFAULT_TARGET, excludeTarget = '') {
    const toggleStates = this.getToggleStates(target, excludeTarget);
    toggleStates.forEach(state => {
      state.toggle();
    });
  }
  /**
   * Activates the specified target. Subscribers to the toggle
   * target can handle layout changes when needed.
   */
  activate(target = DEFAULT_TARGET, excludeTarget = '') {
    const toggleStates = this.getToggleStates(target, excludeTarget);
    toggleStates.forEach(state => {
      state.activate();
    });
  }
  /**
   * Deactivates the specified target. Subscribers to the toggle
   * target can handle layout changes when needed.
   */
  deactivate(target = DEFAULT_TARGET, excludeTarget = '') {
    const toggleStates = this.getToggleStates(target, excludeTarget);
    toggleStates.forEach(state => {
      state.deactivate();
    });
  }
  /**
   * Gets the observable for the specified target.
   * This can be used to subscribe for changes.
   */
  getObserver(target = DEFAULT_TARGET) {
    // Create an array out of the target as is might contain a nested path.
    const targetArray = target.split('.');
    let toggleState = targetArray.reduce((prevObj, key) => prevObj && prevObj[key], this.toggleStates);
    if (!toggleState) {
      toggleState = new PrivaToggleState(target);
      // setting value
      targetArray.reduce((entry, key, index) => {
        if (entry[key] === undefined) entry[key] = {};
        if (index === targetArray.length - 1) entry[key] = toggleState;
        return entry[key];
      }, this.toggleStates);
    }
    return toggleState.dataObserver;
  }
  getToggleStates(toggleTarget, excludeTarget = '') {
    // Create an array out of the target as is might contain a nested path.
    const targetArray = toggleTarget.split('.');
    let stateObj = targetArray.reduce((prevObj, key) => prevObj && prevObj[key], this.toggleStates);
    if (!stateObj) {
      return [];
    }
    // Make a uniform key:value format for the stateObj
    if (stateObj instanceof PrivaToggleState) {
      stateObj = {
        toggleTarget: stateObj
      };
    }
    // Convert to array and filter out the excluded
    return Object.values(stateObj).filter(state => state.toggleId !== excludeTarget);
  }
  static {
    this.ɵfac = function PrivaToggleService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaToggleService,
      factory: PrivaToggleService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleService, [{
    type: Injectable
  }], null, null);
})();

/**
 * Directive that can be used to activate some layout component.
 * It tells the @type {PrivaToggleService} to activate the toggleTarget.
 * All listeners on the target will activate some element/attribute
 */
class PrivaToggleActivateDirective {
  constructor(privaToggleService) {
    this.privaToggleService = privaToggleService;
    this.DEFAULT_TARGET = '##DEFAULT##';
  }
  onClick() {
    this.activate();
  }
  activate() {
    this.activateTarget = !this.activateTarget ? this.DEFAULT_TARGET : this.activateTarget;
    switch (true) {
      case typeof this.activateTarget === 'string':
        this.privaToggleService.activate(this.activateTarget);
        break;
      case this.activateTarget instanceof Array:
        for (const item of this.activateTarget) {
          this.privaToggleService.activate(item);
        }
        break;
      default:
        this.privaToggleService.activate(this.DEFAULT_TARGET);
        break;
    }
  }
  static {
    this.ɵfac = function PrivaToggleActivateDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleActivateDirective)(i0.ɵɵdirectiveInject(PrivaToggleService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaToggleActivateDirective,
      selectors: [["", "priva-toggle-activate", ""]],
      hostBindings: function PrivaToggleActivateDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PrivaToggleActivateDirective_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        activateTarget: [0, "priva-toggle-activate", "activateTarget"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleActivateDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-toggle-activate]'
    }]
  }], () => [{
    type: PrivaToggleService
  }], {
    activateTarget: [{
      type: Input,
      args: ['priva-toggle-activate']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class PrivaToggleClassDirective {
  set toggleClass(value) {
    this.toggleClassesRawValue = value;
  }
  constructor(privaToggleService, element, renderer) {
    this.privaToggleService = privaToggleService;
    this.element = element;
    this.renderer = renderer;
    this.DEFAULT_TARGET = '##DEFAULT##';
  }
  ngOnInit() {
    const map = this.createToggleClassMap();
    map.forEach((classes, toggleId) => {
      this.privaToggleService.getObserver(toggleId).subscribe(toggled => {
        for (const state in classes) {
          if (Object.hasOwn(classes, state)) {
            classes[state].forEach(className => {
              if (className) {
                if ((toggled ? 'true' : 'false') === state) {
                  this.renderer.addClass(this.element.nativeElement, className);
                } else {
                  this.renderer.removeClass(this.element.nativeElement, className);
                }
              }
            });
          }
        }
      });
    });
  }
  /** Create toggleClassMap from toggleClass input values */
  createToggleClassMap() {
    const toggleClassesMap = new Map();
    this.updateMapFromRawValue(toggleClassesMap, undefined, this.toggleClassesRawValue);
    return toggleClassesMap;
  }
  /** Recursive method for setting map values of the ToggleClassType values */
  updateMapFromRawValue(map, key = this.DEFAULT_TARGET, value) {
    switch (true) {
      case typeof value === 'string':
        map.set(key, {
          true: [value],
          false: ['']
        });
        break;
      case value instanceof Array:
        map.set(key, {
          true: value,
          false: ['']
        });
        break;
      default:
        {
          const toggleClassObjectValue = value;
          if (!toggleClassObjectValue) {
            return;
          }
          for (const item in toggleClassObjectValue) {
            if (Object.hasOwn(toggleClassObjectValue, item)) {
              // eslint-disable-next-line no-prototype-builtins
              if (toggleClassObjectValue[item].hasOwnProperty('true')) {
                this.updateMapFromToggleClassStateObject(map, item, toggleClassObjectValue[item]);
              } else {
                this.updateMapFromRawValue(map, item, toggleClassObjectValue[item]);
              }
            }
          }
          break;
        }
    }
  }
  /** Method for setting map values of the ToggleClassStateObject type */
  updateMapFromToggleClassStateObject(map, key = this.DEFAULT_TARGET, value) {
    const keys = ['true', 'false'];
    const stateObject = {
      true: [''],
      false: ['']
    };
    keys.forEach(state => {
      if (typeof value[state] === 'string') {
        stateObject[state] = [value[state]];
      } else if (value instanceof Array) {
        stateObject[state] = value[state];
      }
    });
    map.set(key, stateObject);
  }
  static {
    this.ɵfac = function PrivaToggleClassDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleClassDirective)(i0.ɵɵdirectiveInject(PrivaToggleService), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaToggleClassDirective,
      selectors: [["", "priva-toggle-class", ""]],
      inputs: {
        toggleClass: [0, "priva-toggle-class", "toggleClass"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleClassDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-toggle-class]'
    }]
  }], () => [{
    type: PrivaToggleService
  }, {
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    toggleClass: [{
      type: Input,
      args: ['priva-toggle-class']
    }]
  });
})();

/**
 * Directive that can be used to deactivate some layout component.
 * It tells the @type {PrivaToggleService} to deactivate the toggleTarget.
 * All listeners on the target will deactivate some element/attribute
 */
class PrivaToggleDeactivateDirective {
  constructor(privaToggleService) {
    this.privaToggleService = privaToggleService;
    this.DEFAULT_TARGET = '##DEFAULT##';
  }
  onClick(event) {
    let toggleIdToExclude = '';
    if (event.target) {
      const privaToggleAttribute = this.getCurrentToggleAttribute(event);
      if (privaToggleAttribute) {
        // To make sure the click event of the parent will not
        // undo the previous action of the child.
        toggleIdToExclude = privaToggleAttribute.value;
      }
    }
    this.deactivateTargets(toggleIdToExclude);
  }
  // If we clicked at a node consisting of the attribute 'priva-toggle'
  // or 'priva-toggle-activate', we extract its toggle id to exclude this.
  // This is needed to prevent the bubble-up event from immediately undoing the previous action.
  getCurrentToggleAttribute(event) {
    if (event.target['attributes']) {
      return event.target['attributes'].getNamedItem('priva-toggle-activate') || event.target['attributes'].getNamedItem('priva-toggle-binding');
    }
    // Only look in priva-toggle-deactivate when the event originates
    // from the current node, so not from any of its parents
    if (event.currentTarget !== event.target) {
      return event.target['attributes'].getNamedItem('priva-toggle-deactivate');
    }
    throw new Error('TODO: WvK: Should not come here, fix!');
  }
  deactivateTargets(excludeTarget) {
    this.deactivateTarget = !this.deactivateTarget ? this.DEFAULT_TARGET : this.deactivateTarget;
    switch (true) {
      case typeof this.deactivateTarget === 'string':
        this.deactivate(this.deactivateTarget, excludeTarget);
        break;
      case this.deactivateTarget instanceof Array:
        for (const item of this.deactivateTarget) {
          this.deactivate(item, excludeTarget);
        }
        break;
      default:
        this.deactivate(this.DEFAULT_TARGET, excludeTarget);
        break;
    }
  }
  deactivate(target, excludeTarget = '') {
    if (excludeTarget) {
      this.privaToggleService.deactivate(target, excludeTarget);
    } else {
      this.privaToggleService.deactivate(target);
    }
  }
  static {
    this.ɵfac = function PrivaToggleDeactivateDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleDeactivateDirective)(i0.ɵɵdirectiveInject(PrivaToggleService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaToggleDeactivateDirective,
      selectors: [["", "priva-toggle-deactivate", ""]],
      hostBindings: function PrivaToggleDeactivateDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PrivaToggleDeactivateDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        deactivateTarget: [0, "priva-toggle-deactivate", "deactivateTarget"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleDeactivateDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-toggle-deactivate]'
    }]
  }], () => [{
    type: PrivaToggleService
  }], {
    deactivateTarget: [{
      type: Input,
      args: ['priva-toggle-deactivate']
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();

/**
 * Directive that can be used to toggle some layout component.
 * It tells the @type {PrivaToggleService} to toggle the toggleTarget.
 * All listeners on the toggleTarget will toggle some element/attribute
 */
class PrivaToggleDirective {
  get toggleTarget() {
    return this.target;
  }
  set toggleTarget(value) {
    this.target = value;
    if (this.renderer && value) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'priva-toggle-binding', value);
    }
  }
  constructor(privaToggleService, renderer, elRef) {
    this.privaToggleService = privaToggleService;
    this.renderer = renderer;
    this.elRef = elRef;
    this.DEFAULT_TARGET = '##DEFAULT##';
  }
  onClick() {
    this.toggle();
  }
  toggle() {
    this.toggleTarget = !this.toggleTarget ? this.DEFAULT_TARGET : this.toggleTarget;
    this.privaToggleService.toggleOn(this.toggleTarget);
  }
  static {
    this.ɵfac = function PrivaToggleDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleDirective)(i0.ɵɵdirectiveInject(PrivaToggleService), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaToggleDirective,
      selectors: [["", "priva-toggle", ""]],
      hostBindings: function PrivaToggleDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PrivaToggleDirective_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        toggleTarget: [0, "priva-toggle", "toggleTarget"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-toggle]'
    }]
  }], () => [{
    type: PrivaToggleService
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    toggleTarget: [{
      type: Input,
      args: ['priva-toggle']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();

/**
 * Directive that can be used to make sure that the click event does not bubbles up through the DOM.
 * Use with caution; it might have the undesired side-effects to block a
 * click event that actually had to be let through.
 */
class StopClickPropagationDirective {
  constructor() {
    this.stopPropagation = true;
  }
  set activate(value) {
    this.stopPropagation = typeof value === 'boolean' ? Boolean(value) : true;
  }
  onClick(event) {
    if (this.stopPropagation && event.cancelable) {
      event.stopPropagation();
    }
  }
  static {
    this.ɵfac = function StopClickPropagationDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || StopClickPropagationDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: StopClickPropagationDirective,
      selectors: [["", "priva-toggle-stop-click-propagation", ""]],
      hostBindings: function StopClickPropagationDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function StopClickPropagationDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          });
        }
      },
      inputs: {
        activate: [0, "priva-toggle-stop-click-propagation", "activate"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StopClickPropagationDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-toggle-stop-click-propagation]'
    }]
  }], null, {
    activate: [{
      type: Input,
      args: ['priva-toggle-stop-click-propagation']
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class PrivaToggleModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot() {
    return {
      ngModule: PrivaToggleModule,
      providers: [PrivaToggleService]
    };
  }
  static {
    this.ɵfac = function PrivaToggleModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaToggleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaToggleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaToggleModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [PrivaToggleDirective, PrivaToggleClassDirective, PrivaToggleActivateDirective, PrivaToggleDeactivateDirective, StopClickPropagationDirective],
      declarations: [PrivaToggleDirective, PrivaToggleClassDirective, PrivaToggleActivateDirective, PrivaToggleDeactivateDirective, StopClickPropagationDirective],
      providers: [
        /* Non singleton services */
      ]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaToggleActivateDirective, PrivaToggleClassDirective, PrivaToggleDeactivateDirective, PrivaToggleDirective, PrivaToggleModule, PrivaToggleService, PrivaToggleState, StopClickPropagationDirective };
