import { APP_BASE_HREF, CommonModule } from '@angular/common';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import {
    AppShellConfiguration,
    AppShellModule,
    AppShellUtilitiesModule,
    PrimaryNavigationService,
} from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { PrivaAuthSignoutModule } from '@priva/auth/signout';
import { PrivaDynamicModule } from '@priva/components/dynamic';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { AuthStore } from 'app/core/stores';
import { RowStore } from 'app/spatial/row/row-store/row-store';

import { buildInfo, environment } from '../environments/environment';
import { AppMainComponent } from './app-main.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigurationModule, AppConfigurationService } from './common/configuration';
import { AppConfigurationResolver } from './common/configuration/app-configuration.resolver';
import { AppPrimaryNavigationService, NavigationModule } from './common/routing';
import { HttpErrorInterceptor } from './core/interceptors/http-error/http-error.interceptor';
import { AppErrorHandlingModule, ErrorHandlerService } from './core/services/error-handling';
import { SitesStore, TopologyStore } from './core/stores/';
import { CompartmentStore } from './spatial/compartment/compartment-store/compartment-store';
import { GreenhouseStore } from './spatial/greenhouse/greenhouse-store/greenhouse-store';
import { AppEffects, appReducer } from './state';

export function getCustomAuthOptions(_configService: AppConfigurationService): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.bffConfig = { csrfValue: undefined };
    return options;
}

export function getAppShellConfig(configService: AppConfigurationService): AppShellConfiguration {
    return AppShellConfiguration.from(configService.configuration);
}

export function getAppInsightsKey(configService: AppConfigurationService): {
    instrumentationKey: string | undefined;
} {
    return {
        instrumentationKey: configService.configuration.analytics.appInsights
            ? configService.configuration.analytics.appInsights.instrumentationKey
            : undefined,
    };
}

export function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    return {
        path: 'assets/translations',
        version: buildInfo.version,
        translationFileSuffix: '.json',
        availableLanguagesResource: 'assets/translations/locales.json',
        defaultLanguage: 'en-US',
    };
}

export function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

/**
 * Preparation for State Management
 */
export const STORE_CONFIG = {
    runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: true,
        strictActionSerializability: true,
    },
};

/* istanbul ignore next lvb: depends on environment */
export const devImports = [
    StoreDevtoolsModule.instrument({
        maxAge: 250,
        logOnly: false,
    }),
];

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return (state, action) => {
        return reducer(state, action);
    };
}

const metaReducers: MetaReducer[] = !environment.production && environment.debugState ? [debug] : [];

@NgModule({
    declarations: [AppMainComponent, AppComponent],
    bootstrap: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        PrivaAuthHttpBffModule.forRoot({
            provide: PrivaAuthHttpOptions,
            useFactory: getCustomAuthOptions,
            deps: [AppConfigurationService],
        }),
        AppRoutingModule,
        PrivaDynamicModule,
        NavigationModule.forRoot(),
        PrivaAuthCurrentUserBffModule.forRoot(),
        AppConfigurationModule.forRoot(),
        PrivaWindowEventsModule.forRoot(),
        PrivaScrollbarModule.forRoot(),
        AppShellModule.forRoot(
            {
                provide: AppShellConfiguration,
                useFactory: getAppShellConfig,
                deps: [AppConfigurationService],
            },
            [
                AppShellModule.provideDefaultFeatureToggleRouter(),
                { provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService },
            ],
        ),
        AppShellUtilitiesModule,
        PrivaToggleModule.forRoot(),
        PrivaNotificationsModule.forRoot(),
        PrivaScrollToModule.forRoot(),
        PrivaAuthSignoutModule,
        AppErrorHandlingModule,
        /**
         * Localization, Storage & Translate configuration
         */
        PrivaLocalizationModule.forRoot({
            provide: PrivaLocalizationOptions,
            useFactory: getCustomLocalizationOptions,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: getVersionBasedTranslationLoader,
                deps: [HttpClient, PrivaLocalizationOptions],
            },
        }),
        /**
         * StateManagement, NgRx Store & Effects
         */
        StoreModule.forRoot(
            {
                app: appReducer,
            },
            { metaReducers, ...STORE_CONFIG },
        ),
        EffectsModule.forRoot([AppEffects]),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            registrationStrategy: 'registerImmediately',
        }),
        ...devImports,
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        AppConfigurationService,
        AppConfigurationResolver,
        ErrorHandlerService,
        PrivaErrorPageHandlerService,
        AuthStore,
        SitesStore,
        TopologyStore,
        GreenhouseStore,
        CompartmentStore,
        RowStore,
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {}
