import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, switchMap } from 'rxjs';

import { AppConfigurationService } from 'app/common/configuration';
import { CreatedGreenhouseDto, Greenhouse } from 'app/core/models';
import { CreatedLevelDto, CreateLevelDto } from 'app/core/models/level.model';
import { ApiEndPoints } from 'app/shared/constants/api-endpoints';

@Injectable({ providedIn: 'root' })
export class GreenHouseApiService {
    protected readonly http = inject(HttpClient);
    protected readonly appConfigurationService = inject(AppConfigurationService);
    protected readonly translate = inject(TranslateService);
    private readonly baseUrl = this.appConfigurationService.getBaseUrl('spatial');

    public getGreenHouseList(siteId: string): Observable<Greenhouse[]> {
        return this.http.get<Greenhouse[]>(ApiEndPoints.greenhouses.getListOrCreate(this.baseUrl, siteId));
    }

    public getGreenHouse(siteId: string, greenHouseId: string): Observable<Greenhouse> {
        return this.http.get<Greenhouse>(
            ApiEndPoints.greenhouses.getUpdateOrDeleteById(this.baseUrl, siteId, greenHouseId),
        );
    }

    public addGreenHouse(siteId: string, greenhouse: Greenhouse): Observable<Greenhouse> {
        return (
            this.http
                .post<CreatedGreenhouseDto>(
                    ApiEndPoints.greenhouses.getListOrCreate(this.baseUrl, siteId),
                    greenhouse,
                )
                // temp: #277930 hack until we remove Levels from GM
                .pipe(
                    switchMap((createdGreenhouse) => {
                        const level = {
                            name: `${createdGreenhouse.name}-groundFloorLevel`,
                            complexId: createdGreenhouse.id,
                            shortName: 'GF',
                            isGroundFloor: true,
                        } as CreateLevelDto;

                        return this.http
                            .post<CreatedLevelDto>(`${this.baseUrl}/sites/${siteId}/levels`, level)
                            .pipe(
                                map(
                                    (createdLevel) =>
                                        ({
                                            ...createdGreenhouse,
                                            groundFloorLevelId: createdLevel.id,
                                        }) as Greenhouse,
                                ),
                            );
                    }),
                )
        );
    }

    public updateGreenHouse(siteId: string, id: string, greenhouse: Greenhouse): Observable<Greenhouse> {
        if (!id) {
            throw new Error(this.translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'));
        }

        return this.getGreenHouse(siteId, id).pipe(
            switchMap((existingGreenhouse) => {
                if (existingGreenhouse.id !== id) {
                    throw new Error(this.translate.instant('ERROR.GREENHOUSE_ID_MISMATCH'));
                }

                const { name: _, type: __, ...otherProperties } = existingGreenhouse;

                const updatePayload: Greenhouse = {
                    ...greenhouse,
                    ...otherProperties,
                };

                return this.http.put<Greenhouse>(
                    ApiEndPoints.greenhouses.getUpdateOrDeleteById(this.baseUrl, siteId, id),
                    updatePayload,
                );
            }),
        );
    }

    public deleteGreenHouse(siteId: string, greenHouseId: string): Observable<void> {
        return this.http.delete<void>(
            ApiEndPoints.greenhouses.getUpdateOrDeleteById(this.baseUrl, siteId, greenHouseId),
        );
    }
}
