import { setLoaded, setLoading, withCallState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { CreateGreenhouseDto, Greenhouse, UpdateGreenhouseDto } from 'app/core/models/greenhouse.model';
import { ErrorHandlerService } from 'app/core/services/error-handling';
import { GreenHouseApiService } from 'app/core/services/greenhouse-api/greenhouse-api.service';
import { TopologyStore } from 'app/core/stores';
import { handleError, handleSuccess } from 'app/core/utils/store-helper-utils';

const GreenhouseCollection = 'greenhouses';

export type GreenhouseStoreType = InstanceType<typeof GreenhouseStore>;

export const GreenhouseStore = signalStore(
    withCallState({ collection: GreenhouseCollection }),
    withDevtools(GreenhouseCollection),
    withMethods((state) => {
        const greenHouseApiService = inject(GreenHouseApiService);
        const topologyStore = inject(TopologyStore);
        const translate = inject(TranslateService);
        const errorHandlerService = inject(ErrorHandlerService);

        const addGreenhouse = ({ siteId, name, type }: CreateGreenhouseDto & { siteId: string }) => {
            patchState(state, setLoading(GreenhouseCollection));
            greenHouseApiService.addGreenHouse(siteId, { name, type, siteId }).subscribe({
                next: (newGreenhouse) => {
                    if (!newGreenhouse.id) {
                        errorHandlerService.handleScriptMessages(
                            translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'),
                        );
                        return;
                    }

                    handleSuccess(
                        state,
                        [newGreenhouse],
                        (greenhouse) => greenhouse.id!,
                        GreenhouseCollection,
                    );

                    topologyStore.reloadTopology();
                },
                error: (_error) => {
                    handleError(
                        state,
                        translate.instant('ERROR.ADDING_GREENHOUSE', { greenhouse: name }),
                        GreenhouseCollection,
                    );
                },
            });
        };

        const updateGreenhouse = ({
            siteId,
            id,
            name,
            type,
        }: UpdateGreenhouseDto & { siteId: string; id: string }) => {
            patchState(state, setLoading(GreenhouseCollection));

            greenHouseApiService.updateGreenHouse(siteId, id, { name, type, siteId }).subscribe({
                next: (updatedGreenhouse) => {
                    if (!updatedGreenhouse.id) {
                        errorHandlerService.handleScriptMessages(
                            translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'),
                        );
                        return;
                    }

                    patchState(state, setLoaded(GreenhouseCollection));

                    topologyStore.reloadTopology();
                },
                error: () => {
                    handleError(
                        state,
                        translate.instant('ERROR.UPDATING_GREENHOUSE', { greenhouse: name }),
                        GreenhouseCollection,
                    );
                },
            });
        };

        const deleteGreenhouse = (siteId: string, greenhouse: Greenhouse) => {
            if (!greenhouse.id) {
                handleError(state, translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'), GreenhouseCollection);
                return;
            }

            patchState(state, setLoading(GreenhouseCollection));

            greenHouseApiService.deleteGreenHouse(siteId, greenhouse.id).subscribe({
                next: () => {
                    patchState(state, setLoaded(GreenhouseCollection));

                    topologyStore.reloadTopology();
                },
                error: () => {
                    handleError(
                        state,
                        translate.instant('ERROR.DELETING_GREENHOUSE', { greenhouse: greenhouse.name }),
                        GreenhouseCollection,
                    );
                },
            });
        };

        return {
            addGreenhouse,
            updateGreenhouse,
            deleteGreenhouse,
        };
    }),
);
