import { Component, Input } from '@angular/core';

import { ValidationItem } from './validation-item.interface';

@Component({
    selector: 'gm-validation-items',
    templateUrl: 'validation-items.component.html',
})
export class ValidationItemsComponent {
    @Input()
    public validationItems: ValidationItem[] = [];

    @Input()
    public translateParams: object | undefined;
}
