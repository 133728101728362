import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, Directive, Inject, Input, HostListener, NgModule } from '@angular/core';
import { WINDOW } from 'ngx-window-token';
import * as i1 from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { ReplaySubject } from 'rxjs';

/**
 * PrivaScrollToState is used to store or retrieve the documents position (y) associated linked
 * to a certain url.
 */
class ScrollToState {
  constructor(url, position) {
    this.url = url;
    this.position = position;
  }
}
const DEFAULT_POS = 0;
/**
 * PrivaScrollToService is responsible for managing the layout position states for navigated url's.
 */
class ScrollToService {
  constructor(router) {
    this.router = router;
    this.scrollStateList = [];
    this.position$ = new ReplaySubject();
  }
  /**
   * Retrieves the scroll position of a specified url.
   */
  scrollPos(fromScrollPosY = DEFAULT_POS) {
    const fromUrl = this.router.url;
    this.router.events.subscribe(val => {
      // see also
      if (val instanceof NavigationEnd) {
        const toUrl = val.urlAfterRedirects;
        const scrollFromState = this.findScrollState(fromUrl);
        scrollFromState.position = fromScrollPosY;
        const scrollToState = this.findScrollState(toUrl);
        this.position$.next(scrollToState.position);
      }
    });
    return this.position$.asObservable();
  }
  findScrollState(url) {
    let scrollToState = this.scrollStateList.find(state => state.url === url);
    if (!scrollToState) {
      scrollToState = new ScrollToState(url, DEFAULT_POS);
      this.scrollStateList.push(scrollToState);
    }
    return scrollToState;
  }
  static {
    this.ɵfac = function ScrollToService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ScrollToService)(i0.ɵɵinject(i1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ScrollToService,
      factory: ScrollToService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScrollToService, [{
    type: Injectable
  }], () => [{
    type: i1.Router
  }], null);
})();

/**
 * Directive that can be used to scroll to some layout position on click.
 * It tells the @type {PrivaScrollToService} to get the position.
 */
class PrivaScrollToDirective {
  constructor(scrollToService, window) {
    this.scrollToService = scrollToService;
    this.window = window;
  }
  onClick() {
    this.scrollTo();
  }
  scrollTo() {
    const storeScrollPos = this.scrollToPos || this.window.scrollY;
    this.scrollToService.scrollPos(+storeScrollPos).subscribe(toScrollPos => {
      this.window.scrollTo(0, toScrollPos);
    });
  }
  static {
    this.ɵfac = function PrivaScrollToDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaScrollToDirective)(i0.ɵɵdirectiveInject(ScrollToService), i0.ɵɵdirectiveInject(WINDOW));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaScrollToDirective,
      selectors: [["", "priva-scrollto", ""]],
      hostBindings: function PrivaScrollToDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function PrivaScrollToDirective_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        scrollToPos: [0, "priva-scrollto", "scrollToPos"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaScrollToDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-scrollto]'
    }]
  }], () => [{
    type: ScrollToService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WINDOW]
    }]
  }], {
    scrollToPos: [{
      type: Input,
      args: ['priva-scrollto']
    }],
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class PrivaScrollToModule {
  // forRoot is used to define services that must be singleton even for Lazy loaded modules
  static forRoot() {
    return {
      ngModule: PrivaScrollToModule,
      providers: [ScrollToService]
    };
  }
  static {
    this.ɵfac = function PrivaScrollToModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaScrollToModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaScrollToModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaScrollToModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [PrivaScrollToDirective],
      declarations: [PrivaScrollToDirective],
      providers: [
        /* Non singleton services */
      ]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaScrollToDirective, PrivaScrollToModule };
