import * as i1 from '@angular/cdk/overlay';
import { OverlayConfig, OverlayModule } from '@angular/cdk/overlay';
import * as i2 from '@angular/cdk/portal';
import { CdkPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Input, ViewChild, EventEmitter, Component, Output, NgModule } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
const _c0 = ["*"];
function PrivaDialogOverlayComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0);
  }
}
class PrivaBaseOverlay {
  constructor() {
    this.showing = false;
  }
  hide() {
    if (this.canHide()) {
      this.overlayRef.detach();
      this.showing = false;
    }
  }
  canHide() {
    return true;
  }
  getOverlayConfig() {
    return new OverlayConfig({
      hasBackdrop: true
    });
  }
  static {
    this.ɵfac = function PrivaBaseOverlay_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaBaseOverlay)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaBaseOverlay,
      viewQuery: function PrivaBaseOverlay_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(CdkPortal, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentTemplate = _t.first);
        }
      },
      inputs: {
        reference: "reference",
        overlayRef: "overlayRef"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaBaseOverlay, [{
    type: Directive
  }], () => [], {
    reference: [{
      type: Input
    }],
    overlayRef: [{
      type: Input
    }],
    contentTemplate: [{
      type: ViewChild,
      args: [CdkPortal, {
        static: true
      }]
    }]
  });
})();
class PrivaDialogOverlayComponent extends PrivaBaseOverlay {
  constructor(overlay) {
    super();
    this.overlay = overlay;
    // eslint-disable-next-line @angular-eslint/no-output-native
    this.close = new EventEmitter();
    this.backdropClick = new EventEmitter();
    this.unsubscribe$ = new Subject();
  }
  ngOnInit() {
    /**
     * Create the overlay and attach the content template.
     * This will render the template within the cdk-overlay-container
     */
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    this.overlayRef.backdropClick().pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      // prevent closing the dialog when the backdropClick is being observed
      if (this.backdropClick.observed) {
        this.backdropClick.emit('backdropClick');
      } else {
        this.closeDialog('backdropClick');
      }
    });
    this.overlayRef.keydownEvents().pipe(takeUntil(this.unsubscribe$)).subscribe(event => {
      if (event.key === 'Escape') {
        // prevent closing the dialog on escape when the backdropClick is being observed
        if (this.backdropClick.observed) {
          this.backdropClick.emit('backdropClick');
        } else {
          this.closeDialog('escape');
        }
      }
    });
  }
  closeDialog(eventType) {
    this.close.emit(eventType);
  }
  closeOverlay() {
    this.overlayRef.dispose();
  }
  ngOnDestroy() {
    this.closeOverlay();
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  getOverlayConfig() {
    const positionStrategy = this.overlay.position().global();
    switch (this.alignment) {
      case 'left':
        positionStrategy.centerVertically().left();
        break;
      case 'right':
        positionStrategy.centerVertically().right();
        break;
      case 'top':
        positionStrategy.centerHorizontally().top();
        break;
      case 'bottom':
        positionStrategy.centerHorizontally().bottom();
        break;
      case 'center':
        positionStrategy.centerHorizontally().centerVertically();
        break;
      default:
        /* eslint-disable-next-line no-console*/
        console.error(`Provided ${this.alignment}, alignment should be one of 'top', 'right', 'bottom', 'left', 'center'`);
    }
    const scrollStrategy = this.overlay.scrollStrategies.block();
    return new OverlayConfig({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: true,
      backdropClass: ['cdk-overlay-dark-backdrop', 'priva-overlay-dialog__backdrop'],
      panelClass: this.fullscreen ? 'priva-overlay-dialog__pane--fullscreen' : ''
    });
  }
  static {
    this.ɵfac = function PrivaDialogOverlayComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDialogOverlayComponent)(i0.ɵɵdirectiveInject(i1.Overlay));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaDialogOverlayComponent,
      selectors: [["priva-dialog-overlay"]],
      inputs: {
        alignment: "alignment",
        fullscreen: "fullscreen"
      },
      outputs: {
        close: "close",
        backdropClick: "backdropClick"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      consts: [["cdk-portal", ""]],
      template: function PrivaDialogOverlayComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, PrivaDialogOverlayComponent_ng_template_0_Template, 1, 0, "ng-template", 0);
        }
      },
      dependencies: [i2.TemplatePortalDirective],
      styles: [".cdk-overlay-container{z-index:1050}  .priva-overlay-dialog__pane--fullscreen{width:100%;height:100%}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDialogOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'priva-dialog-overlay',
      template: '<ng-template cdk-portal><ng-content /></ng-template>',
      styles: ["::ng-deep .cdk-overlay-container{z-index:1050}::ng-deep .priva-overlay-dialog__pane--fullscreen{width:100%;height:100%}\n"]
    }]
  }], () => [{
    type: i1.Overlay
  }], {
    alignment: [{
      type: Input
    }],
    fullscreen: [{
      type: Input
    }],
    close: [{
      type: Output
    }],
    backdropClick: [{
      type: Output
    }]
  });
})();
class PrivaOverlayModule {
  static {
    this.ɵfac = function PrivaOverlayModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaOverlayModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaOverlayModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, OverlayModule, PortalModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaOverlayModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaDialogOverlayComponent],
      imports: [CommonModule, OverlayModule, PortalModule],
      exports: [PrivaDialogOverlayComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaDialogOverlayComponent, PrivaOverlayModule };
