import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i1 from 'ngx-device-detector';
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@angular/router';
import { ReplaySubject, filter } from 'rxjs';
class UniqueIdentifierUtilities {
  static createUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  }
}
class DeviceInfoService {
  constructor(deviceService) {
    this.deviceService = deviceService;
  }
  get browserType() {
    return this.deviceService.browser.toLowerCase();
  }
  static {
    this.ɵfac = function DeviceInfoService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DeviceInfoService)(i0.ɵɵinject(i1.DeviceDetectorService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DeviceInfoService,
      factory: DeviceInfoService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DeviceInfoService, [{
    type: Injectable
  }], () => [{
    type: i1.DeviceDetectorService
  }], null);
})();
class PrivaBrowserCheckGuard {
  constructor(router, deviceDetectorService) {
    this.router = router;
    this.deviceDetectorService = deviceDetectorService;
    this.browserNotSupportedRoute = '/error/browser-not-supported';
  }
  canActivate() {
    return this.checkBrowser();
  }
  canActivateChild() {
    return this.checkBrowser();
  }
  checkBrowser() {
    const supported = this.isBrowserSupported();
    if (!supported) {
      this.router.navigate([this.browserNotSupportedRoute]);
    }
    return supported;
  }
  isBrowserSupported() {
    const browserType = this.deviceDetectorService.browser;
    switch (browserType) {
      case BROWSERS.IE:
        return false;
      case BROWSERS.MS_EDGE:
      default:
        return true;
    }
  }
  static {
    this.ɵfac = function PrivaBrowserCheckGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaBrowserCheckGuard)(i0.ɵɵinject(i1$1.Router), i0.ɵɵinject(i1.DeviceDetectorService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaBrowserCheckGuard,
      factory: PrivaBrowserCheckGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaBrowserCheckGuard, [{
    type: Injectable
  }], () => [{
    type: i1$1.Router
  }, {
    type: i1.DeviceDetectorService
  }], null);
})();
class PrivaEventHubService {
  constructor() {
    this.event$ = new ReplaySubject();
  }
  /**
   * ReplaySubject Event, listener
   * @param topic
   */
  event(topic) {
    return this.event$.asObservable().pipe(filter(event => event instanceof topic));
  }
  /**
   * Publish replay event
   * @param event
   */
  publish(event) {
    this.event$.next(event);
  }
  static {
    this.ɵfac = function PrivaEventHubService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaEventHubService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaEventHubService,
      factory: PrivaEventHubService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaEventHubService, [{
    type: Injectable
  }], null, null);
})();
class PrivaUtilitiesModule {
  static {
    this.ɵfac = function PrivaUtilitiesModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaUtilitiesModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaUtilitiesModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [DeviceDetectorService, DeviceInfoService, PrivaEventHubService, PrivaBrowserCheckGuard],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaUtilitiesModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [],
      providers: [DeviceDetectorService, DeviceInfoService, PrivaEventHubService, PrivaBrowserCheckGuard]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DeviceInfoService, PrivaBrowserCheckGuard, PrivaEventHubService, PrivaUtilitiesModule, UniqueIdentifierUtilities };
