import { setLoaded, setLoading, withCallState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of, pipe, switchMap, tap } from 'rxjs';

import { Row, UpdateRowDto } from 'app/core/models';
import { RowApiService } from 'app/core/services/row-api/row-api.service';
import { TopologyStore } from 'app/core/stores';
import { handleError, handleSuccess } from 'app/core/utils/store-helper-utils';

const RowCollection = 'rows';
export type RowStoreType = InstanceType<typeof RowStore>;

export const RowStore = signalStore(
    withCallState({ collection: RowCollection }),
    withDevtools(RowCollection),
    withMethods((state) => {
        const topologyStore = inject(TopologyStore);
        const translate = inject(TranslateService);
        const rowApiService = inject(RowApiService);

        const addRows = (baseRows: Row[]) => {
            const metaProperties = {
                siteId: topologyStore.selectedSiteId(),
                spaceId: topologyStore.selectedCompartmentId(),
            };
            patchState(state, setLoading(RowCollection));

            const completeRows: Row[] = baseRows.map((row: Row) => {
                return {
                    ...row,
                    ...metaProperties,
                };
            });

            rowApiService.createRows(metaProperties.siteId, completeRows).subscribe({
                next: (newRows) => {
                    handleSuccess(state, newRows, (row) => row.id!, RowCollection);
                    topologyStore.reloadTopology();
                },
                error: (_error) => {
                    handleError(state, translate.instant('ERROR.ADDING_ROWS'), RowCollection);
                },
            });
        };

        const updateRow = (rowId: string, baseRow: UpdateRowDto) => {
            const metaProperties = {
                siteId: topologyStore.selectedSiteId(),
            };
            patchState(state, setLoading(RowCollection));

            rowApiService.updateRow(metaProperties.siteId, rowId, baseRow).subscribe({
                next: (updatedRow) => {
                    handleSuccess(state, [updatedRow], (row) => row.id!, RowCollection);
                    topologyStore.reloadTopology();
                },
                error: () => {
                    handleError(state, translate.instant('ERROR.UPDATING_ROW'), RowCollection);
                },
            });
        };

        const deleteRow = rxMethod<{ siteId: string; row: Row }>(
            pipe(
                tap(({ row }) => {
                    if (!row.id) {
                        handleError(state, translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'), RowCollection);
                        return;
                    }
                    patchState(state, setLoading(RowCollection));
                }),
                switchMap(({ siteId, row }) =>
                    rowApiService.deleteRow(siteId, row.id!).pipe(
                        tap({
                            next: () => {
                                patchState(state, setLoaded(RowCollection));
                                topologyStore.reloadTopology();
                            },
                            error: () => {
                                handleError(
                                    state,
                                    translate.instant('ERROR.DELETING_ROW', { row: row.name }),
                                    RowCollection,
                                );
                            },
                        }),
                        catchError(() => of(undefined)),
                    ),
                ),
            ),
        );

        return {
            addRows,
            updateRow,
            deleteRow,
        };
    }),
);
