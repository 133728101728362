import { Component, computed, DestroyRef, inject, input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Action, Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';

import { AppShellAreaService, AppShellAreaType } from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';

import { TopologyStore } from 'app/core/stores';
import { AppState } from 'app/state';

import { AppError } from './app.model';
import { ErrorHandlerService } from './core/services/error-handling';

@Component({
    selector: 'gm-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    protected topologyStore = inject(TopologyStore);
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    public error$: Observable<AppError | undefined>;

    protected siteId = input<string>();
    protected combination = computed(() => ({ siteId: this.siteId() }));

    constructor(
        private store: Store<{ app: AppState }>,
        private notificationService: PrivaNotificationsService,
        private appShellAreaService: AppShellAreaService,
        private errorHandler: ErrorHandlerService,
        localization: PrivaLocalizationService,
        private destroyRef: DestroyRef,
    ) {
        this.error$ = this.store.select((s) => s.app.error);
        localization.language.pipe(first()).subscribe();

        this.topologyStore.loadTopology(this.combination);
    }

    public ngOnInit() {
        /* TODO: refactor these manual subscribes (use async pipe) */

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));

        // Initialize ErrorHandler
        this.errorHandler.initialize();
    }

    public dispatchAction(action: Action) {
        this.store.dispatch(action);
    }
}
