import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
const _c0 = ["priva-button", ""];
const _c1 = ["*"];
const _c2 = () => ["left", "only"];
const _c3 = (a0, a1) => [a0, a1];
function PrivaButtonComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("btn-icon btn-icon--size-", ctx_r0.iconSize, "");
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c3, ctx_r0.iconClass, ctx_r0.PrivaButtonIconState[ctx_r0.iconState == null ? null : ctx_r0.iconState.toLowerCase()]));
  }
}
function PrivaButtonComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMapInterpolate1("btn-icon btn-icon--size-", ctx_r0.iconSize, "");
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(4, _c3, ctx_r0.iconClass, ctx_r0.PrivaButtonIconState[ctx_r0.iconState == null ? null : ctx_r0.iconState.toLowerCase()]));
  }
}
var PrivaButtonThemeClass;
(function (PrivaButtonThemeClass) {
  PrivaButtonThemeClass["onwhite"] = "btn-theme-on-white";
  PrivaButtonThemeClass["onlightgray"] = "btn-theme-on-light-gray";
  PrivaButtonThemeClass["onlightblue"] = "btn-theme-on-light-blue";
  PrivaButtonThemeClass["ondarkblue"] = "btn-theme-on-dark-blue";
})(PrivaButtonThemeClass || (PrivaButtonThemeClass = {}));
var PrivaButtonImportanceClass;
(function (PrivaButtonImportanceClass) {
  PrivaButtonImportanceClass["none"] = "";
  PrivaButtonImportanceClass["primary"] = "btn-primary";
  PrivaButtonImportanceClass["secondary"] = "btn-secondary";
  PrivaButtonImportanceClass["tertiary"] = "btn-tertiary";
  PrivaButtonImportanceClass["quaternary"] = "btn-quaternary";
})(PrivaButtonImportanceClass || (PrivaButtonImportanceClass = {}));
var PrivaButtonDensityClass;
(function (PrivaButtonDensityClass) {
  PrivaButtonDensityClass["sm"] = "btn-density-sm";
  PrivaButtonDensityClass["md"] = "btn-density-md";
})(PrivaButtonDensityClass || (PrivaButtonDensityClass = {}));
var PrivaButtonSizeClass;
(function (PrivaButtonSizeClass) {
  PrivaButtonSizeClass["sm"] = "btn-small";
  PrivaButtonSizeClass["md"] = "";
})(PrivaButtonSizeClass || (PrivaButtonSizeClass = {}));
var PrivaButtonIconStateClass;
(function (PrivaButtonIconStateClass) {
  PrivaButtonIconStateClass["none"] = "";
  PrivaButtonIconStateClass["only"] = "btn-icon-only";
  PrivaButtonIconStateClass["left"] = "btn-icon-left";
  PrivaButtonIconStateClass["right"] = "btn-icon-right";
})(PrivaButtonIconStateClass || (PrivaButtonIconStateClass = {}));
var PrivaButtonStatusClass;
(function (PrivaButtonStatusClass) {
  PrivaButtonStatusClass["none"] = "";
  PrivaButtonStatusClass["danger"] = "btn-status-danger";
})(PrivaButtonStatusClass || (PrivaButtonStatusClass = {}));
var PrivaButtonSplitClass;
(function (PrivaButtonSplitClass) {
  PrivaButtonSplitClass["none"] = "";
  PrivaButtonSplitClass["start"] = "btn-split-start";
  PrivaButtonSplitClass["end"] = "btn-split-end";
  PrivaButtonSplitClass["both"] = "btn-split-both";
})(PrivaButtonSplitClass || (PrivaButtonSplitClass = {}));
/** @deprecated use the `start` instead of `left` and `end` instead of `right` */
var PrivaButtonSplitClassOld;
(function (PrivaButtonSplitClassOld) {
  PrivaButtonSplitClassOld["left"] = "btn-split-start";
  PrivaButtonSplitClassOld["right"] = "btn-split-end";
})(PrivaButtonSplitClassOld || (PrivaButtonSplitClassOld = {}));
class PrivaButtonComponent {
  get hostClasses() {
    return ['btn', PrivaButtonThemeClass[this.theme?.toLowerCase()], PrivaButtonSizeClass[this.size?.toLowerCase()], PrivaButtonStatusClass[this.status?.toLowerCase()], PrivaButtonSplitClassOld[this.split?.toLowerCase()] || PrivaButtonSplitClass[this.split?.toLowerCase()], this.buttonDensityClass, this.iconState?.toLowerCase() === 'only' ? 'btn-icon-only' : '', this.isMenu ? 'btn-menu' : PrivaButtonImportanceClass[this.importance?.toLowerCase()], this.hasHeaderTheme ? 'btn-theme-on-header' : '', this.class || '', this.classes || ''].join(' ');
  }
  get disabledState() {
    return this.hasButtonHostTag && this.isDisabled ? '' : null;
  }
  get disabledStateClass() {
    return this.isDisabled;
  }
  get activeState() {
    return this.isActive;
  }
  get loaderState() {
    return this.hasLoader;
  }
  constructor(el) {
    this.el = el;
    this.theme = 'onwhite';
    this.size = 'md';
    this.density = 'md';
    this.importance = 'secondary';
    this.status = 'none';
    this.iconState = 'none';
    this.split = 'none';
    this.iconSize = 'auto';
    this.iconClass = '';
    this.isDisabled = false;
    this.isActive = false;
    this.isMenu = false;
    this.hasLoader = false;
    this.hasHeaderTheme = false;
    this.hasButtonHostTag = false;
    this.PrivaButtonIconState = PrivaButtonIconStateClass;
  }
  ngOnInit() {
    this.hasButtonHostTag = this.el.nativeElement.tagName === 'BUTTON';
    this.type = this.el.nativeElement.type || (this.hasButtonHostTag ? 'button' : null);
  }
  get buttonDensityClass() {
    return (['primary', 'secondary'].includes(this.importance?.toLowerCase()) || this.isMenu) && this.iconState?.toLowerCase() !== 'only' ? PrivaButtonDensityClass[this.density?.toLowerCase()] : '';
  }
  static {
    this.ɵfac = function PrivaButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaButtonComponent,
      selectors: [["button", "priva-button", ""], ["", "priva-button", ""]],
      hostVars: 10,
      hostBindings: function PrivaButtonComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("type", ctx.type)("disabled", ctx.disabledState);
          i0.ɵɵclassMap(ctx.hostClasses);
          i0.ɵɵclassProp("is-disabled", ctx.disabledStateClass)("is-active", ctx.activeState)("has-loader", ctx.loaderState);
        }
      },
      inputs: {
        class: "class",
        classes: "classes",
        theme: "theme",
        size: "size",
        density: "density",
        importance: "importance",
        status: "status",
        iconState: "iconState",
        split: "split",
        iconSize: "iconSize",
        iconClass: "iconClass",
        isDisabled: "isDisabled",
        isActive: "isActive",
        isMenu: "isMenu",
        hasLoader: "hasLoader",
        hasHeaderTheme: "hasHeaderTheme"
      },
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 3,
      vars: 3,
      consts: [[3, "class", "ngClass"], [3, "ngClass"]],
      template: function PrivaButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, PrivaButtonComponent_Conditional_0_Template, 1, 7, "span", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵtemplate(2, PrivaButtonComponent_Conditional_2_Template, 1, 7, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵconditional(i0.ɵɵpureFunction0(2, _c2).includes(ctx.iconState == null ? null : ctx.iconState.toLowerCase()) ? 0 : -1);
          i0.ɵɵadvance(2);
          i0.ɵɵconditional((ctx.iconState == null ? null : ctx.iconState.toLowerCase()) === "right" ? 2 : -1);
        }
      },
      dependencies: [i1.NgClass],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaButtonComponent, [{
    type: Component,
    args: [{
      selector: 'button[priva-button], [priva-button]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[attr.type]': 'type'
      },
      template: "@if (['left', 'only'].includes(iconState?.toLowerCase())) {\n    <span\n        class=\"btn-icon btn-icon--size-{{ this.iconSize }}\"\n        [ngClass]=\"[iconClass, PrivaButtonIconState[iconState?.toLowerCase()]]\"\n    ></span>\n}\n<ng-content />\n@if (iconState?.toLowerCase() === 'right') {\n    <span\n        class=\"btn-icon btn-icon--size-{{ this.iconSize }}\"\n        [ngClass]=\"[iconClass, PrivaButtonIconState[iconState?.toLowerCase()]]\"\n    ></span>\n}\n"
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    class: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    density: [{
      type: Input
    }],
    importance: [{
      type: Input
    }],
    status: [{
      type: Input
    }],
    iconState: [{
      type: Input
    }],
    split: [{
      type: Input
    }],
    iconSize: [{
      type: Input
    }],
    iconClass: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    isActive: [{
      type: Input
    }],
    isMenu: [{
      type: Input
    }],
    hasLoader: [{
      type: Input
    }],
    hasHeaderTheme: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }],
    disabledState: [{
      type: HostBinding,
      args: ['attr.disabled']
    }],
    disabledStateClass: [{
      type: HostBinding,
      args: ['class.is-disabled']
    }],
    activeState: [{
      type: HostBinding,
      args: ['class.is-active']
    }],
    loaderState: [{
      type: HostBinding,
      args: ['class.has-loader']
    }]
  });
})();
class PrivaButtonModule {
  static {
    this.ɵfac = function PrivaButtonModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaButtonComponent],
      imports: [CommonModule],
      exports: [PrivaButtonComponent]
    }]
  }], null, null);
})();
var PrivaButtonImportance;
(function (PrivaButtonImportance) {
  PrivaButtonImportance["None"] = "none";
  PrivaButtonImportance["Primary"] = "primary";
  PrivaButtonImportance["Secondary"] = "secondary";
  PrivaButtonImportance["Tertiary"] = "tertiary";
  PrivaButtonImportance["Quaternary"] = "quaternary";
})(PrivaButtonImportance || (PrivaButtonImportance = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaButtonComponent, PrivaButtonImportance, PrivaButtonModule };
