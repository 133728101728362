import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
import * as i1 from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PrivaLocalizationModule } from '@priva/localization';
const _c0 = ["priva-select", ""];
const _c1 = ["*"];
function PrivaSelectComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 0);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "translate");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 1, ctx_r0.placeholder || "GLOBAL.SELECT.PLACEHOLDER_OPTION"), " ");
  }
}
function PrivaSelectComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    i0.ɵɵattribute("value", option_r2.value || null)("disabled", option_r2.disabled || null)("selected", option_r2.selected || null);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", option_r2.label, " ");
  }
}
var PrivaSelectThemeClass;
(function (PrivaSelectThemeClass) {
  PrivaSelectThemeClass["onwhite"] = "form-control--theme-on-white";
  PrivaSelectThemeClass["onlightgray"] = "form-control--theme-on-light-gray";
  PrivaSelectThemeClass["onlightblue"] = "form-control--theme-on-light-blue";
  PrivaSelectThemeClass["ondarkblue"] = "form-control--theme-on-dark-blue";
})(PrivaSelectThemeClass || (PrivaSelectThemeClass = {}));
class PrivaSelectComponent {
  constructor() {
    this.size = 'md';
    this.theme = 'onwhite';
    this.defaultPlaceholder = false;
    this.hideToggleIcon = false;
  }
  get hostClasses() {
    return ['form-control', PrivaSelectThemeClass[this.theme?.toLowerCase()], 'form-control--size-' + this.size, this.hideToggleIcon ? 'hide-toggle-icon' : '', this.class || ''].join(' ');
  }
  static {
    this.ɵfac = function PrivaSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaSelectComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaSelectComponent,
      selectors: [["", "priva-select", ""]],
      hostVars: 2,
      hostBindings: function PrivaSelectComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClasses);
        }
      },
      inputs: {
        class: "class",
        size: "size",
        theme: "theme",
        options: "options",
        placeholder: "placeholder",
        defaultPlaceholder: "defaultPlaceholder",
        hideToggleIcon: "hideToggleIcon"
      },
      attrs: _c0,
      ngContentSelectors: _c1,
      decls: 4,
      vars: 1,
      consts: [["value", "", "disabled", "", "selected", "", "hidden", ""]],
      template: function PrivaSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, PrivaSelectComponent_Conditional_0_Template, 3, 3, "option", 0);
          i0.ɵɵrepeaterCreate(1, PrivaSelectComponent_For_2_Template, 2, 4, "option", null, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵprojection(3);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.placeholder || ctx.defaultPlaceholder ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.options);
        }
      },
      dependencies: [i1.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:inline-block}[size].form-control--size-md[_nghost-%COMP%]{height:36px}[size].form-control--size-sm[_nghost-%COMP%]{height:24px}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSelectComponent, [{
    type: Component,
    args: [{
      selector: '[priva-select]',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "@if (placeholder || defaultPlaceholder) {\n    <option value=\"\" disabled selected hidden>\n        {{ placeholder || 'GLOBAL.SELECT.PLACEHOLDER_OPTION' | translate }}\n    </option>\n}\n@for (option of options; track option) {\n    <option\n        [attr.value]=\"option.value || null\"\n        [attr.disabled]=\"option.disabled || null\"\n        [attr.selected]=\"option.selected || null\"\n    >\n        {{ option.label }}\n    </option>\n}\n<ng-content />\n",
      styles: [":host{display:inline-block}:host[size].form-control--size-md{height:36px}:host[size].form-control--size-sm{height:24px}\n"]
    }]
  }], null, {
    class: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    defaultPlaceholder: [{
      type: Input
    }],
    hideToggleIcon: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class PrivaSelectModule {
  static {
    this.ɵfac = function PrivaSelectModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaSelectModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaSelectModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaSelectModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaSelectComponent],
      imports: [CommonModule, PrivaLocalizationModule],
      exports: [PrivaSelectComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaSelectComponent, PrivaSelectModule };
