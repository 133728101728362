import { setLoading, withCallState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, type, withHooks, withMethods, WritableStateSource } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { filter, Observable, pipe, switchMap, tap } from 'rxjs';

import { Site, UpdateSiteDto } from 'app/core/models/site.model';
import { ErrorHandlerService } from 'app/core/services/error-handling';
import { SitesApiService } from 'app/core/services/sites-api/sites-api.service';
import { AuthStore } from 'app/core/stores';
import { handleError, handleSuccess, handleUpdateSuccess } from 'app/core/utils/store-helper-utils';

const SitesCollection = 'sites';
const EditSiteCollection = 'editSite';
export type SitesStore = InstanceType<typeof SitesStore>;

export const SitesStore = signalStore(
    { providedIn: 'root' },
    withCallState({ collection: SitesCollection }),
    withCallState({ collection: EditSiteCollection }),
    withEntities({ entity: type<Site>(), collection: SitesCollection }),
    withDevtools('SitesStore'),
    withMethods((store) => {
        const authStore = inject(AuthStore);
        const sitesApiService = inject(SitesApiService);
        const translate = inject(TranslateService);
        const errorHandlerService = inject(ErrorHandlerService);

        const loadSites = rxMethod<void>(
            pipe(
                tap(() => patchState(store, setLoading(SitesCollection))),
                switchMap(() =>
                    _loadSites(store, sitesApiService, authStore.tenantId()!, translate, errorHandlerService),
                ),
            ),
        );

        const loadSitesAfterTenantIdAvailable = rxMethod<boolean>(
            pipe(
                filter((tenantIdAvailable) => tenantIdAvailable && store.sitesCallState() === 'init'),
                tap(() => loadSites()),
            ),
        );

        const updateSiteDetails = rxMethod<
            [siteUpdate: UpdateSiteDto, selectedSiteId: string, selectedSiteName: string]
        >(
            pipe(
                switchMap(([params, siteId, siteName]) =>
                    _updateSiteDetails(
                        store,
                        sitesApiService,
                        {
                            tenantId: authStore.tenantId()!,
                            siteId,
                            siteName,
                            ...params,
                        },
                        translate,
                        errorHandlerService,
                    ),
                ),
            ),
        );

        return { loadSites, loadSitesAfterTenantIdAvailable, updateSiteDetails };
    }),
    withHooks((store) => {
        const authStore = inject(AuthStore);

        return {
            onInit() {
                store.loadSitesAfterTenantIdAvailable(authStore.isTenantIdAvailable);
            },
        };
    }),
);

function _loadSites<State extends object>(
    state: WritableStateSource<State>,
    sitesApiService: SitesApiService,
    tenantId: string,
    translate: TranslateService,
    errorHandlerService: ErrorHandlerService,
): Observable<Site[]> {
    return sitesApiService.getSites(tenantId).pipe(
        tapResponse({
            next: (sites) => handleSuccess(state, sites, (site) => site.id!, SitesCollection),
            error: (_error) => {
                const translatedErrorMessage = translate.instant('ERROR.GLOBAL_ERROR_MESSAGE');
                handleError(state, translatedErrorMessage, SitesCollection);
                errorHandlerService.handleScriptMessages(translatedErrorMessage);
            },
        }),
    );
}

function _updateSiteDetails<State extends object>(
    state: WritableStateSource<State>,
    sitesApiService: SitesApiService,
    params: { tenantId: string; siteId: string; siteName: string } & UpdateSiteDto,
    translate: TranslateService,
    errorHandlerService: ErrorHandlerService,
): Observable<Site> {
    if (!params.siteId || !params.location || !params.location?.latitude || !params.location?.longitude) {
        errorHandlerService.handleScriptMessages(translate.instant('ERROR.MISSING_REQUIRED_PARAMETERS_SITE'));
    }

    return sitesApiService
        .updateSiteDetail(params.tenantId, params.siteId, {
            location: {
                longitude: params.location!.longitude,
                latitude: params.location!.latitude,
            },
        })
        .pipe(
            tapResponse({
                next: (updatedSite) =>
                    handleUpdateSuccess(state, updatedSite, (site) => site.id!, SitesCollection),
                error: (_error) => {
                    handleError(
                        state,
                        translate.instant('ERROR.UPDATING_SITE', { name: params.siteName }),
                        SitesCollection,
                    );
                },
            }),
        );
}
