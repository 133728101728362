<div
    id="root"
    [class.has-notification-bar]="hasNotificationBar"
    [class.has-primary-nav]="hasPrimaryNav"
    [appshell-deactivate-area]="[AppShellAreaType.SidebarLocalLeft]"
    [priva-toggle-class]="{ contentActions: 'has-content-overlay' }"
    [priva-toggle-deactivate]="['contentActions', 'showPrimaryNavItems']"
>
    <!-- AREA | COMPONENT: Notification messages -->
    <priva-notifications />

    <!-- App Shell main component -->
    <router-outlet />

    <!-- Global error handling -->
    @if (error$ | async; as appError) {
        <gm-status-error-page
            class="app__error-page"
            (actionClicked)="dispatchAction(appError.action)"
            [actionHtmlClass]="appError.actionHtmlClass"
            [actionHtmlId]="appError.actionHtmlId"
            [actionTitle]="appError.actionTitle"
            [info]="appError.subTitle"
            [title]="appError.title"
        />
    }
</div>
