import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Action } from '@priva/components/actions';
import { PrivaTitleSize } from '@priva/components/title';
import { PrivaTheme } from '@priva/styles/foundation';

@Component({
    selector: 'gm-status-error-page',
    templateUrl: 'status-error-page.component.html',
})
export class StatusErrorPageComponent {
    @Input() public title: string;
    @Input() public info: string;
    @Input() public extraInfo: string;
    @Input() public actions: Action[];

    @Input() public actionTitle: string;
    @Input() public actionHtmlId: string;
    @Input() public actionHtmlClass: string;
    @Input() public actionIcon: string;
    @Output() public actionClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    public isExpanded = false;
    public PrivaTitleSize = PrivaTitleSize;
    public PrivaTheme = PrivaTheme;

    public get hasExtraInfo(): boolean {
        return !!this.extraInfo;
    }

    public actionButtonClick() {
        this.actionClicked.emit(true);
    }
}
