import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfiguration, windowWithPrivaConfig } from './app-configuration.model';

export type ApiCategory = 'control' | 'spatial' | 'equipment';

@Injectable({
    providedIn: 'root',
})
export class AppConfigurationService {
    private config!: AppConfiguration;
    private configurationLoadedSubject = new ReplaySubject<boolean>(1);

    constructor() {
        this.loadConfiguration();
    }

    public get configuration(): AppConfiguration {
        if (!this.config) {
            this.config = windowWithPrivaConfig.privaConfig;
        }
        return this.config;
    }

    public loadConfiguration(): void {
        if (!this.config) {
            this.config = windowWithPrivaConfig.privaConfig;
        }

        this.configurationLoadedSubject.next(!!this.config);
    }

    public getBaseUrl(apiCategory: ApiCategory): string {
        switch (apiCategory) {
            case 'spatial':
                return this.config.uris.masterDataSpatialServices;
            case 'control':
                return this.config.uris.masterDataControlServices;
            case 'equipment':
                return this.config.uris.masterDataEquipmentServices;
            default:
                throw new Error('API category out of range');
        }
    }

    public get configurationLoaded(): Observable<boolean> {
        return this.configurationLoadedSubject.asObservable();
    }
}
