import * as i1 from '@angular/cdk/portal';
import { ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
function PrivaDynamicComponentSlotComponent_ng_template_0_Template(rf, ctx) {}
class PrivaDynamicComponentSlotComponent {
  set dynamicComponent(dynamicComponent) {
    if (dynamicComponent && dynamicComponent.component) {
      this.inputs = dynamicComponent.inputs;
      this.portalComponent = new ComponentPortal(dynamicComponent.component);
    } else {
      this.portalComponent = undefined;
    }
  }
  setInputs(cdkPortalRef) {
    if (this.inputs) {
      const componentRef = cdkPortalRef;
      const keys = Object.keys(this.inputs);
      for (const key of keys) {
        componentRef.instance[key] = this.inputs[key];
      }
    }
  }
  static {
    this.ɵfac = function PrivaDynamicComponentSlotComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDynamicComponentSlotComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaDynamicComponentSlotComponent,
      selectors: [["priva-dynamic-component-slot"]],
      inputs: {
        dynamicComponent: "dynamicComponent"
      },
      decls: 1,
      vars: 1,
      consts: [[3, "attached", "cdkPortalOutlet"]],
      template: function PrivaDynamicComponentSlotComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, PrivaDynamicComponentSlotComponent_ng_template_0_Template, 0, 0, "ng-template", 0);
          i0.ɵɵlistener("attached", function PrivaDynamicComponentSlotComponent_Template_ng_template_attached_0_listener($event) {
            return ctx.setInputs($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵproperty("cdkPortalOutlet", ctx.portalComponent);
        }
      },
      dependencies: [i1.CdkPortalOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDynamicComponentSlotComponent, [{
    type: Component,
    args: [{
      selector: 'priva-dynamic-component-slot',
      template: ` <ng-template [cdkPortalOutlet]="portalComponent" (attached)="setInputs($event)" /> `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    dynamicComponent: [{
      type: Input
    }]
  });
})();
class PrivaDynamicModule {
  static {
    this.ɵfac = function PrivaDynamicModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDynamicModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaDynamicModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PortalModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDynamicModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PortalModule],
      declarations: [PrivaDynamicComponentSlotComponent],
      exports: [PrivaDynamicComponentSlotComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaDynamicComponentSlotComponent, PrivaDynamicModule };
