import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AppConfigurationService } from 'app/common/configuration';
import { Site, Topology, UpdateSiteDto } from 'app/core/models';
import { ApiEndPoints } from 'app/shared/constants/api-endpoints';

/**
 * Service for fetching and managing site data
 */
@Injectable({
    providedIn: 'root',
})
export class SitesApiService {
    protected readonly http = inject(HttpClient);
    protected readonly appConfigurationService = inject(AppConfigurationService);
    protected readonly translate = inject(TranslateService);
    private readonly baseUrl = this.appConfigurationService.getBaseUrl('spatial');

    public getSites(tenantId: string): Observable<Site[]> {
        return this.http.get<Site[]>(ApiEndPoints.sites.getList(this.baseUrl, tenantId));
    }

    public getSiteDetail(tenantId: string, siteId: string): Observable<Site> {
        return this.http.get<Site>(ApiEndPoints.sites.getOrUpdateById(this.baseUrl, tenantId, siteId));
    }

    public updateSiteDetail(tenantId: string, siteId: string, siteData: UpdateSiteDto): Observable<Site> {
        return this.http.patch<Site>(
            ApiEndPoints.sites.getOrUpdateById(this.baseUrl, tenantId, siteId),
            siteData,
        );
    }

    public getTopology(tenantId: string, siteId: string): Observable<Topology> {
        return this.http.get<Topology>(ApiEndPoints.sites.getTopology(this.baseUrl, tenantId, siteId));
    }
}
