import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
const _c0 = (a0, a1) => [a0, a1];
var PrivaIndicatorThemeClass;
(function (PrivaIndicatorThemeClass) {
  PrivaIndicatorThemeClass["onwhite"] = "indicator--theme-on-white";
  PrivaIndicatorThemeClass["onlightgray"] = "indicator--theme-on-light-gray";
  PrivaIndicatorThemeClass["onlightblue"] = "indicator--theme-on-light-blue";
  PrivaIndicatorThemeClass["ondarkblue"] = "indicator--theme-on-dark-blue";
})(PrivaIndicatorThemeClass || (PrivaIndicatorThemeClass = {}));
class PrivaIndicatorComponent {
  constructor() {
    this.position = 'none';
    this.theme = 'onwhite';
    this.amountMaxDisplay = 99;
    this.size = 'md';
    this.PrivaIndicatorThemeClass = PrivaIndicatorThemeClass;
  }
  get hasTopRightPosition() {
    return this.position?.toLowerCase() === 'topright';
  }
  get indicatorMdSize() {
    return this.size?.toLowerCase() === 'md';
  }
  static {
    this.ɵfac = function PrivaIndicatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaIndicatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaIndicatorComponent,
      selectors: [["priva-indicator"]],
      hostVars: 4,
      hostBindings: function PrivaIndicatorComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("is-top-right", ctx.hasTopRightPosition)("is-size-md", ctx.indicatorMdSize);
        }
      },
      inputs: {
        amount: "amount",
        position: "position",
        theme: "theme"
      },
      decls: 2,
      vars: 5,
      consts: [[1, "indicator", 3, "ngClass"]],
      template: function PrivaIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "span", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(2, _c0, ctx.PrivaIndicatorThemeClass[ctx.theme == null ? null : ctx.theme.toLowerCase()], "indicator--size-" + ctx.size));
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.amount <= ctx.amountMaxDisplay ? ctx.amount : ctx.amountMaxDisplay + "+", "\n");
        }
      },
      dependencies: [i1.NgClass],
      styles: [".is-top-right[_nghost-%COMP%]{position:absolute;top:0;transform:translateY(-50%)}.is-top-right.is-size-md[_nghost-%COMP%]{right:-10px}.indicator[_ngcontent-%COMP%]{display:inline-flex;justify-content:center;border-style:solid;font-weight:600}.indicator--size-md[_ngcontent-%COMP%]{min-width:20px;height:20px;padding:0 5px;border-width:2px;border-radius:10px;font-size:11px}.indicator--theme-on-white[_ngcontent-%COMP%], .has-theme-white[_ngcontent-%COMP%]   .indicator[_ngcontent-%COMP%]{border-color:#fff;background-color:#254a5d;color:#fff}.indicator--theme-on-light-gray[_ngcontent-%COMP%], .has-theme-light-gray[_ngcontent-%COMP%]   .indicator[_ngcontent-%COMP%]{border-color:#f5f5f6;background-color:#1d3c4c;color:#fff}.indicator--theme-on-light-blue[_ngcontent-%COMP%], .has-theme-light-blue[_ngcontent-%COMP%]   .indicator[_ngcontent-%COMP%]{border-color:#daecf5;background-color:#1d3c4c;color:#fff}.indicator--theme-on-dark-blue[_ngcontent-%COMP%], .has-theme-dark-blue[_ngcontent-%COMP%]   .indicator[_ngcontent-%COMP%]{border-color:#254a5d;background-color:#fff;color:#26272a}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'priva-indicator',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<span\n    class=\"indicator\"\n    [ngClass]=\"[PrivaIndicatorThemeClass[theme?.toLowerCase()], 'indicator--size-' + size]\"\n>\n    {{ amount <= amountMaxDisplay ? amount : amountMaxDisplay + '+' }}\n</span>\n",
      styles: [":host.is-top-right{position:absolute;top:0;transform:translateY(-50%)}:host.is-top-right.is-size-md{right:-10px}.indicator{display:inline-flex;justify-content:center;border-style:solid;font-weight:600}.indicator--size-md{min-width:20px;height:20px;padding:0 5px;border-width:2px;border-radius:10px;font-size:11px}.indicator--theme-on-white,.has-theme-white .indicator{border-color:#fff;background-color:#254a5d;color:#fff}.indicator--theme-on-light-gray,.has-theme-light-gray .indicator{border-color:#f5f5f6;background-color:#1d3c4c;color:#fff}.indicator--theme-on-light-blue,.has-theme-light-blue .indicator{border-color:#daecf5;background-color:#1d3c4c;color:#fff}.indicator--theme-on-dark-blue,.has-theme-dark-blue .indicator{border-color:#254a5d;background-color:#fff;color:#26272a}\n"]
    }]
  }], null, {
    amount: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    hasTopRightPosition: [{
      type: HostBinding,
      args: ['class.is-top-right']
    }],
    indicatorMdSize: [{
      type: HostBinding,
      args: ['class.is-size-md']
    }]
  });
})();
class PrivaIndicatorModule {
  static {
    this.ɵfac = function PrivaIndicatorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaIndicatorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaIndicatorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaIndicatorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [PrivaIndicatorComponent],
      exports: [PrivaIndicatorComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaIndicatorComponent, PrivaIndicatorModule };
