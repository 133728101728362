import { ModuleWithProviders, NgModule } from '@angular/core';

import { PrimaryNavigationService } from '@priva/appshell';

import { AppPrimaryNavigationService } from './primary-navigation.service';

@NgModule({})
export class NavigationModule {
    public static forRoot(): ModuleWithProviders<NavigationModule> {
        return {
            ngModule: NavigationModule,
            providers: [{ provide: PrimaryNavigationService, useClass: AppPrimaryNavigationService }],
        };
    }
}
