import { Component, Input } from '@angular/core';

import { ErrorDefinition } from './error-definition.interface';

@Component({
    selector: 'gm-error-definition',
    templateUrl: 'error-definition.component.html',
})
export class ErrorDefinitionComponent {
    @Input()
    public errorDefinition: ErrorDefinition | undefined;
}
