import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Input, Pipe, NgModule } from '@angular/core';
import { UniqueIdentifierUtilities } from '@priva/utilities';

/**
 * @deprecated directive has been refactored to priva-append-to as it allows the developer to append to other elements than the body tag
 * @example rename your directive from priva-append-to-body="true" to priva-append-to (defaults to body)
 **/
class PrivaAppendBodyToDirective {
  constructor(document, renderer, elementRef) {
    this.document = document;
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.isEnabled = true;
    this.parentId = 'append-to-container';
    this.appendId = UniqueIdentifierUtilities.createUUID();
    console.warn('Deprecated, please use priva-append-to instead of this legacy directive');
  }
  ngOnInit() {
    this.childElement = this.elementRef.nativeElement;
  }
  ngAfterViewInit() {
    if (this.isEnabled) {
      this.createParent();
      this.appendChild();
    }
  }
  ngOnDestroy() {
    this.removeChild();
  }
  createParent() {
    const hasParentElement = !!this.document.getElementById(this.parentId);
    if (!hasParentElement) {
      const parentElement = this.renderer.createElement('div');
      this.renderer.setAttribute(parentElement, 'id', this.parentId);
      this.renderer.appendChild(this.document.body, parentElement);
    }
    this.parentElement = this.document.getElementById(this.parentId);
  }
  appendChild() {
    this.renderer.setAttribute(this.childElement, 'id', this.appendId);
    this.renderer.appendChild(this.parentElement, this.childElement);
    this.appendedElement = this.document.getElementById(this.appendId);
  }
  removeChild() {
    if (this.parentElement && this.appendedElement) {
      this.renderer.removeChild(this.parentElement, this.appendedElement);
    }
  }
  static {
    this.ɵfac = function PrivaAppendBodyToDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAppendBodyToDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaAppendBodyToDirective,
      selectors: [["", "priva-append-to-body", ""]],
      inputs: {
        isEnabled: [0, "priva-append-to-body", "isEnabled"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAppendBodyToDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-append-to-body]'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    isEnabled: [{
      type: Input,
      args: ['priva-append-to-body']
    }]
  });
})();
class PrivaAppendToDirective {
  constructor(document, renderer, elementRef) {
    this.document = document;
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.parentId = 'append-to-container';
    this.appendId = UniqueIdentifierUtilities.createUUID();
  }
  ngOnInit() {
    this.childElement = this.elementRef.nativeElement;
  }
  ngAfterViewInit() {
    if (this.targetSelector !== '') {
      this.createParent();
      this.appendChild();
    }
  }
  ngOnDestroy() {
    this.removeChild();
  }
  createParent() {
    const targetElement = this.document.querySelector(this.targetSelector) || this.document.body;
    const targetFiltered = new RegExp(/[.#]/g);
    this.parentId = targetElement !== this.document.body ? `${this.parentId}-${this.targetSelector.replace(targetFiltered, '')}` : this.parentId;
    let parentElement = this.document.getElementById(this.parentId);
    if (!parentElement) {
      const newParentElement = this.renderer.createElement('div');
      this.renderer.setAttribute(newParentElement, 'id', this.parentId);
      this.renderer.appendChild(targetElement, newParentElement);
      parentElement = newParentElement;
    }
    this.parentElement = parentElement;
  }
  appendChild() {
    this.renderer.setAttribute(this.childElement, 'id', this.appendId);
    this.renderer.appendChild(this.parentElement, this.childElement);
    this.appendedElement = this.document.getElementById(this.appendId);
  }
  removeChild() {
    if (this.parentElement && this.appendedElement) {
      this.renderer.removeChild(this.parentElement, this.appendedElement);
    }
  }
  static {
    this.ɵfac = function PrivaAppendToDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaAppendToDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaAppendToDirective,
      selectors: [["", "priva-append-to", ""]],
      inputs: {
        targetSelector: [0, "priva-append-to", "targetSelector"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAppendToDirective, [{
    type: Directive,
    args: [{
      selector: '[priva-append-to]'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }], {
    targetSelector: [{
      type: Input,
      args: ['priva-append-to']
    }]
  });
})();
class PrivaIfLoadingDirective {
  constructor(templateRef, viewContainer) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
    this.ifLoadingDelay = 300;
    this.ifLoadingMinRenderTime = 600;
  }
  set ifLoading(condition) {
    this.ifLoadingContext = condition;
    this.updateView();
  }
  set ifLoadingThen(ref) {
    this.thenRef = ref;
    this.updateView();
  }
  set ifLoadingElse(ref) {
    this.elseRef = ref;
    this.updateView();
  }
  updateView() {
    this.ifLoadingContext ? this.showLoader(this.thenRef ? this.thenRef : this.templateRef) : this.showContent();
  }
  showLoader(ref) {
    clearTimeout(this.renderContentTimeout);
    this.loadingTimeStart = performance.now();
    this.renderLoaderTimeout = setTimeout(() => {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(ref);
      this.loaderVisibleTimeStart = performance.now();
    }, this.ifLoadingDelay);
  }
  showContent() {
    const loadingTime = this.loadingTimeStart ? performance.now() - this.loadingTimeStart : 0;
    if (loadingTime < this.ifLoadingDelay) {
      clearTimeout(this.renderLoaderTimeout);
      this.renderContent();
    } else {
      const loaderShownTime = this.loaderVisibleTimeStart ? performance.now() - this.loaderVisibleTimeStart : 0;
      const renderContentDelay = loaderShownTime < this.ifLoadingMinRenderTime ? this.ifLoadingMinRenderTime - loaderShownTime : 0;
      this.renderContentTimeout = setTimeout(() => this.renderContent(), renderContentDelay);
    }
  }
  renderContent() {
    this.viewContainer.clear();
    if (this.elseRef) this.viewContainer.createEmbeddedView(this.elseRef);
  }
  static {
    this.ɵfac = function PrivaIfLoadingDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaIfLoadingDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: PrivaIfLoadingDirective,
      selectors: [["", "ifLoading", ""]],
      inputs: {
        ifLoadingDelay: "ifLoadingDelay",
        ifLoadingMinRenderTime: "ifLoadingMinRenderTime",
        ifLoading: "ifLoading",
        ifLoadingThen: "ifLoadingThen",
        ifLoadingElse: "ifLoadingElse"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaIfLoadingDirective, [{
    type: Directive,
    args: [{
      // camelCase selector because structural directives cannot use kebab-case
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[ifLoading]'
    }]
  }], () => [{
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }], {
    ifLoadingDelay: [{
      type: Input
    }],
    ifLoadingMinRenderTime: [{
      type: Input
    }],
    ifLoading: [{
      type: Input
    }],
    ifLoadingThen: [{
      type: Input
    }],
    ifLoadingElse: [{
      type: Input
    }]
  });
})();

/**
 * Transforms a min or max value to determine the next valid value with a specific precision.
 *
 * @example
 * When value is 10 and precision is 0
 * {{ 10 | numberRangeValue: 'min': 0 }} // minimum valid value is 11
 * {{ 10 | numberRangeValue: 'max': 0 }} // maximum valid value is 9
 *
 * {{ 10 | numberRangeValue: 'min': 1 }} // minimum valid value is 10.1
 * {{ 10 | numberRangeValue: 'max': 1 }} // maximum valid value is 9.9
 */
class PrivaNumberRangeValuePipe {
  transform(value, type, precision = 0) {
    if (typeof value !== 'number') return null;
    const adjustment = Math.pow(10, -precision);
    return type === 'min' ? value + adjustment : value - adjustment;
  }
  static {
    this.ɵfac = function PrivaNumberRangeValuePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaNumberRangeValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "numberRangeValue",
      type: PrivaNumberRangeValuePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaNumberRangeValuePipe, [{
    type: Pipe,
    args: [{
      name: 'numberRangeValue'
    }]
  }], null, null);
})();
class PrivaReplacePipe {
  transform(value, regexValue, replaceValue) {
    const regex = new RegExp(regexValue, 'g');
    return value.replace(regex, replaceValue);
  }
  static {
    this.ɵfac = function PrivaReplacePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaReplacePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "replace",
      type: PrivaReplacePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaReplacePipe, [{
    type: Pipe,
    args: [{
      name: 'replace'
    }]
  }], null, null);
})();
class PrivaReversePipe {
  transform(array) {
    return [...array].reverse();
  }
  static {
    this.ɵfac = function PrivaReversePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaReversePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "reverse",
      type: PrivaReversePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaReversePipe, [{
    type: Pipe,
    args: [{
      name: 'reverse'
    }]
  }], null, null);
})();
class PrivaUtilitiesGenericModule {
  static {
    this.ɵfac = function PrivaUtilitiesGenericModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaUtilitiesGenericModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaUtilitiesGenericModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaUtilitiesGenericModule, [{
    type: NgModule,
    args: [{
      declarations: [PrivaReversePipe, PrivaIfLoadingDirective, PrivaAppendBodyToDirective, PrivaAppendToDirective, PrivaReplacePipe, PrivaNumberRangeValuePipe],
      imports: [CommonModule],
      exports: [PrivaReversePipe, PrivaIfLoadingDirective, PrivaAppendBodyToDirective, PrivaAppendToDirective, PrivaReplacePipe, PrivaNumberRangeValuePipe]
    }]
  }], null, null);
})();

/** Converts provided placeholder value to a string and returns a fallback value if the input is undefined.
 *
 * Note that we return `null` if the value and fallback are both undefined, null or empty to ensure that the
 * placeholder attribute is not set on the input element.
 *
 * @example {{ value | placeholder: 'fallback' }}
 **/
class PrivaPlaceholderPipe {
  transform(value, fallback, forceFallback) {
    return forceFallback || value === undefined || value === null || value === '' ? fallback || null : value.toString();
  }
  static {
    this.ɵfac = function PrivaPlaceholderPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaPlaceholderPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "placeholder",
      type: PrivaPlaceholderPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaPlaceholderPipe, [{
    type: Pipe,
    args: [{
      name: 'placeholder',
      standalone: true
    }]
  }], null, null);
})();
class PrivaVisibleNodesPipe {
  transform(childNodes) {
    for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i];
      // Any node that is not a COMMENT_NODE or TEXT_NODE is considered visible
      if (!node) continue;
      if (node.nodeType === Node.COMMENT_NODE) continue;
      if (node.nodeType !== Node.TEXT_NODE) return true;
      // Use regex to check if the content has any non-whitespace characters
      if (/[^\t\n\r ]/.test(node.textContent)) return true;
    }
    // If no visible nodes are found
    return false;
  }
  static {
    this.ɵfac = function PrivaVisibleNodesPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaVisibleNodesPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "hasVisibleNodes",
      type: PrivaVisibleNodesPipe,
      pure: false,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaVisibleNodesPipe, [{
    type: Pipe,
    args: [{
      name: 'hasVisibleNodes',
      standalone: true,
      pure: false
    }]
  }], null, null);
})();

/** Checks if an item satisfies the supplied predicate. */
class PrivaPredicatePipe {
  transform(item, predicate) {
    return predicate ? !!predicate(item) : false;
  }
  static {
    this.ɵfac = function PrivaPredicatePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaPredicatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "predicate",
      type: PrivaPredicatePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaPredicatePipe, [{
    type: Pipe,
    args: [{
      name: 'predicate',
      standalone: true
    }]
  }], null, null);
})();

/** Checks if at least one item of an array satisfies the supplied predicate. */
class PrivaHasSomePipe {
  transform(items, predicate) {
    return items?.some(predicate) ?? false;
  }
  static {
    this.ɵfac = function PrivaHasSomePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaHasSomePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "hasSome",
      type: PrivaHasSomePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaHasSomePipe, [{
    type: Pipe,
    args: [{
      name: 'hasSome',
      standalone: true
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaAppendBodyToDirective, PrivaAppendToDirective, PrivaHasSomePipe, PrivaIfLoadingDirective, PrivaNumberRangeValuePipe, PrivaPlaceholderPipe, PrivaPredicatePipe, PrivaReplacePipe, PrivaReversePipe, PrivaUtilitiesGenericModule, PrivaVisibleNodesPipe };
