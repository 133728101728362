import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AppConfigurationResolver } from './app-configuration.resolver';
import { AppConfigurationService } from './app-configuration.service';

@NgModule({
    imports: [CommonModule],
    exports: [],
    declarations: [],
    providers: [],
})
export class AppConfigurationModule {
    public static forRoot(): ModuleWithProviders<AppConfigurationModule> {
        return {
            ngModule: AppConfigurationModule,
            providers: [AppConfigurationService, AppConfigurationResolver],
        };
    }
}
