{
    "private": true,
    "name": "greenhouse-modeler",
    "version": "1.0.329",
    "engines": {
        "node": "^22",
        "npm": "^10"
    },
    "scripts": {
        "ng": "ng",
        "prepare": "cd .. && husky",
        "prestart": "npm run localize",
        "start": "run-p -r start:server start:app",
        "start:app": "ng serve --open --watch --proxy-config proxy.conf.json",
        "start:app:proxy-bff": "ng serve --open --ssl --watch --proxy-config proxy-bffserver.conf.json",
        "start:server": "ts-node mock-server/server",
        "start:bffserver": "cross-env dotnet run --project ../Server/BffServer/Priva.Greenhouse.Modeler.BffServer.csproj --launch-profile https",
        "start:wait": "wait-on http://localhost:4200",
        "start:wait-mock-server": "wait-on http://localhost:9005",
        "build": "npm run localize && ng build",
        "build:tst": "npm run localize && ng build --configuration test",
        "build:acc": "npm run localize && ng build --configuration acceptance",
        "build:prod": "npm run localize && ng build --configuration production",
        "build:source-maps": "npm run localize && ng build --configuration=production --source-map",
        "test": "jest --coverage --maxWorkers=50% --config=jest.config.js ./src",
        "test:watch": "jest --coverage --maxWorkers=25% --watch",
        "lint": "npm run lint:check",
        "lint:check": "run-p lint:check:*",
        "lint:check:scripts": "set NODE_OPTIONS=--max-old-space-size=8192 & eslint . --cache",
        "lint:check:styles": "stylelint **/*.{css,scss} --cache",
        "lint:check:format": "prettier --check . --ignore-unknown --log-level warn",
        "lint:fix": "run-p lint:fix:*",
        "lint:fix:scripts": "set NODE_OPTIONS=--max-old-space-size=8192 & eslint . --fix --cache",
        "lint:fix:styles": "stylelint --fix **/*.{css,scss} --cache",
        "lint:fix:format": "prettier --write . --ignore-unknown --log-level warn",
        "analyze-bundle-size": "npm run build:source-maps && source-map-explorer dist/*.js",
        "localize": "localization-cli --sourcePaths localization/source --destinationPath src/assets/translations",
        "localize:debug": "localization-cli --verbose --sourcePaths localization/source --destinationPath src/assets/translations"
    },
    "prettier": "@priva/linter/prettier-config",
    "overrides": {
        "postcss": "$postcss",
        "@stdlib/number-float64-base-normalize@0.0.7": "0.0.8"
    },
    "dependencies": {
        "@angular-architects/ngrx-toolkit": "^18.1.0",
        "@angular/animations": "~18.2.9",
        "@angular/cdk": "~18.2.10",
        "@angular/common": "~18.2.9",
        "@angular/compiler": "~18.2.9",
        "@angular/core": "~18.2.9",
        "@angular/forms": "~18.2.9",
        "@angular/platform-browser": "~18.2.9",
        "@angular/platform-browser-dynamic": "~18.2.9",
        "@angular/router": "~18.2.9",
        "@angular/service-worker": "~18.2.9",
        "@ng-select/ng-select": "^13.4.1",
        "@ngrx/effects": "~18.1.0",
        "@ngrx/operators": "^18.1.0",
        "@ngrx/router-store": "~18.1.0",
        "@ngrx/signals": "^18.1.0",
        "@ngrx/store": "~18.1.0",
        "@ngrx/store-devtools": "~18.1.0",
        "@ngx-translate/core": "^15.0.0",
        "@popperjs/core": "^2.11.8",
        "@priva/analytics": "^13.0.2",
        "@priva/appshell": "^27.3.0",
        "@priva/auth": "^14.0.1",
        "@priva/components": "^49.4.6",
        "@priva/error-pages": "^16.1.0",
        "@priva/localization": "^15.3.0",
        "@priva/styles": "^32.7.1",
        "@priva/utilities": "^13.6.1",
        "@segment/analytics-next": "^1.75.0",
        "clean": "^4.0.2",
        "date-fns": "^2.30.0",
        "dayjs": "1.11.13",
        "focus-visible": "^5.2.1",
        "ng2-date-picker": "~18.0.0",
        "ngx-segment-analytics": "^18.0.1",
        "ngx-window-token": "^7.0.0",
        "object-hash": "^3.0.0",
        "rxjs": "^7.8.1",
        "tslib": "^2.8.0",
        "zone.js": "~0.14.10"
    },
    "devDependencies": {
        "@angular-builders/jest": "^18.0.0",
        "@angular-devkit/build-angular": "^18.2.10",
        "@angular-devkit/core": "~18.2.10",
        "@angular-devkit/schematics": "~18.2.10",
        "@angular/build": "^18.2.10",
        "@angular/cli": "~18.2.10",
        "@angular/compiler-cli": "~18.2.9",
        "@angular/language-service": "~18.2.9",
        "@priva/linter": "^9.2.1",
        "@priva/localization-cli": "^5.2.1",
        "@priva/mock-server": "^1.3.1",
        "@playwright/test": "^1.48.2",
        "@types/jest": "^29.5.14",
        "@types/node": "^22.9.0",
        "@types/object-hash": "^3.0.6",
        "cross-env": "^7.0.3",
        "eslint": "^8.57.1",
        "husky": "^9.1.6",
        "jest": "^29.7.0",
        "jest-preset-angular": "^14.2.4",
        "lint-staged": "^15.2.10",
        "ng-mocks": "^14.13.1",
        "npm-run-all": "^4.1.5",
        "postcss": "^8.4.47",
        "postcss-scss": "^4.0.9",
        "prettier": "^3.3.3",
        "source-map-explorer": "^2.5.3",
        "stylelint": "^16.10.0",
        "ts-jest": "^29.2.5",
        "ts-node": "^10.9.2",
        "wait-on": "^7.2.0",
        "typescript": "~5.5.4"
    }
}
