<div class="template-error" id="error-not-found">
    <section class="status-message status-message-global">
        <header class="status-message-header">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="status-message-image"
                width="220"
                height="220"
                viewBox="0 0 180 180"
            >
                <circle cx="90" cy="90" r="90" fill="#dae3e6" />
                <ellipse cx="87.83" cy="135.65" fill="#254a5d" opacity=".15" rx="66.24" ry="11.12" />
                <path
                    fill="#fff"
                    d="M117.91 126.1c-5.29 0-11.34-3.78-16.21-10.26a1.59 1.59 0 1 1 2.54-1.91c4.66 6.2 10.52 9.69 15 8.89 4.08-.74 5.78-4.29 6.56-7a1.59 1.59 0 1 1 3 .9c-2.53 7.53-5.91 8.64-9 9.2a10.46 10.46 0 0 1-1.89.18z"
                />
                <path
                    fill="#254a5d"
                    d="M103 113.3a1.57 1.57 0 0 1 1.27.63c4.21 5.61 9.42 9 13.66 9a8.08 8.08 0 0 0 1.31-.11c4.08-.74 5.78-4.29 6.56-7a1.58 1.58 0 0 1 1.52-1.14 1.41 1.41 0 0 1 .45.07 1.56 1.56 0 0 1 1.07 2c-2.53 7.53-5.91 8.64-9 9.2a10.46 10.46 0 0 1-1.86.16c-5.29 0-11.34-3.78-16.21-10.26a1.6 1.6 0 0 1 .32-2.23 1.59 1.59 0 0 1 1-.31m0-1.26a2.84 2.84 0 0 0-2.27 4.55c5.06 6.74 11.5 10.77 17.21 10.77a11.83 11.83 0 0 0 2.08-.18c3.42-.62 7.29-2 10-10a2.78 2.78 0 0 0-.18-2.15 2.9 2.9 0 0 0-1.73-1.43 3.22 3.22 0 0 0-.81-.11 2.85 2.85 0 0 0-2.73 2c-1 3.56-2.9 5.61-5.58 6.09a6.3 6.3 0 0 1-1.08.09c-3.87 0-8.72-3.25-12.65-8.5A2.88 2.88 0 0 0 103 112z"
                />
                <path fill="#fff" d="M42.68 54.29h91.98v60.41H42.68z" />
                <path
                    fill="#84adb8"
                    d="M127.71 60.26H50a1.54 1.54 0 0 0-1.56 1.53v45.84a1.55 1.55 0 0 0 1.56 1.53h48A34.78 34.78 0 0 1 94.22 97l-3 .55a1.37 1.37 0 0 1-.28 0 1.55 1.55 0 0 1-1.39-.85l-5-10a1.44 1.44 0 0 1-.13-.4l-2-10.52A1.54 1.54 0 0 1 83.67 74l15.29-2.75a1.59 1.59 0 0 1 1.16.25 1.49 1.49 0 0 1 .64 1l2 10.52a1.48 1.48 0 0 1 0 .42l-1.1 11.08a1.55 1.55 0 0 1-1.27 1.35l-3.07.55a32.53 32.53 0 0 0 4.21 12.7h26.22a1.55 1.55 0 0 0 1.56-1.53v-45.8a1.54 1.54 0 0 0-1.6-1.53z"
                />
                <path
                    fill="#fff"
                    d="M80.5 115.31h16.39v14.54H80.5zM100.72 96.39A1.59 1.59 0 0 0 102 95l1.13-11.5a2.13 2.13 0 0 0 0-.44l-2-10.94a1.6 1.6 0 0 0-1.85-1.28l-15.62 2.84a1.6 1.6 0 0 0-1.28 1.85l2 10.93a1.48 1.48 0 0 0 .14.42l5.12 10.37a1.59 1.59 0 0 0 1.43.88h.28l3.11-.57c.46 4.29 3.68 12 5.72 16h3.61c-2.34-4.14-5.75-12.16-6.2-16.62z"
                />
                <path
                    fill="#254a5d"
                    d="M99.56 70.8a1.63 1.63 0 0 1 .91.28 1.6 1.6 0 0 1 .66 1l2 10.94a2.13 2.13 0 0 1 0 .44L102 95a1.59 1.59 0 0 1-1.3 1.41l-3.11.59c.45 4.46 3.86 12.48 6.2 16.62h-3.61c-2-4-5.26-11.75-5.72-16l-3.11.57h-.28a1.59 1.59 0 0 1-1.43-.88l-5.12-10.43a1.48 1.48 0 0 1-.14-.42l-2-10.93a1.6 1.6 0 0 1 1.28-1.85l15.62-2.86h.28m0-1.77a4 4 0 0 0-.59 0l-15.63 2.89a3.37 3.37 0 0 0-2.7 3.91l2 10.93a3.49 3.49 0 0 0 .29.88L88.06 98a3.34 3.34 0 0 0 3 1.87 4 4 0 0 0 .59-.05L93 99.6a73.08 73.08 0 0 0 5.59 14.78l.5 1h7.72l-1.49-2.64a62.16 62.16 0 0 1-5.72-14.32l1.43-.26a3.37 3.37 0 0 0 2.74-3l1.12-11.5a3.59 3.59 0 0 0 0-.93l-2-10.94A3.38 3.38 0 0 0 99.56 69z"
                />
                <path
                    fill="#254a5d"
                    d="M131.75 53H46a4.77 4.77 0 0 0-4.77 4.76v54a4.77 4.77 0 0 0 4.77 4.71h33.34v12.71H71.4a1.59 1.59 0 1 0 0 3.17h34.94a1.59 1.59 0 0 0 0-3.17H98.4v-12.71h33.35a4.77 4.77 0 0 0 4.76-4.76v-54a4.76 4.76 0 0 0-4.76-4.71zm-36.53 76.18H82.51v-12.71h12.71zm38.11-17.47a1.59 1.59 0 0 1-1.58 1.59H46a1.59 1.59 0 0 1-1.59-1.59v-54A1.59 1.59 0 0 1 46 56.12h85.76a1.59 1.59 0 0 1 1.58 1.59z"
                />
                <path
                    fill="#254a5d"
                    d="M99.84 83.35l-1.67-9.16-4.69.86.86 4.69a1.59 1.59 0 0 1-1.28 1.84 1.43 1.43 0 0 1-.29 0 1.59 1.59 0 0 1-1.56-1.3l-.85-4.69-4.69.86 1.67 9.15 4.5 9.09 7-1.28z"
                />
                <path
                    fill="#fff"
                    d="M104.52 119.16a1.51 1.51 0 0 0 1.15.49 1.59 1.59 0 0 0 1.14-2.69 24.47 24.47 0 0 1-3.38-4l-3.53.04a28.36 28.36 0 0 0 4.62 6.16z"
                />
                <g>
                    <path
                        fill="#fff"
                        d="M158.9 29.59a1 1 0 0 0-1.15.72l-6.49 27.16a1.36 1.36 0 0 0 1 1.63c3 .83 10.64 2.52 13.64 3.17a1.28 1.28 0 0 0 1.52-1c1.18-5 5.59-23.57 6.45-27.52a.83.83 0 0 0-.63-1z"
                    />
                    <path
                        fill="#254a5d"
                        d="M166.47 63.47a2.5 2.5 0 0 1-.47 0l-14.41-3.34a2 2 0 0 1-1.52-2.44l6.43-27.8a2 2 0 0 1 2.44-1.52l14.41 3.33a2.07 2.07 0 0 1 1.27.91 2 2 0 0 1 .26 1.54l-6.43 27.8a2.08 2.08 0 0 1-.91 1.26 2 2 0 0 1-1.07.26zm-8-33.25a.09.09 0 0 0-.08.06L152 58.08a.08.08 0 0 0 .06.1l14.42 3.33h.06L173 33.65a.08.08 0 0 0-.06-.09l-14.44-3.34z"
                    />
                    <path
                        fill="#254a5d"
                        d="M168.68 57.5a.86.86 0 0 1-.22 0L152 53.67a1 1 0 0 1-.61-.44.94.94 0 0 1-.12-.73l4.52-19.57a1 1 0 0 1 1.21-.73l16.43 3.8a1 1 0 0 1 .73 1.17l-4.52 19.57a1 1 0 0 1-1 .75zm-15.3-5.5L168 55.35l4-17.65-14.57-3.38zM166.36 33.75l-2.06-.47a.53.53 0 0 1-.39-.64.52.52 0 0 1 .63-.39l2.06.47a.53.53 0 0 1 .39.64.52.52 0 0 1-.63.39z"
                    />
                    <g fill="#254a5d">
                        <circle cx="159.73" cy="57.71" r="1.06" />
                        <path
                            d="M159.38 59.25a1.58 1.58 0 1 1 1.9-1.18 1.58 1.58 0 0 1-1.9 1.18zm.47-2a.52.52 0 0 0-.63.39.54.54 0 0 0 .39.64.54.54 0 0 0 .64-.4.53.53 0 0 0-.4-.68z"
                        />
                    </g>
                    <g fill="#254a5d">
                        <rect
                            width="8.45"
                            height="2.11"
                            x="153.77"
                            y="45.4"
                            rx=".77"
                            ry=".77"
                            transform="rotate(-76.97 158 46.46)"
                        />
                        <path
                            d="M157.5 50.83a.78.78 0 0 1-.22 0l-.55-.13a.93.93 0 0 1-.7-1.12l1.56-6.73a.9.9 0 0 1 .41-.58.89.89 0 0 1 .71-.12l.55.13a.93.93 0 0 1 .7 1.12l-1.55 6.73a1 1 0 0 1-.42.58.88.88 0 0 1-.49.12zm1-8.41a.63.63 0 0 0-.33.09.68.68 0 0 0-.27.39l-1.55 6.72a.62.62 0 0 0 .46.74l.55.12a.62.62 0 0 0 .46-.07.62.62 0 0 0 .27-.38l1.56-6.73a.62.62 0 0 0-.46-.74l-.55-.12z"
                        />
                    </g>
                    <g fill="#254a5d">
                        <rect
                            width="11.63"
                            height="2.11"
                            x="160.78"
                            y="45.77"
                            rx=".77"
                            ry=".77"
                            transform="rotate(-76.97 166.6 46.81)"
                        />
                        <path
                            d="M165.73 52.73h-.21l-.55-.13a1 1 0 0 1-.59-.42.91.91 0 0 1-.11-.7l2.27-9.82a.93.93 0 0 1 1.12-.7l.55.12a1 1 0 0 1 .59.42.92.92 0 0 1 .11.71L166.64 52a.9.9 0 0 1-.41.59 1 1 0 0 1-.5.14zm-.14-.34a.57.57 0 0 0 .46-.08.6.6 0 0 0 .28-.38l2.27-9.81a.65.65 0 0 0-.08-.47.62.62 0 0 0-.38-.27l-.55-.13a.62.62 0 0 0-.74.46l-2.27 9.82a.67.67 0 0 0 .08.46.58.58 0 0 0 .38.27z"
                        />
                    </g>
                    <g fill="#254a5d">
                        <rect
                            width="5.28"
                            height="2.11"
                            x="159.12"
                            y="47.9"
                            rx=".77"
                            ry=".77"
                            transform="rotate(-76.97 161.77 48.96)"
                        />
                        <path
                            d="M161.61 51.78a.76.76 0 0 1-.21 0l-.55-.12a.94.94 0 0 1-.7-1.13l.84-3.64a.94.94 0 0 1 1.12-.7l.56.13a.92.92 0 0 1 .58.42 1 1 0 0 1 .12.7l-.85 3.64a.92.92 0 0 1-.41.59 1 1 0 0 1-.5.11zm.29-5.32a.6.6 0 0 0-.59.48l-.85 3.64a.63.63 0 0 0 .46.73l.55.13a.62.62 0 0 0 .74-.46l.84-3.64a.58.58 0 0 0-.08-.46.56.56 0 0 0-.38-.27l-.55-.13z"
                        />
                    </g>
                </g>
                <path
                    fill="#254a5d"
                    d="M124.06 138.48h-7.5a1.22 1.22 0 1 1 0-2.43h7.5a1.22 1.22 0 1 1 0 2.43zM162.71 98.67a1.49 1.49 0 0 1-2.12 2.08l-1-1-1 1a1.48 1.48 0 0 1-2.09-2.1l1-1-1.12-1.13a1.49 1.49 0 0 1 2.13-2.07l1.08 1.11 1.06-1.06a1.48 1.48 0 0 1 2.09 2.1l-1.08 1.08zm-144-28.83l2.11-2.16a1.48 1.48 0 1 0-2.12-2.07l-2.09 2.13-2.05-2a1.48 1.48 0 0 0-2.09 2.09l2.07 2.07-1.91 2a1.48 1.48 0 0 0 0 2.1 1.47 1.47 0 0 0 2.09 0l1.93-2 1.94 1.94a1.48 1.48 0 1 0 2.1-2.09zm-12 17.4a3.89 3.89 0 1 0 3.89 3.89 3.89 3.89 0 0 0-3.88-3.89z"
                />
            </svg>

            <priva-header
                [size]="PrivaTitleSize.Lg"
                [theme]="PrivaTheme.OnLightGray"
                [title]="this.title | translate"
                [subtitle]="this.info | translate"
            />
        </header>

        <div class="status-message-main">
            @if (hasExtraInfo) {
                <div class="status-message-expander expander" [class.is-expanded]="isExpanded">
                    <button
                        type="button"
                        class="expander-trigger btn btn-tertiary center-block"
                        (click)="isExpanded = !isExpanded"
                    >
                        @if (this.isExpanded) {
                            <span class="expander-trigger-label expander-trigger-hide">
                                {{ 'GLOBAL.ACTION.LESSINFO' | translate }}
                                <span class="expander-trigger-icon btn-icon-right far fa-chevron-up"></span
                            ></span>
                        } @else {
                            <span class="expander-trigger-label expander-trigger-show">
                                {{ 'GLOBAL.ACTION.MOREINFO' | translate }}
                                <span class="expander-trigger-icon btn-icon-right far fa-chevron-down"></span
                            ></span>
                        }
                    </button>
                    <div class="expander-content center-block text-color-soft">
                        <p>{{ this.extraInfo | translate }}</p>
                    </div>
                </div>
            }
        </div>

        <footer class="status-message-footer">
            @for (action of this.actions; track action; let i = $index) {
                <div>
                    <div class="margin-bottom-1">
                        <button priva-button type="button" (click)="actionButtonClick()"></button>
                    </div>
                </div>
            }

            @if (!!this.actionTitle) {
                <button
                    class="status-message-cta btn btn-primary"
                    (click)="actionButtonClick()"
                    [id]="actionHtmlId"
                >
                    @if (actionIcon) {
                        <span class="btn-icon far {{ actionIcon }} btn-icon-left"></span>
                    }
                    <span [class]="actionHtmlClass"> </span>
                    {{ this.actionTitle | translate }}
                </button>
            }
        </footer>
    </section>
</div>
