import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class AppPrimaryNavigationService implements PrimaryNavigationService {
    private readonly navigation$: Observable<PrivaNavigationItem[]> = of([
        {
            label: 'APP.NAVIGATION.SPATIAL',
            label_short: 'APP.NAVIGATION.SPATIAL',
            slug: 'spatial',
            url: '/spatial',
        },
        {
            label: 'APP.NAVIGATION.CONTROL',
            label_short: 'APP.NAVIGATION.CONTROL',
            slug: 'control',
            url: '/control',
        },
    ]);

    public get navigation(): Observable<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
