import { setLoaded, setLoading, withCallState, withDevtools } from '@angular-architects/ngrx-toolkit';
import { inject } from '@angular/core';
import { patchState, signalStore, withMethods } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { BaseCompartment, Compartment } from 'app/core/models';
import { CompartmentApiService } from 'app/core/services/compartment-api/compartment-api.service';
import { TopologyStore } from 'app/core/stores';
import { handleError, handleSuccess } from 'app/core/utils/store-helper-utils';

const CompartmentCollection = 'compartments';
export type CompartmentStoreType = InstanceType<typeof CompartmentStore>;

export const CompartmentStore = signalStore(
    withCallState({ collection: CompartmentCollection }),
    withDevtools(CompartmentCollection),
    withMethods((state) => {
        const topologyStore = inject(TopologyStore);
        const translate = inject(TranslateService);
        const compartmentApiService = inject(CompartmentApiService);

        const addCompartments = (compartments: BaseCompartment[]) => {
            patchState(state, setLoading(CompartmentCollection));
            const siteId = topologyStore.selectedSiteId();
            const compartmentsToCreate = compartments.map((compartment: BaseCompartment) => {
                return {
                    name: compartment.name,
                    number: compartment.number,
                    levelId: topologyStore.selectedGreenhouse()?.groundFloorLevelId,
                };
            });
            compartmentApiService.createCompartments(siteId, compartmentsToCreate).subscribe({
                next: (newCompartments) => {
                    handleSuccess(
                        state,
                        newCompartments,
                        (compartment) => compartment.id,
                        CompartmentCollection,
                    );
                    topologyStore.reloadTopology();
                },
                error: (_error) => {
                    handleError(state, translate.instant('ERROR.ADDING_COMPARTMENTS'), CompartmentCollection);
                },
            });
        };

        const updateCompartment = (compartment: Compartment) => {
            patchState(state, setLoading(CompartmentCollection));

            compartmentApiService
                .updateCompartment(compartment.siteId!, compartment.id, {
                    name: compartment.name,
                    number: compartment.number,
                })
                .subscribe({
                    next: (updatedCompartment) => {
                        handleSuccess(
                            state,
                            [updatedCompartment],
                            (compartment) => compartment.id,
                            CompartmentCollection,
                        );

                        topologyStore.reloadTopology();
                    },
                    error: (error) => {
                        handleError(
                            state,
                            translate.instant('ERROR.UPDATING_COMPARTMENT'),
                            CompartmentCollection,
                        );
                        throw error;
                    },
                });
        };

        const deleteCompartment = (siteId: string, compartment: Compartment) => {
            if (!compartment.id) {
                handleError(state, translate.instant('ERROR.GLOBAL_ERROR_MESSAGE'), CompartmentCollection);
                return;
            }

            patchState(state, setLoading(CompartmentCollection));

            compartmentApiService.deleteCompartment(siteId, compartment.id).subscribe({
                next: () => {
                    patchState(state, setLoaded(CompartmentCollection));

                    topologyStore.reloadTopology();
                },
                error: () => {
                    handleError(
                        state,
                        translate.instant('ERROR.DELETING_COMPARTMENT', { compartment: compartment.name }),
                        CompartmentCollection,
                    );
                },
            });
        };

        return {
            addCompartments,
            updateCompartment,
            deleteCompartment,
        };
    }),
);
