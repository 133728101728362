export { deepmerge, deepmergeCustom, deepmergeInto, deepmergeIntoCustom } from 'deepmerge-ts';
function validateCSSProperty(property, value, convertUnitlessToPixels = false) {
  value = value?.toString().trim();
  if (convertUnitlessToPixels && value) {
    // Convert unit-less value to pixels
    // e.g. 10 => 10px
    // NB. We only do this for backwards compatibility for number input properties
    if (/^\d+$/.test(value)) {
      value = `${value}px`;
    }
  }
  const supported = CSS.supports(property, value);
  if (!supported && value) {
    console.warn(`Property '${property}' does not support provided value: '${value}'.`);
  }
  return supported ? value : null;
}
function widthAttribute(value) {
  return validateCSSProperty('width', value, true);
}
function maxWidthAttribute(value) {
  return validateCSSProperty('max-width', value);
}
function heightAttribute(value) {
  return validateCSSProperty('height', value, true);
}
function paddingAttribute(value) {
  return validateCSSProperty('padding', value);
}
function colorAttribute(value) {
  return validateCSSProperty('color', value);
}

/** Digit replacements for original digit, superscript digit, subscript digit */
const digitReplacementsLookup = ['[0\u2070\u2080]', '[1\u00b9\u2081]', '[2\u00b2\u2082]', '[3\u00b3\u2083]', '[4\u2074\u2084]', '[5\u2075\u2085]', '[6\u2076\u2086]', '[7\u2077\u2087]', '[8\u2078\u2088]', '[9\u2079\u2089]'];
/**
 * Replaces a digit with a regex that matches the digit in multiple formats
 * @param input The string to replace the digit in
 **/
function replaceDigits(input) {
  const digitIndex = parseInt(input);
  if (isNaN(digitIndex) || digitIndex < 0 || digitIndex > 9) return input;
  return digitReplacementsLookup[digitIndex];
}
function fuzzyMatch(text) {
  if (!text) return undefined;
  const matchSpecialChars = /[.*+?^${}()|[\]\\]/g;
  const replaceSpecialChars = '\\$&';
  const matchDigits = /\d+(?!\d)/g;
  return text.replace(matchSpecialChars, replaceSpecialChars).replace(matchDigits, replaceDigits);
}

/**
 * Generated bundle index. Do not edit.
 */

export { colorAttribute, fuzzyMatch, heightAttribute, maxWidthAttribute, paddingAttribute, widthAttribute };
