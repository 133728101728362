import { setError, setLoaded } from '@angular-architects/ngrx-toolkit';
import { patchState, WritableStateSource } from '@ngrx/signals';
import { addEntities, setAllEntities, setEntities } from '@ngrx/signals/entities';

import { SelectedZoneState } from 'app/core/stores';

export type HasId = { id?: string };

export function handleSuccess<T>(
    state: WritableStateSource<object>,
    entities: T[],
    selectId: (entity: T) => string,
    collection: string,
): void {
    patchState(state, setAllEntities(entities, { selectId, collection }), setLoaded(collection));
}

export function handleAddSuccess<T>(
    state: WritableStateSource<object>,
    entities: T[],
    selectId: (entity: T) => string,
    collection: string,
): void {
    patchState(state, addEntities(entities, { selectId, collection }), setLoaded(collection));
}

export function handleUpdateSuccess<T>(
    state: WritableStateSource<object>,
    entity: T,
    selectId: (entity: T) => string,
    collection: string,
): void {
    patchState(state, setEntities([entity], { selectId, collection }), setLoaded(collection));
}

export function handleError(state: WritableStateSource<object>, message: string, collection: string): void {
    patchState(state, setError(message, collection));
}

export const extractItemsFromParent = <TParent, TChild = never, TGrandChild = never>(
    parent: TParent | undefined,
    childArrayName?: keyof TParent,
    grandChildArrayName?: keyof TChild,
): TGrandChild[] => {
    if (!parent) {
        return [];
    }

    if (!childArrayName) {
        return parent as TGrandChild[];
    }

    const children = parent[childArrayName] as TChild[] | undefined;
    if (!Array.isArray(children)) {
        return [];
    }

    if (!grandChildArrayName) {
        return children as unknown as TGrandChild[];
    }

    return children.reduce((acc: TGrandChild[], child: TChild) => {
        const grandChildren = child[grandChildArrayName] as TGrandChild[] | undefined;
        if (Array.isArray(grandChildren)) {
            return acc.concat(grandChildren);
        }
        return acc;
    }, []);
};

export function findItemsById<T extends HasId>(items: T[], id: string): T | undefined {
    return items.find((item: T) => item.id === id);
}

export type TopologySelectionParams = {
    siteId: string;
    greenhouseId?: string;
    compartmentId?: string;
};

export function isSameTopologySelection(a: TopologySelectionParams, b: TopologySelectionParams): boolean {
    return a.siteId === b.siteId && a.greenhouseId === b.greenhouseId && a.compartmentId === b.compartmentId;
}

export function isSameSelectedZone(a: SelectedZoneState, b: SelectedZoneState): boolean {
    return (
        a.selectedSiteId === b.selectedSiteId &&
        a.selectedGreenhouseId === b.selectedGreenhouseId &&
        a.selectedCompartmentId === b.selectedCompartmentId
    );
}
