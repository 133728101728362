import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@ngx-translate/core';
import { PrivaLocalizationModule } from '@priva/localization';
const _c0 = (a0, a1, a2, a3, a4) => [a0, a1, a2, a3, a4];
const _c1 = a0 => ({
  item: a0
});
function PrivaDescriptionListComponent_For_2_Conditional_4_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
    i0.ɵɵpipe(1, "translate");
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(1, 1, item_r1.value));
  }
}
function PrivaDescriptionListComponent_For_2_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 4);
    i0.ɵɵelementContainer(1, 5);
    i0.ɵɵtemplate(2, PrivaDescriptionListComponent_For_2_Conditional_4_ng_template_2_Template, 2, 3, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const itemValueTmpDefault_r2 = i0.ɵɵreference(3);
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.itemValueTmp || itemValueTmpDefault_r2)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, item_r1));
  }
}
function PrivaDescriptionListComponent_For_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2)(1, "span", 3);
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, PrivaDescriptionListComponent_For_2_Conditional_4_Template, 4, 4, "span", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r1 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(3, 2, item_r1.label));
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(item_r1.value || ctx_r2.itemValueTmp ? 4 : -1);
  }
}
var PrivaDescriptionListLayoutClass;
(function (PrivaDescriptionListLayoutClass) {
  PrivaDescriptionListLayoutClass["stacked"] = "description-list-stacked";
  PrivaDescriptionListLayoutClass["inline"] = "description-list-inline";
})(PrivaDescriptionListLayoutClass || (PrivaDescriptionListLayoutClass = {}));
var PrivaDescriptionListDirectionClass;
(function (PrivaDescriptionListDirectionClass) {
  PrivaDescriptionListDirectionClass["vertical"] = "description-list-vertical";
  PrivaDescriptionListDirectionClass["horizontal"] = "description-list-horizontal";
  PrivaDescriptionListDirectionClass["grid"] = "description-list-grid";
})(PrivaDescriptionListDirectionClass || (PrivaDescriptionListDirectionClass = {}));
var PrivaDescriptionListDensityClass;
(function (PrivaDescriptionListDensityClass) {
  PrivaDescriptionListDensityClass["sm"] = "description-list-density-sm";
  PrivaDescriptionListDensityClass["md"] = "description-list-density-md";
})(PrivaDescriptionListDensityClass || (PrivaDescriptionListDensityClass = {}));
var PrivaDescriptionListInlineRatioClass;
(function (PrivaDescriptionListInlineRatioClass) {
  PrivaDescriptionListInlineRatioClass["1:1"] = "";
  PrivaDescriptionListInlineRatioClass["1:2"] = "description-list-inline-1-2";
  PrivaDescriptionListInlineRatioClass["2:1"] = "description-list-inline-2-1";
})(PrivaDescriptionListInlineRatioClass || (PrivaDescriptionListInlineRatioClass = {}));
var PrivaDescriptionListThemeClass;
(function (PrivaDescriptionListThemeClass) {
  PrivaDescriptionListThemeClass["onwhite"] = "description-list-theme-on-white";
  PrivaDescriptionListThemeClass["onlightgray"] = "description-list-theme-on-light-gray";
  PrivaDescriptionListThemeClass["onlightblue"] = "description-list-theme-on-light-blue";
  PrivaDescriptionListThemeClass["ondarkblue"] = "description-list-theme-on-dark-blue";
})(PrivaDescriptionListThemeClass || (PrivaDescriptionListThemeClass = {}));
class PrivaDescriptionListComponent {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.theme = 'onwhite';
    this.layout = 'stacked';
    this.inlineRatio = '1:1';
    this.direction = 'vertical';
    this.density = 'md';
    this.PrivaDescriptionListDirectionClass = PrivaDescriptionListDirectionClass;
    this.PrivaDescriptionListInlineRatioClass = PrivaDescriptionListInlineRatioClass;
    this.PrivaDescriptionListThemeClass = PrivaDescriptionListThemeClass;
    this.PrivaDescriptionListLayoutClass = PrivaDescriptionListLayoutClass;
    this.PrivaDescriptionListDensityClass = PrivaDescriptionListDensityClass;
  }
  setProperty($name, $value) {
    if ($value > 0) {
      this.elementRef.nativeElement.style.setProperty($name, $value + 'px');
    } else {
      this.elementRef.nativeElement.style.setProperty($name, '');
    }
  }
  ngOnInit() {
    this.setProperty('--description-list__item_min-width', this.minWidth);
    this.setProperty('--description-list__item_spacing', this.spacing);
  }
  static {
    this.ɵfac = function PrivaDescriptionListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDescriptionListComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaDescriptionListComponent,
      selectors: [["priva-description-list"]],
      inputs: {
        theme: "theme",
        layout: "layout",
        inlineRatio: "inlineRatio",
        direction: "direction",
        density: "density",
        minWidth: "minWidth",
        spacing: "spacing",
        items: "items",
        itemValueTmp: "itemValueTmp"
      },
      decls: 3,
      vars: 7,
      consts: [["itemValueTmpDefault", ""], [1, "description-list", 3, "ngClass"], [1, "description-list-item"], [1, "description-list-item-label"], [1, "description-list-item-value"], [3, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function PrivaDescriptionListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 1);
          i0.ɵɵrepeaterCreate(1, PrivaDescriptionListComponent_For_2_Template, 5, 4, "div", 2, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(1, _c0, (ctx.layout == null ? null : ctx.layout.toLowerCase()) === "inline" ? ctx.PrivaDescriptionListLayoutClass["inline"] + " " + ctx.PrivaDescriptionListInlineRatioClass[ctx.inlineRatio] : "", ctx.PrivaDescriptionListLayoutClass[ctx.layout == null ? null : ctx.layout.toLowerCase()], ctx.PrivaDescriptionListThemeClass[ctx.theme == null ? null : ctx.theme.toLowerCase()], ctx.PrivaDescriptionListDirectionClass[ctx.direction == null ? null : ctx.direction.toLowerCase()], ctx.PrivaDescriptionListDensityClass[ctx.density == null ? null : ctx.density.toLowerCase()]));
          i0.ɵɵadvance();
          i0.ɵɵrepeater(ctx.items);
        }
      },
      dependencies: [i1.NgClass, i1.NgTemplateOutlet, i2.TranslatePipe],
      styles: ["[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDescriptionListComponent, [{
    type: Component,
    args: [{
      selector: 'priva-description-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div\n    class=\"description-list\"\n    [ngClass]=\"[\n        layout?.toLowerCase() === 'inline'\n            ? PrivaDescriptionListLayoutClass['inline'] +\n              ' ' +\n              PrivaDescriptionListInlineRatioClass[inlineRatio]\n            : '',\n        PrivaDescriptionListLayoutClass[layout?.toLowerCase()],\n        PrivaDescriptionListThemeClass[theme?.toLowerCase()],\n        PrivaDescriptionListDirectionClass[direction?.toLowerCase()],\n        PrivaDescriptionListDensityClass[density?.toLowerCase()],\n    ]\"\n>\n    @for (item of items; track item) {\n        <div class=\"description-list-item\">\n            <span class=\"description-list-item-label\">{{ item.label | translate }}</span>\n            @if (item.value || itemValueTmp) {\n                <span class=\"description-list-item-value\"\n                    ><ng-container\n                        [ngTemplateOutlet]=\"itemValueTmp || itemValueTmpDefault\"\n                        [ngTemplateOutletContext]=\"{ item: item }\"\n                    /><ng-template #itemValueTmpDefault>{{ item.value | translate }}</ng-template></span\n                >\n            }\n        </div>\n    }\n</div>\n",
      styles: [":host{display:block}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    theme: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    inlineRatio: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    density: [{
      type: Input
    }],
    minWidth: [{
      type: Input
    }],
    spacing: [{
      type: Input
    }],
    items: [{
      type: Input
    }],
    itemValueTmp: [{
      type: Input
    }]
  });
})();
class PrivaDescriptionListModule {
  static {
    this.ɵfac = function PrivaDescriptionListModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaDescriptionListModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaDescriptionListModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaLocalizationModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaDescriptionListModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaLocalizationModule],
      declarations: [PrivaDescriptionListComponent],
      exports: [PrivaDescriptionListComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaDescriptionListComponent, PrivaDescriptionListModule };
