import { AppShellComponentsConfiguration } from '@priva/appshell';
import { PrivaAuthHttpOptions } from '@priva/auth/http';
import { FeatureToggleConfig } from '@priva/utilities/feature-toggles';

import { AppAnalytics } from './app-analytics.interface';
import { AppUris } from './app-uris.interface';

export interface AppConfiguration {
    name: string;
    showUserName: boolean;
    showTenantName: boolean;
    useLegacyRouterLinkActiveDirective: boolean;
    uris: AppUris;
    analytics: AppAnalytics;
    features: FeatureToggleConfig;
    components: AppShellComponentsConfiguration;
    authentication?: PrivaAuthHttpOptions;
}

type WindowWithPrivaConfig = { privaConfig: AppConfiguration };
export const windowWithPrivaConfig = window as unknown as WindowWithPrivaConfig;
